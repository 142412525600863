import { FailedDistribution, isDefined, TrackListWithReason } from '@timeedit/registration-shared';

type FailKeys =
  | keyof NonNullable<Required<FailedDistribution[string]>>['failsWithTracks']
  | keyof NonNullable<Required<FailedDistribution[string]>>['failsWithMessage']
  | 'none';

type ErrorKeyWithReason = {
  key: FailKeys;
  type: keyof NonNullable<FailedDistribution[string]> | 'none';
  reason: string;
};

// TODO: Probably want to handle this on the backend. Not doing it now because we are not sure if this is the structure we want.
export function findErrorKeysWithMessage(failed: TrackListWithReason) {
  const errorKeysWithMessage = Object.values(failed).reduce<ErrorKeyWithReason[]>((trackKeysWithReason, track) => {
    const keyWithReason: ErrorKeyWithReason =
      track?.reduce<ErrorKeyWithReason>(
        (failKeyWithReason, curr) => {
          // If we already found an error, we do not need to check for more.
          if (failKeyWithReason.key !== 'none') return failKeyWithReason;

          const failsWithTracks = curr.reasons?.failsWithTracks;
          if (isDefined(failsWithTracks)) {
            if (isDefined(failsWithTracks.conflict)) {
              const reasonIsLinked = checkIfReasonIsLinked(failsWithTracks.conflict, curr.reasons?.linkedTrackIds);
              return {
                key: 'conflict',
                type: 'failsWithTracks',
                reason: `${reasonIsLinked ? 'In a linked track, a' : 'A'} conflict was found, override?`,
              };
            }
            if (isDefined(failsWithTracks.dedicated)) {
              const reasonIsLinked = checkIfReasonIsLinked(failsWithTracks.dedicated, curr.reasons?.linkedTrackIds);
              return {
                key: 'dedicated',
                type: 'failsWithTracks',
                reason: `
                ${reasonIsLinked ? 'In a linked track, a' : 'A'} miss matching dedicated track was found, override?`,
              };
            }
            if (isDefined(failsWithTracks.doubleBooked)) {
              const reasonIsLinked = checkIfReasonIsLinked(failsWithTracks.doubleBooked, curr.reasons?.linkedTrackIds);
              return {
                key: 'doubleBooked',
                type: 'failsWithTracks',
                reason: `${
                  reasonIsLinked ? 'In a linked track, the s' : 'S'
                }tudent is already allocated to this activity, override?`,
              };
            }
            if (isDefined(failsWithTracks.buffer)) {
              const reasonIsLinked = checkIfReasonIsLinked(failsWithTracks.buffer, curr.reasons?.linkedTrackIds);
              return {
                key: 'buffer',
                type: 'failsWithTracks',
                reason: `${reasonIsLinked ? 'A linked track has n' : 'N'}o seats left, override buffer?`,
              };
            }
            if (isDefined(failsWithTracks.size)) {
              const reasonIsLinked = checkIfReasonIsLinked(failsWithTracks.size, curr.reasons?.linkedTrackIds);
              return {
                key: 'size',
                type: 'failsWithTracks',
                reason: `${reasonIsLinked ? 'A linked track has n' : 'N'}o seats left and no buffer left to override.`,
              };
            }
            if (isDefined(failsWithTracks.hidden)) {
              const reasonIsLinked = checkIfReasonIsLinked(failsWithTracks.hidden, curr.reasons?.linkedTrackIds);
              return {
                key: 'hidden',
                type: 'failsWithTracks',
                reason: `${reasonIsLinked ? 'A linked t' : 'T'}rack is hidden, override?`,
              };
            }
          }
          const failsWithMessage = curr.reasons?.failsWithMessage;
          if (isDefined(failsWithMessage)) {
            const { systemError } = failsWithMessage;
            if (isDefined(systemError)) {
              return {
                key: 'systemError',
                type: 'failsWithMessage',
                reason: Object.values(systemError)
                  .map((err) => err?.message)
                  .join(', '),
              };
            }
          }
          return failKeyWithReason;
        },
        { key: 'none', type: 'none', reason: '' },
      ) ?? ([] as unknown as ErrorKeyWithReason);

    return keyWithReason.key !== 'none' ? [...trackKeysWithReason, keyWithReason] : trackKeysWithReason;
  }, []);

  // Take index 0 to only show the first error.
  return errorKeysWithMessage.length === 0 ? { key: 'none', type: 'none', reason: '' } : errorKeysWithMessage[0];
}

function checkIfReasonIsLinked(fails: number[], linked?: number[]) {
  return fails.every((id) => linked?.includes(id));
}
