/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from 'react';
import { TFormInstance } from '@timeedit/types/lib/types';
import { TActivityResultsInResponse } from 'activities/pages/types/activity.type';
import { getActivityFormInstancesStatus, getFormNameByIds } from 'activities/services/activities.service';
import { uniq } from 'lodash';
import TEObjectManager from 'activities/services/TEObjects.service';
import { getInlineString } from 'i18n/intl';
import { useSelector } from 'react-redux';
import { organizationSelector } from '../../../slices/organization.slice';
import { Spin } from 'antd';
import DivWithHtml from 'utils/security';

export const withFormInstanceStatus = ({ activities }: { activities: TActivityResultsInResponse[] }) => {
  const [formInstanceStatus, setFormInstanceStatus] = useState<Record<string, TFormInstance['status']>>({});

  useEffect(() => {
    const doGetInstanceStatus = async () => {
      const formInstanceIds = uniq(
        activities
          .filter(({ formInstanceId }) => formInstanceId)
          .map(({ formInstanceId }) => formInstanceId.toString()),
      );
      if (!formInstanceIds.length) return;
      const response = await getActivityFormInstancesStatus(formInstanceIds);
      setFormInstanceStatus(response.data?.formInstances ?? {});
    };
    doGetInstanceStatus();
  }, [activities]);

  return { formInstanceStatus };
};

export const useSendToReviewPopover = ({
  selectedRowKeys,
  activitySeries,
}: {
  selectedRowKeys?: string[];
  activitySeries: TActivityResultsInResponse[];
}) => {
  const [open, setOpen] = useState(false);
  const [loadFormName, setLoadFormName] = useState(false);
  const [formNames, setFormNames] = useState<string[]>([]);
  const organization = useSelector(organizationSelector);
  useEffect(() => {
    if (open) {
      const sourceIds = uniq(
        activitySeries
          .filter(({ activitySeriesId }) => selectedRowKeys?.includes(activitySeriesId))
          .map(({ sourceId }) => sourceId),
      );
      try {
        setLoadFormName(true);
        getFormNameByIds(organization?.id, sourceIds).then((response) => {
          setFormNames(uniq(response?.map((d) => d.activityCreateTemplateName)) ?? []);
        });
      } finally {
        setLoadFormName(false);
      }
    } else {
      setFormNames([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const title = useMemo(() => {
    if (!open) return '';
    if (loadFormName) {
      return <Spin />;
    }
    const selectedSeries = activitySeries.filter(({ activitySeriesId }) => selectedRowKeys?.includes(activitySeriesId));
    const formNameString = formNames.join(', ');
    const primaryObject = selectedSeries.length
      ? TEObjectManager.getObjectTypeLabelByObject(selectedSeries[0].primaryObject)
      : 'primary objects';
    return (
      <div>
        <DivWithHtml
          htmlInput={getInlineString('activities.overview.table.review_confirmation', primaryObject)}
          allowedHTMLTags={['br', 'b']}
        />
        <DivWithHtml
          htmlInput={getInlineString('activities.overview.table.form_name_information', formNameString)}
          allowedHTMLTags={['b']}
        />
      </div>
    );
  }, [open, selectedRowKeys, activitySeries, loadFormName, formNames]);

  return {
    open,
    setOpen,
    title,
  };
};
