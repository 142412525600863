import { useEffect, useRef } from 'react';

export function useAbortController() {
  const abortRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      abortRef.current?.abort();
      abortRef.current = null;
    };
  }, []);

  function signal() {
    if (abortRef.current !== null) {
      abortRef.current.abort();
    }
    abortRef.current = new AbortController();
    return abortRef.current.signal;
  }

  return { signal };
}
