import { TAppDispatch, TRootState } from 'index';
import { useDispatch, useSelector } from 'react-redux';
import { runAutoAllocation } from '../../pages/slices/allocation.slice';
import { notification } from 'antd';
import { configService } from 'services/config.service';

export function useAutoAllocation() {
  const dispatch = useDispatch<TAppDispatch>();
  const autoAllocationRunning = useSelector((state: TRootState) => state.allocation.autoAllocationRunning);

  const startAutoAllocation = async () => {
    notification.info({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: 'Started Auto Allocate',
      description: 'The allocation may take a few minutes.',
    });

    try {
      await dispatch(runAutoAllocation()).unwrap();
    } catch (error) {
      notification.destroy(configService().NOTIFICATION_KEY);
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: 'Error',
        description: 'Could not perform Auto Allocate',
      });
    }
  };

  return { startAutoAllocation, autoAllocationRunning };
}
