import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';

import { convertToString } from '../../pages/BulkAllocationPage/utils';
import { Skeleton, TableColumnProps, Tag } from 'antd';
import intl from '../../../i18n/intl';
import { MultiProgress } from '@timeedit/registration-components';
import { StudentAdjustmentDataSource } from '../../pages/StudentAdjustmentsPage';
import { Button } from '@timeedit/ui-components';
import '../../pages/BulkAllocationPage/BulkAllocationPage.scss';
import { requiredKeys } from './StudentAdjustmentTable';
import { NavigateFunction } from 'react-router-dom';
import { isArray } from 'lodash';
import { isDefined, Mapping } from '@timeedit/registration-shared';

const language = intl.messages as Record<string, string>;
type StudentAdjustmentTableColumnsProps = {
  keys: ((typeof requiredKeys)[number] | number)[];
  mapping: Mapping;
  hasData: boolean;
  navigate: NavigateFunction;
};

export function useStudentAdjustmentTableColumns({
  keys,
  mapping,
  hasData,
  navigate,
}: StudentAdjustmentTableColumnsProps): ColumnsType<StudentAdjustmentDataSource> {
  return useMemo(() => {
    return keys.map<TableColumnProps<StudentAdjustmentDataSource>>((key) => {
      if (key === 'program') {
        return {
          title: mapping.typename('program'),
          key,
          dataIndex: key,
          onHeaderCell,
          onCell,
          sorter: sorterString(key),
          render: renderString,
        };
      }
      if (key === 'clashes') {
        return {
          title: language.conflicts,
          key,
          dataIndex: key,
          onHeaderCell: () => ({ style: { width: '8rem' } }),
          onCell,
          sorter: sorterString(key),
          render: (value) => {
            if (!isDefined(value) || value === '-') {
              return <Skeleton.Button active shape="circle" />;
            }
            if (value.toString() === '0') {
              return <Tag className="status__tag status__tag--success">{value}</Tag>;
            }
            return <Tag className="status__tag status__tag--error">{value}</Tag>;
          },
        };
      }
      if (key === 'allocation') {
        return {
          title: language.allocation,
          key: 'allocation',
          dataIndex: 'allocation',
          fixed: hasData ? 'right' : undefined,
          onCell,
          onHeaderCell,
          sorter: (a, b) => a.percent - b.percent,
          render: (_, record) => {
            return (
              <MultiProgress
                formatHelp={() => ''}
                end={0}
                max={record.trackListsLength}
                value={record.inHowManyTrackListIsStudent}
                compact
                explainValue={`${mapping.typename('track')}`}
              />
            );
          },
        };
      }
      if (key === 'manage') {
        return {
          key: 'manage',
          dataIndex: 'manage',
          fixed: hasData ? 'right' : undefined,
          onHeaderCell,
          onCell,
          render: (_, record) => {
            return (
              <Button
                size="small"
                onClick={() => {
                  navigate(`registration/${record.id}`);
                }}
              >
                {language.manage}{' '}
              </Button>
            );
          },
        };
      }
      return {
        title: mapping.fieldname(key),
        dataIndex: key,
        key,
        onCell,
        onHeaderCell,
        sorter: sorterString(key),
        render: renderString,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys, hasData]);
}

function onHeaderCell() {
  return { style: { width: '8rem' } };
}

function onCell() {
  return {
    style: {
      minWidth: '6rem',
    },
  };
}

function sorterString(key: string | number) {
  return (first: StudentAdjustmentDataSource, second: StudentAdjustmentDataSource) => {
    return convertToString(first[key]).localeCompare(convertToString(second[key]));
  };
}

const renderString = (value: any) => {
  if (isArray(value)) {
    return value.join(', ');
  }
  return value;
};
