import { convertToString, isDefined, isErrorWithMessage, TTEObject } from '@timeedit/registration-shared';
import { TFilterValueSet } from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import { notification } from 'antd';
import { unionBy, merge } from 'lodash';
import { z } from 'zod';
import { DependencyList, useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { configService } from 'services/config.service';
import { useAbortController } from './UseAbortController';
import { usePrevious } from 'hooks/usePrevious';

type SearchOption = { value: string; label: string };

type MinimumBody = {
  useCache?: boolean;
  limit?: number;
  signal?: AbortSignal;
  errorMessage?: boolean;
};

interface UseObjectSearchProps<T extends MinimumBody> {
  initialBody: T;
  request: (body: T) => Promise<{ data: TTEObject[] }>;
  searchParamKey: string;
  searchParamFilters?: TFilterValueSet;
  savedSearchCallback: (ids: number[]) => T;
  labelCallback: (obj: TTEObject) => string;
}

/**
 * @param initialBody - Initial body for the load objects request. Will be
 * deeply merged with the properies of every call to fetchSearchObject.
 * @param request - a request function that returns TTEObject[] data.
 * @param searchParamKey - The key to the specific filter the object search is intended for.
 * @params searchParamFilters - The search filters that the object search is intended for.
 * @param savedSearchCallback - A callback to create the parameters needed
 * to fetch search objects saved in the URL. The returned value will be merged
 * with initialBody.
 * @param labelCallback - Function that returns a search option label for a given object.
 * @returns
 * Tuple containing:
 * @param searchOptions - List of searchOptions to be used in a filter.
 * @param fetchSearchObjects - Function that takes additional parameters for a refined request
 * to load objects.
 * @param loading - Whether the objects are loading.
 */
export function useObjectSearch<T extends MinimumBody>({
  initialBody,
  request,
  searchParamKey,
  searchParamFilters,
  savedSearchCallback,
  labelCallback,
}: UseObjectSearchProps<T>) {
  const [loading, setLoading] = useState(false);
  const prevFilters = usePrevious(searchParamFilters);
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);
  // Avoid setting state when component is unmounted.
  const mounted = useRef(true);
  // Cancel request if request is repeated or component is unmounted.
  const { signal } = useAbortController();

  const useFetchSearchObjects = (
    fetchOptions: (extraBody?: Partial<T>) => Promise<SearchOption[]>,
    deps: DependencyList,
  ) =>
    useCallback(
      async (extraBody?: Partial<T>) => {
        try {
          if (mounted.current) {
            setLoading(true);
          }
          const result = await fetchOptions(extraBody);

          if (mounted.current) {
            setSearchOptions(result);
            setLoading(false);
          }
        } catch (e: any) {
          setLoading(false);
          const errorMessage = isErrorWithMessage(e) ? e?.message : null;
          if (errorMessage !== 'canceled') {
            notification.error({
              key: configService().NOTIFICATION_KEY,
              message: 'Object search failed.',
              description: typeof errorMessage === 'string' ? errorMessage : e.response?.data?.message,
            });
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [deps],
    );

  const fetchSavedSearchObjects = useFetchSearchObjects(async () => {
    const body = {
      useCache: true,
      limit: 100,
      signal: signal(),
      errorMessage: false,
      ...initialBody,
    };

    const promises = [request(body)];

    const maybeValue = z
      .array(
        z
          .string()
          .transform((it) => Number(it))
          .pipe(z.number().finite()),
      )
      .safeParse(searchParamFilters?.[searchParamKey]);
    if (maybeValue.success) {
      promises.push(request(merge(body, savedSearchCallback(maybeValue.data))));
    }

    const responses = await Promise.all(promises);

    return unionBy(
      searchOptions ?? [],
      responses.flatMap((res) =>
        res.data.map((obj: TTEObject) => ({ value: convertToString(obj.id), label: labelCallback(obj) })),
      ),
      'value',
    );
  }, [searchOptions, searchParamFilters, searchParamKey]);

  const fetchSearchObjects = useFetchSearchObjects(
    async (extraBody?: Partial<T>) => {
      const mergedBody = merge(initialBody, extraBody);

      const response = await request({
        useCache: true,
        limit: 100,
        signal: signal(),
        errorMessage: false,
        ...mergedBody,
      });

      return unionBy(
        searchOptions ?? [],
        response.data.map((obj: TTEObject) => ({ value: convertToString(obj.id), label: labelCallback(obj) })),
        'value',
      );
    },
    [searchOptions],
  );

  useEffect(() => {
    if (!isDefined(prevFilters) && isDefined(searchParamFilters)) {
      fetchSavedSearchObjects();
    }
  }, [fetchSavedSearchObjects, prevFilters, searchParamFilters]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const response = useMemo(
    () => [searchOptions, fetchSearchObjects, loading],
    [searchOptions, fetchSearchObjects, loading],
  );

  return response as [SearchOption[], (extraBody: Partial<T>) => Promise<void>, boolean];
}
