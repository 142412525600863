import { combineReducers } from '@reduxjs/toolkit';

// SLICES
import uiReducer from './ui.slice';
import authReducer from './auth.slice';
import languagesReducer from './language.slice';
import objectRelationshipReducer from './objectRelationships.slice';
import organizationReducer from './organization.slice';

import pathwayReducer from '../study-combinations/slices/pathway.slice';
import integrationReducer, { IntegrationState } from './integration.slice';
import combinationReducer from '../study-combinations/slices/combinationStudents.slice';
import allocationReducer, { AllocationState } from '../registration-allocation/pages/slices/allocation.slice';
import registrationReducer, { RegistrationState } from '../registration-allocation/pages/slices/registration.slice';
import issueListReducer, { IssueListState } from '../registration-allocation/pages/slices/issueList.slice';
import activityReducer, { ActivityState } from '../activities/pages/slices/activity.slice';
import studentAdjustmentSlice, {
  StudentAdjustmentState,
} from '../registration-allocation/pages/slices/studentAdjustment.slice';
import { IAuthState, IObjectRelationshipState, IUIState } from 'types/state.interface';
import drawerReducer, { DrawerState } from 'registration-allocation/pages/slices/drawer.slice';

export interface ApplicationState {
  ui: IUIState;
  auth: IAuthState;
  languages: any;
  pathway: any;
  integration: IntegrationState;
  combinations: any;
  objectRelationships: IObjectRelationshipState;
  organizations: any;
  allocation: AllocationState;
  drawer: DrawerState;
  registration: RegistrationState;
  issueList: IssueListState;
  activity: ActivityState;
  studentAdjustment: StudentAdjustmentState;
}
const rootReducer = combineReducers<ApplicationState>({
  ui: uiReducer,
  auth: authReducer,
  languages: languagesReducer,
  pathway: pathwayReducer,
  integration: integrationReducer,
  combinations: combinationReducer,
  objectRelationships: objectRelationshipReducer,
  organizations: organizationReducer,
  allocation: allocationReducer,
  drawer: drawerReducer,
  registration: registrationReducer,
  issueList: issueListReducer,
  activity: activityReducer,
  studentAdjustment: studentAdjustmentSlice,
});

export default rootReducer;
