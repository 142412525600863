import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TStudentsAndTracksOfCourse } from '@timeedit/preferences-and-dm-commons/lib/src';
import { UnsafeRecord } from '@timeedit/registration-shared';
import { omit } from 'lodash';

export interface StudentAdjustmentState {
  loadedCourses: { [key: string]: TStudentsAndTracksOfCourse };
  columnKeys: number[];
  conflicts: UnsafeRecord<number, number>;
  studentAdjustmentTableLoading: boolean;
  studentAdjustmentTableCurrentlyLoadingCourse: number | null;
  studentAdjustmentTableCurrentlyLoadedCourse: number | null;
}

const initialState: StudentAdjustmentState = {
  studentAdjustmentTableLoading: false,
  studentAdjustmentTableCurrentlyLoadingCourse: null,
  studentAdjustmentTableCurrentlyLoadedCourse: null,
  loadedCourses: {},
  columnKeys: [],
  conflicts: {},
};

const studentAdjustmentSlice = createSlice({
  name: 'studentAdjustment',
  initialState,
  reducers: {
    setStudentAdjustmentToInitialState() {
      return initialState;
    },
    setStudentAdjustmentCourse(state, action: PayloadAction<TStudentsAndTracksOfCourse & { id: string }>) {
      const { loadedCourses } = state;
      const newLoadedCourses = { ...loadedCourses, [action.payload.id]: omit(action.payload, 'id') };
      return { ...state, loadedCourses: newLoadedCourses };
    },
    setStudentAdjustmentTableLoading(state, action: PayloadAction<boolean>) {
      return { ...state, studentAdjustmentTableLoading: action.payload };
    },
    setStudentAdjustmentTableCurrentlyLoadingCourse(state, action: PayloadAction<number | null>) {
      return { ...state, studentAdjustmentTableCurrentlyLoadingCourse: action.payload };
    },
    setStudentAdjustmentTableCurrentlyLoadedCourse(state, action: PayloadAction<number | null>) {
      return { ...state, studentAdjustmentTableCurrentlyLoadedCourse: action.payload };
    },
    setColumnKeys(state, action: PayloadAction<number[]>) {
      return { ...state, columnKeys: action.payload };
    },
    setStudentConflicts: (state, action: PayloadAction<UnsafeRecord<number, number>>) => {
      return { ...state, conflicts: { ...state.conflicts, ...action.payload } };
    },
  },
});

export const {
  setStudentAdjustmentToInitialState,
  setStudentAdjustmentCourse,
  setStudentAdjustmentTableCurrentlyLoadedCourse,
  setStudentAdjustmentTableCurrentlyLoadingCourse,
  setStudentAdjustmentTableLoading,
  setColumnKeys,
  setStudentConflicts,
} = studentAdjustmentSlice.actions;
export default studentAdjustmentSlice.reducer;
