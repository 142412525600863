import { Dispatch } from '@reduxjs/toolkit';
import api from '../../../services/api.service';
import { configService } from '../../../services/config.service';
import {
  setAllocationObjectLoading,
  setCourseObjects,
  setFields,
  setTypes,
  setViewerLink,
  setStudentFields,
  updateLinkedTracks,
  updateSearchedObjects,
  setAllocationFailedGroups,
  setAllocationSuccessGroups,
  updateReservations,
  updateTrackObjects,
  updateStudentObjects,
  setConflicts,
} from './allocation.slice';
import { notification } from 'antd';
import {
  ConflictControlStrategy,
  allocationApi,
  GetStaffCoursesProps,
} from '../../services/registration-allocation.service';
import intl from '../../../i18n/intl';
import {
  setActiveChangeRequests,
  setChangeRequestObjects,
  setIssues,
  setIssuesCount,
  setIssuesStatus,
  updateChangeRequestsLoading,
} from './issueList.slice';
import { Mapping } from '../../services/mapping';
import {
  setStudentAdjustmentCourse,
  setStudentAdjustmentTableCurrentlyLoadedCourse,
  setStudentAdjustmentTableCurrentlyLoadingCourse,
  setStudentAdjustmentTableLoading,
  setStudentConflicts,
} from './studentAdjustment.slice';
import { setError, setStatus, setRegistration } from './registration.slice';
import { TRootState } from '../../../index';
import {
  LogService,
  isDefined,
  TTEObject,
  CatalogService,
  RegistrationService,
  fromTimestamp,
  Catalog,
  ScheduleService,
  AddStudentsToTracksOverrides,
  getErrorMessage,
  isErrorWithMessage,
} from '@timeedit/registration-shared';
import { isEmpty } from 'lodash';
import { allRelatedUniqIds, isMapped } from '../BulkAllocationPage/utils';
import { loadObjectById, loadReservationsFor } from '../BulkAllocationPage';
import { OnAllocate } from '@timeedit/registration-components';

const language = intl.messages as Record<string, string>;

export const fetchTypes = () => async (dispatch: Dispatch) => {
  try {
    allocationApi.findTypes({}).then((response) => dispatch(setTypes(response.data)));
  } catch (e) {
    console.error(e);
  }
};

export const fetchFields = () => async (dispatch: Dispatch) => {
  try {
    allocationApi.findFields({}).then((response) => dispatch(setFields(response.data)));
  } catch (e) {
    console.error(e);
  }
};

export const fetchViewerLink = () => async (dispatch: Dispatch) => {
  try {
    allocationApi.getViewLink().then((response) => dispatch(setViewerLink(response.data)));
  } catch (e) {
    console.error(e);
  }
};

export const fetchRelatedCourses = (props?: GetStaffCoursesProps) => async (dispatch: Dispatch) => {
  try {
    dispatch(setAllocationObjectLoading(true));
    const response = await allocationApi.staffCourses(props ?? {});
    dispatch(setCourseObjects(response.data));
    dispatch(setAllocationObjectLoading(false));
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    if (errorMessage !== 'canceled') {
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: 'Failed to load data',
        description: ` Details: ${e}`.slice(0, 600),
      });
    }
    dispatch(setAllocationObjectLoading(false));
  }
};

export const fetchStudentPerCourse = (courseId: number) => async (dispatch: Dispatch) => {
  dispatch(setStudentAdjustmentTableLoading(true));
  dispatch(setStudentAdjustmentTableCurrentlyLoadingCourse(courseId));
  try {
    api
      .get({
        endpoint: `${configService().REACT_APP_REGISTRATION_URL}/load-students-per-course/${courseId}`,
        successMessage: false,
      })
      .then(
        (response) =>
          dispatch(setStudentAdjustmentCourse({ ...response.data, id: courseId.toString() })) &&
          dispatch(updateSearchedObjects(response.data.programs)) &&
          dispatch(setStudentAdjustmentTableLoading(false)) &&
          dispatch(setStudentAdjustmentTableCurrentlyLoadedCourse(courseId)),
      );
  } catch (e) {
    notification.error({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: 'Failed to load data',
      description: ` Details: ${e}`.slice(0, 600),
    });
    dispatch(setStudentAdjustmentTableLoading(false));
  } finally {
    dispatch(setStudentAdjustmentTableCurrentlyLoadingCourse(null));
  }
};

export const fetchAndUpdateLinkedTracks = (trackIds: number[]) => async (dispatch: Dispatch) => {
  try {
    if (trackIds.length === 0) {
      return;
    }
    const response = await allocationApi.getLinkedTracks(trackIds);
    if (response.status === 200) {
      dispatch(updateLinkedTracks(response.data));
    } else {
      throw new Error(`${language.getLinkingTracksFailed}: ${trackIds}`);
    }
  } catch (e) {
    notification.error({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: language.linkTracksFailed,
      description: `Details: ${e}`.slice(0, 600),
    });
  }
};

export const fetchAndUpdateIssueList = () => async (dispatch: Dispatch) => {
  dispatch(setIssuesStatus('loading'));
  dispatch(updateChangeRequestsLoading({ activeRequestsLoading: true }));

  try {
    const changeRequests = await allocationApi.getChangeRequests();
    if (changeRequests.status === 200) {
      dispatch(setActiveChangeRequests(changeRequests.data));
    } else {
      throw new Error(`${language.getChangeRequestFailed}`);
    }

    const response = await allocationApi.getIssueList();
    if (response.status === 200) {
      dispatch(setIssues(response.data));
      dispatch(
        setIssuesCount(response.data.overEnrollment.courseIds.length + response.data.noAllocation.trackIds.length),
      );
    } else {
      throw new Error(`${language.getIssueListFailed}`);
    }
  } catch (e) {
    notification.error({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: language.issueListFailed,
      description: `Details: ${e}`.slice(0, 600),
    });
  }
  dispatch(setIssuesStatus('ready'));
  dispatch(updateChangeRequestsLoading({ activeRequestsLoading: false }));
};

export const fetchAndUpdatePossibleChangeRequests = (mapping: Mapping) => async (dispatch: Dispatch) => {
  if (!mapping.isMapped('changeRequest')) {
    return;
  }
  dispatch(updateChangeRequestsLoading({ objectsLoading: true }));

  try {
    const changeRequestObjects = await allocationApi.loadObjects({
      typeId: mapping.getId('changeRequest'),
      limit: 20,
    });
    if (changeRequestObjects.data.length > 0) {
      dispatch(setChangeRequestObjects(changeRequestObjects.data));
    } else {
      throw new Error(`${language.findObjectsChangeRequestFailed}`);
    }
  } catch (error) {
    notification.error({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: language.findObjectsFailed,
      description: `Details: ${error}`.slice(0, 600),
    });
  }

  dispatch(updateChangeRequestsLoading({ objectsLoading: false }));
};

export const fetchStudentFields = (mapping: Mapping) => async (dispatch: Dispatch) => {
  try {
    api
      .post({
        endpoint: `${configService().REACT_APP_REGISTRATION_URL}/find-fields`,
        successMessage: false,
        data: { typeId: mapping.getId('student') },
      })
      .then((response) => {
        dispatch(setStudentFields(response.data));
      });
  } catch (e) {
    console.error(e);
  }
};

/**
 * Private helper functions for Registration.
 */

interface FetchStudentObjectProps {
  studentObjects: TTEObject[];
  mapping: Mapping;
  studentId: number;
}
const _fetchStudentObject =
  ({ studentObjects, mapping, studentId }: FetchStudentObjectProps) =>
  async (dispatch: Dispatch) => {
    let studentObject = studentObjects.find((obj) => obj.id === studentId);
    if (!isDefined(studentObject)) {
      [studentObject] = await loadObjectById([studentId], mapping.getId('student'), true);
      if (isDefined(studentObject)) {
        dispatch(updateStudentObjects({ studentObjects: [studentObject], coursesWithStudentsLoaded: [] }));
      }
    }
    return studentObject;
  };

interface FetchCourseObjectsProps {
  mapping: Mapping;
  studentObject?: TTEObject;
}
const _fetchCourseObjects = async ({ mapping, studentObject }: FetchCourseObjectsProps) => {
  let result: TTEObject[] = [];
  if (isDefined(studentObject)) {
    const studentRelations = allRelatedUniqIds(studentObject);
    result = await loadObjectById(studentRelations, mapping.getId('course'), true);
  }
  return result;
};

interface FetchTrackObjectsProps {
  courseObjects: TTEObject[];
  mapping: Mapping;
  cache: boolean;
}
const _fetchTrackObjects = async ({ courseObjects, mapping, cache }: FetchTrackObjectsProps) => {
  const courseRelstions = allRelatedUniqIds(courseObjects);
  const objects = await loadObjectById(courseRelstions, mapping.getId('track'), cache);
  return objects.filter((obj) => obj.types.includes(mapping.getId('track')));
};

interface FetchStudentMembersProps {
  catalog: Catalog;
  studentObject: TTEObject;
  mapping: Mapping;
}
const _fetchStudentMembers = async ({ catalog, studentObject, mapping }: FetchStudentMembersProps) => {
  const studentTypes = studentObject.types;
  const ids =
    Object.values(catalog.tracks)
      .flatMap((track) => track?.teObject.members.map((member) => member.objectId))
      .filter(isDefined) ?? [];
  const objects = await loadObjectById(ids, mapping.getId('student'), true);

  return objects.filter((item) => studentTypes.some((type) => item.types.includes(type)));
};

// We should refactor to use LoadCourseRelated but make it return all data.
const _fetchRegistration =
  (studentId: number, mapping: Mapping, cache: boolean) => async (dispatch: Dispatch, getState: () => TRootState) => {
    const state = getState();
    const logger = new LogService(console);
    const unixNow = Math.floor(new Date().getTime() / 1000);
    const timezone = state.allocation.timeZone;

    const studentObject = await _fetchStudentObject({
      studentObjects: state.allocation.studentObjectState.studentObjects,
      mapping,
      studentId,
    })(dispatch);

    if (!isDefined(studentObject)) {
      throw new Error('Found no student object.');
    }

    const courseObjects = await _fetchCourseObjects({
      studentObject,
      mapping,
    });
    const trackObjects = await _fetchTrackObjects({
      courseObjects,
      mapping,
      cache,
    });

    const trackIds = trackObjects.map((t) => t.id);
    const linkedTracksRecord =
      trackIds.length === 0 ? {} : (await allocationApi.getLinkedTracks(trackObjects.map((t) => t.id))).data;

    const catalogResult = new CatalogService({
      mappingData: mapping.store.mappingData,
      logger,
    }).createCatalog({
      courseObjects,
      trackObjects,
      linkedTracksRecord,
    });

    if (!catalogResult.success) {
      throw catalogResult.error;
    }

    const catalog = catalogResult.data;

    const studentMembers = await _fetchStudentMembers({ catalog, studentObject, mapping });

    const registrationResult = new RegistrationService({
      dateTime: {
        currentDateTime: fromTimestamp(unixNow, timezone),
        offsetDateTime: fromTimestamp(unixNow, timezone),
      },
      timezone: state.allocation.timeZone,
      mappingData: mapping.store.mappingData,
      logger,
    }).createRegistration({
      catalog,
      studentData: {
        studentObject,
        studentMembers,
      },
      mode: 'teacher',
    });

    if (!registrationResult.success) {
      throw registrationResult.error;
    }

    const registration = registrationResult.data;

    let dedicatedIds: number[] = [];
    for (const trackId in registration.tracks) {
      const track = registration.tracks[trackId];
      if (track?.dedicated.kind === 'relation') {
        dedicatedIds = [...dedicatedIds, ...track.dedicated.data.map((data) => data.id)];
      }
    }

    const searchedObjects = await loadObjectById(dedicatedIds, 0, true);

    dispatch(updateSearchedObjects(searchedObjects));

    const reservationsResponse = await allocationApi.loadReservationsFor(
      Object.values(registration.tracks)
        .map((track) => track?.id)
        .filter(isDefined),
      mapping.getId('track'),
      true,
    );

    const reservations = reservationsResponse.data ?? [];

    const scheduleResult = new ScheduleService({ timezone, logger }).createSchedule({
      registration,
      reservations,
    });

    if (scheduleResult.success) {
      registration.events = scheduleResult.data.events;
      registration.conflicts = scheduleResult.data.conflicts;
    } else {
      throw scheduleResult.error;
    }

    dispatch(setRegistration({ studentId, registration }));
  };

export const fetchRegistration =
  (studentId: number, mapping: Mapping) => async (dispatch: Dispatch, getState: () => TRootState) => {
    try {
      dispatch(setStatus({ studentId, status: 'loading' }));
      await _fetchRegistration(studentId, mapping, true)(dispatch, getState);
    } catch (e) {
      console.error(e);
      dispatch(setError({ studentId, error: e }));
    }
  };

type AllocateRequest = {
  conflictControlStrategy: ConflictControlStrategy;
  studentId: number;
  newObjectId: number;
  overrides?: AddStudentsToTracksOverrides;
} & Omit<ReloadTrackAndReservations, 'trackIds'> &
  Pick<OnAllocate, 'prevObjectIds'>;

// Allocation for a single student to a single track
export const registrationAllocate =
  ({ conflictControlStrategy, studentId, prevObjectIds, mapping, newObjectId, overrides }: AllocateRequest) =>
  async (dispatch: Dispatch, getState: () => TRootState) => {
    dispatch(setAllocationFailedGroups({}));
    dispatch(setAllocationSuccessGroups({}));
    dispatch(setStatus({ studentId, status: 'fetching' }));

    const studentInfo = { [studentId]: [newObjectId] };
    const deallocateTrackIds = prevObjectIds ?? [];
    const deAllocateStudentInfo = { [studentId]: deallocateTrackIds };

    try {
      const response = await allocationApi.allocate({
        data: {
          studentInfo,
          conflictControl: conflictControlStrategy,
          // We we make a swap action (having deallocateTrackIds) then override doubleBooked
          overrides: { ...overrides, doubleBooked: overrides?.doubleBooked ?? deallocateTrackIds.length > 0 },
        },
        successMessage: false,
      });

      if (response.status === 200) {
        if (!isEmpty(response.data?.failedGroups)) {
          dispatch(setAllocationFailedGroups(response.data.failedGroups));
        }
        if (!isEmpty(response.data?.successGroups)) {
          dispatch(setAllocationSuccessGroups(response.data?.successGroups));

          if (deallocateTrackIds.length > 0) {
            await allocationApi.deAllocate({
              data: { studentInfo: deAllocateStudentInfo },
              successMessage: false,
            });
          }

          const tracks = await loadObjectById([...prevObjectIds, newObjectId], mapping.getId('track'), true);
          dispatch(updateTrackObjects(tracks));
          dispatch(setStudentAdjustmentTableCurrentlyLoadedCourse(null));
          await _fetchRegistration(studentId, mapping, false)(dispatch, getState);
        } else {
          dispatch(setStatus({ studentId, status: 'fulfilled' }));
        }
      }
    } catch (error) {
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: 'Allocation failed.',
        description: ` Details: \n ${JSON.stringify(studentInfo)} \n ${error}`.slice(0, 600),
      });
      dispatch(setError({ studentId, error }));
    }
  };

type DeallocateRequest = {
  studentId: number;
} & ReloadTrackAndReservations;
export const deallocate =
  ({ studentId, trackIds, mapping }: DeallocateRequest) =>
  async (dispatch: Dispatch, getState: () => TRootState) => {
    const studentInfo = { [studentId]: trackIds };
    try {
      dispatch(setStatus({ studentId, status: 'fetching' }));

      const response = await allocationApi.deAllocate({
        successMessage: false,
        data: { studentInfo },
      });

      if (response.status === 200) {
        const tracks = await loadObjectById(trackIds, mapping.getId('track'), true);
        dispatch(updateTrackObjects(tracks));
        dispatch(setStudentAdjustmentTableCurrentlyLoadedCourse(null));
        await _fetchRegistration(studentId, mapping, false)(dispatch, getState);
      } else {
        throw new Error(`Could not deallocate student ${studentId} from tracks: ${trackIds}`);
      }
    } catch (error) {
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: 'Deallocate failed.',
        description: ` Details: ${error}`.slice(0, 600),
      });
      dispatch(setError({ studentId, error }));
    }
  };

type ReloadTrackAndReservations = {
  reloadReservations?: boolean;
  trackIds: number[];
  mapping: Mapping;
};
export const reloadTrackAndReservations =
  ({ reloadReservations, trackIds, mapping }: ReloadTrackAndReservations) =>
  async (dispatch: Dispatch) => {
    if (trackIds.length === 0) {
      return [];
    }
    const typeId = mapping.getId('track');
    if (!isMapped(typeId)) {
      return [];
    }
    const loaded = await loadObjectById(trackIds, typeId, false); // Forced reload of tracks
    dispatch(updateTrackObjects(loaded));
    if (reloadReservations) {
      loadReservationsFor(trackIds, typeId, false).then((reservations) => {
        // Forced reload in the background
        dispatch(updateReservations(reservations));
      });
    }
    return loaded;
  };

type FetchTrackConflictsProps = {
  trackIds: number[];
  dispatch: Dispatch;
  abortSignal?: AbortSignal;
};
export const fetchTrackConflicts = async ({ dispatch, trackIds, abortSignal }: FetchTrackConflictsProps) => {
  if (trackIds.length > 0) {
    try {
      const conflicts = await allocationApi.getConflicts({
        data: { tracks: trackIds },
        signal: abortSignal,
        errorMessage: false,
      });

      if (conflicts.error) {
        notification.error({
          duration: 0,
          key: configService().NOTIFICATION_KEY,
          message: 'Get conflicts failed.',
          description: ` Details: \n ${conflicts.error.message}`.slice(0, 600),
        });
      }

      if (conflicts.success) {
        dispatch(setConflicts(conflicts.data.data));
        return conflicts.data.data;
      }
      return {};
    } catch (error) {
      const errorMessage = isErrorWithMessage(error) ? error?.message : null;
      if (errorMessage !== 'canceled') {
        notification.error({
          duration: 0,
          key: configService().NOTIFICATION_KEY,
          message: 'Get conflicts failed.',
          description: ` Details: \n ${error}`.slice(0, 600),
        });
      }
      return {};
    }
  }
  return {};
};

export const fetchStudentConflicts = (studentIds: number[]) => async (dispatch: Dispatch) => {
  if (studentIds.length > 0) {
    try {
      const studentConflicts = await allocationApi.findStudentConflicts({ data: { students: studentIds } });

      if (studentConflicts.data) {
        dispatch(setStudentConflicts(studentConflicts.data));
      }
    } catch (error) {
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: 'Get student conflicts failed.',
        description: ` Details: \n ${error}`.slice(0, 600),
      });
    }
  }
};
