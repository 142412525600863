import React from 'react';
import { TField, isDefined } from '@timeedit/registration-shared';
import { Select } from 'antd';
import { isUndefined } from 'lodash';
import { Mapping, MappingShorthand } from '../../services/mapping';
import { checkBoxOptions, filterOptionLowerCaseInclude } from '../Filter/common';

function createCategoryOptions(field: TField) {
  if (field.fieldType === 'CHECKBOX') {
    return checkBoxOptions;
  }
  if (!isDefined(field.categories)) {
    return undefined;
  }
  return field.categories.map((name) => ({
    value: name,
    label: name,
  }));
}

export const createCategoryDropdowns = (
  mapping: Mapping,
  shorthand: MappingShorthand,
  fields: TField[],
  changeValues: (id: number, values: string[]) => void,
) => {
  const ids = mapping.searchableFields(mapping.getId(shorthand));
  if (!isDefined(ids)) {
    return '';
  }
  if (ids.length === 0) {
    return '';
  }
  const dropdowns = ids
    .map((id) => {
      const field = fields.find((f) => f.id === id);
      if (!isDefined(field)) {
        return undefined;
      }
      const categoryOptions = createCategoryOptions(field);
      if (isUndefined(categoryOptions)) {
        return undefined;
      }
      const categoryChange = (values: string[]) => changeValues(id, values);
      const key = `${shorthand}${fields[0]}cat_${id}`;
      return (
        <div key={`tip${key}`} className={'drawer__item--margin categoryTitle'}>
          <div>{field.name}</div>
          <Select
            key={key}
            mode="multiple"
            className={'category__select'}
            showSearch
            allowClear
            size="small"
            placeholder={field.name}
            onChange={categoryChange}
            filterOption={filterOptionLowerCaseInclude}
            options={categoryOptions}
            popupMatchSelectWidth={false}
          />
        </div>
      );
    })
    .filter(isDefined);
  if (dropdowns.length === 0) {
    return '';
  }
  return dropdowns;
};
