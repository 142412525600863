import { Steps } from 'antd';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { allocateDateTimeFormat } from '@timeedit/registration-shared';
import intl from '../../../i18n/intl';
import { StudentsStep } from './StudentsStep';
import { AllocationMethodStep } from './AllocationMethodStep';
import { AllocateStep } from './AllocateStep';
import { ReviewResultsStep } from './ReviewResultsStep';
import './BatchAllocateContent.scss';
import { userSelector } from '../../../slices/auth.slice';
import dayjs from 'dayjs';

const language = intl.messages as Record<string, string>;

type BatchAllocateContentProps = {
  currentStep: number;
};

export function BatchAllocateContent({ currentStep }: BatchAllocateContentProps) {
  const user = useSelector(userSelector);

  const [csv, setCSV] = useState<string>(
    `Date and time,${dayjs().format(allocateDateTimeFormat)}\nRun by,${user?.email}`,
  );

  const items = useMemo(
    () => [
      {
        title: 'Students',
        content: <StudentsStep />,
      },
      {
        title: 'Allocation method',
        content: <AllocationMethodStep />,
      },
      {
        title: language.allocate,
        content: <AllocateStep setCSV={setCSV} />,
      },
      {
        title: 'Review results',
        content: <ReviewResultsStep csv={csv} />,
      },
    ],
    [csv, setCSV],
  );

  return (
    <div>
      <Steps size="small" current={currentStep} items={items} />
      <div className="content-container">{items[currentStep].content}</div>
    </div>
  );
}
