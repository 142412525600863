/* eslint-disable camelcase */
import { Button, Form, Input, InputNumber, Modal } from 'antd';
import { compact, isEmpty, keyBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TEObjectMappingSelector } from '../../../slices/integration.slice';
import {
  pathwayCoursesSelector,
  pathwayPrimaryObjectsSelector,
  pathwaySelectedFormSelector,
  pathwayMandatoryCoursesOfSelectedProgram,
  pathwayElectiveCoursesOfSelectedProgram,
  allRelationsSelector,
} from '../../slices/pathway.slice';
import { DatasourceSelector } from '@timeedit/ui-components';
import { TEObject } from '@timeedit/ui-components/lib/src/types/TEObject.type';
import { TCreatePathwayPayload, TPathway } from '../PathwayService.type';
import { creditCalculator } from '../PathwayService.utils';
import './ModalCreatingOrEditingPathway.scss';
import { usePrevious } from '../../../hooks/usePrevious';

// i18N
import intl from '../../../i18n/intl';

const language: any = intl.messages;

interface Props {
  open?: boolean;
  createOrEdit: 'create' | 'edit';
  pathwayToEdit?: TPathway;
  onVisibleChange: (visible: boolean) => void;
  onSubmit: (values: TCreatePathwayPayload) => void;
}
function ModalCreatingOrEditingPathway(props: Props) {
  const { onVisibleChange, open, onSubmit, pathwayToEdit, createOrEdit } = props;

  const courses = useSelector(pathwayCoursesSelector);
  const programs = useSelector(pathwayPrimaryObjectsSelector);
  const mapping = useSelector(TEObjectMappingSelector());
  const selectedForm = useSelector(pathwaySelectedFormSelector);
  const allRelations = useSelector(allRelationsSelector);

  const [form] = Form.useForm();

  const [electiveCourseIds, setElectiveCourseIds] = useState<string[]>(pathwayToEdit?.electiveCourseIds || []);
  const [mandatoryCourseIds, setMandatoryCourseIds] = useState<string[]>(pathwayToEdit?.mandatoryCourseIds || []);
  const [scopedObject, setScopedObject] = useState<string[]>([]);
  const previousScopedObject = usePrevious(scopedObject);

  useEffect(() => {
    if (createOrEdit === 'edit' && pathwayToEdit) {
      setElectiveCourseIds(pathwayToEdit?.electiveCourseIds || []);
      setMandatoryCourseIds(pathwayToEdit?.mandatoryCourseIds || []);
      setScopedObject([pathwayToEdit.programExtId || '']);
      form.setFieldValue('expectedEnrollment', pathwayToEdit.expectedEnrollment);
    } else {
      setElectiveCourseIds([]);
      setMandatoryCourseIds([]);
      setScopedObject([]);
    }
  }, [createOrEdit, pathwayToEdit, form]);

  const fieldsMapping = useMemo(() => {
    if (!selectedForm)
      return {
        courses: '',
        credits: '',
      };
    return {
      courses: selectedForm.pathwaysSettings?.course.datasource,
      credits: selectedForm.pathwaysSettings?.course.fields.credits,
    };
  }, [selectedForm]);

  const allMandatoryIds = useSelector(pathwayMandatoryCoursesOfSelectedProgram(scopedObject[0] || ''));
  const allElectiveIds = useSelector(pathwayElectiveCoursesOfSelectedProgram(scopedObject[0] || ''));

  const indexedCourses = useMemo(() => keyBy(courses, 'te_extid'), [courses]);
  const indexedPrograms = useMemo(() => keyBy(programs, 'te_extid'), [programs]);

  const mandatoryCourses: TEObject[] = useMemo(() => {
    return mandatoryCourseIds.filter((id) => indexedCourses[id]).map((id) => indexedCourses[id]);
  }, [mandatoryCourseIds, indexedCourses]);

  const mandatoryCoursesCredits = useMemo(() => {
    return creditCalculator(mandatoryCourses, fieldsMapping.credits);
  }, [mandatoryCourses, fieldsMapping]);

  const electiveCourses = useMemo(() => {
    return electiveCourseIds.filter((id) => indexedCourses[id]).map((id) => indexedCourses[id]);
  }, [indexedCourses, electiveCourseIds]);

  const electiveCoursesCredits = useMemo(() => {
    return creditCalculator(electiveCourses, fieldsMapping.credits);
  }, [electiveCourses, fieldsMapping]);

  useEffect(() => {
    const onlyCourses = (input: string[]) => input?.filter((i) => i in indexedCourses);
    if (createOrEdit === 'create' && scopedObject[0] !== previousScopedObject?.[0]) {
      const mandatoryCoursesOfSelectedProg = onlyCourses(allRelations.objectRelated[scopedObject[0]]) || [];
      if (mandatoryCourseIds.length === 0 && mandatoryCoursesOfSelectedProg.length > 0) {
        setMandatoryCourseIds(mandatoryCoursesOfSelectedProg);
      }
      const electiveCoursesOfSelectedProg = onlyCourses(allRelations.objectOptionalRelated[scopedObject[0]]) || [];
      if (electiveCourseIds.length === 0 && electiveCoursesOfSelectedProg.length > 0) {
        setElectiveCourseIds(electiveCoursesOfSelectedProg);
      }
    }
  }, [
    scopedObject,
    createOrEdit,
    mandatoryCourseIds,
    electiveCourseIds,
    allRelations,
    previousScopedObject,
    indexedCourses,
  ]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setElectiveCourseIds([]);
      setScopedObject([]);
    }
  }, [open, form]);

  const doSubmitValues = (values: any) => {
    onSubmit({
      name: values.name,
      expectedEnrollment: values.expectedEnrollment,
      electiveCourseIds,
      scopedObject: scopedObject[0],
      mandatoryCourseIds,
      formId: selectedForm?._id || '',
    });
  };

  if (!selectedForm || !mapping) return null;

  return (
    <Modal
      open={open}
      onCancel={() => onVisibleChange(false)}
      title={createOrEdit === 'create' ? language.create_new_combination : language.label_selected_study_comb}
      wrapClassName="modal-create-new-pathway"
      footer={
        <div className="te-flex te-justify-between">
          <b>
            {language.total_credits}: {mandatoryCoursesCredits + electiveCoursesCredits}
          </b>
          <div>
            <Button onClick={() => onVisibleChange(false)}>{language.cancel}</Button>
            <Button form="createNewPathwayForm" htmlType="submit" type="primary">
              {createOrEdit === 'create' ? language.create : language.confirm}
            </Button>
          </div>
        </div>
      }
    >
      <Form id="createNewPathwayForm" form={form} onFinish={doSubmitValues}>
        {createOrEdit === 'create' && (
          <>
            <Form.Item
              label={language.name}
              name="name"
              required
              rules={[
                {
                  required: true,
                  message: language.name_is_required,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="program"
              label={language.program}
              required
              rules={[
                {
                  required: true,
                  message: language.program_is_required,
                },
              ]}
            >
              <DatasourceSelector
                objects={programs || []}
                fields={mapping.objectTypes?.[selectedForm.objectScope]?.fields || []}
                datasource={selectedForm.objectScope}
                onChange={(selectedObjectIds: any) => {
                  setScopedObject(compact(selectedObjectIds));
                }}
                values={scopedObject}
                valueRenderer={(val: any) => {
                  const program = indexedPrograms[val];
                  if (!program) return val;
                  return `${program[mapping.labeledFields?.[selectedForm.objectScope]]}`;
                }}
              />
            </Form.Item>
          </>
        )}
        <b>
          {language.mandatory_courses} ({mandatoryCoursesCredits})
        </b>
        <Form.Item
          rules={[
            {
              validator: () => {
                if (isEmpty(mandatoryCourseIds)) {
                  return Promise.reject(new Error(language.mandatory_course_is_required as string));
                }
                return Promise.resolve();
              },
            },
          ]}
          name="mandatoryCourseIds"
        >
          <div className="course-selector">
            <DatasourceSelector
              objects={courses || []}
              fields={mapping.objectTypes?.[fieldsMapping.courses]?.fields || []}
              datasource={fieldsMapping.courses}
              onChange={(selectedObjectIds: any) => {
                setMandatoryCourseIds(compact(selectedObjectIds));
              }}
              values={mandatoryCourseIds}
              valueRenderer={(val: any) => {
                const course = indexedCourses[val];
                if (!course) return val;
                return `${course[mapping.labeledFields?.[fieldsMapping.courses]]} (${course[fieldsMapping.credits]})`;
              }}
              checkboxFilters={[
                {
                  key: 'related',
                  label: language.show_only_related_objects,
                  defaultValue: true,
                  filterFn: (item: any) => {
                    return allMandatoryIds.includes(item.te_extid);
                  },
                },
              ]}
              allowMultiple
            />
          </div>
        </Form.Item>
        <b>
          {language.elective_courses} ({electiveCoursesCredits})
        </b>
        <Form.Item>
          <div className="course-selector">
            <DatasourceSelector
              objects={courses || []}
              fields={mapping.objectTypes?.[fieldsMapping.courses]?.fields || []}
              datasource={fieldsMapping.courses}
              onChange={(selectedObjectIds: any) => {
                setElectiveCourseIds(compact(selectedObjectIds));
              }}
              values={electiveCourseIds}
              valueRenderer={(val: any) => {
                const course = indexedCourses[val];
                if (!course) return val;
                return `${course[mapping.labeledFields?.[fieldsMapping.courses]]} (${course[fieldsMapping.credits]})`;
              }}
              checkboxFilters={[
                {
                  key: 'related',
                  label: language.show_only_related_objects,
                  defaultValue: true,
                  filterFn: (item: any) => {
                    return allElectiveIds.includes(item.te_extid);
                  },
                },
              ]}
              allowMultiple
            />
          </div>
        </Form.Item>
        <Form.Item
          name="expectedEnrollment"
          label={language.expected_enrollment}
          rules={[
            {
              required: true,
              message: language.expected_enrollment_is_required,
            },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalCreatingOrEditingPathway;
