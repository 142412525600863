import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, InputNumber, Select, Switch, Tooltip } from 'antd';
import { TEDrawerSection } from '@timeedit/ui-components';
import { TTEObject, isDefined } from '@timeedit/registration-shared';
import { MultiProgress } from '@timeedit/registration-components';
import intl from '../../../i18n/intl';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { useMapping } from '../../services/mapping';
import {
  Calc,
  PresentRooms,
  convertToString,
  isValidId,
  parseBuffer,
  presentReservationRanges,
  presentSet,
  printDaysOfWeek,
  printSet,
  printSetMax,
  toDateInURL,
  validBufferSize,
} from '../../pages/BulkAllocationPage/utils';
import { isEmpty, isNumber } from 'lodash';
import { TTEFieldValue, TOrganizationPublic } from '@timeedit/types/lib/types';
import { setChangesToDiscard } from '../../pages/slices/drawer.slice';
import { MappingShorthandField } from '../../services/mapping/mapping.service';
import { allocationApi } from '../../services/registration-allocation.service';
import { ViewLink, ViewerLink } from '../../pages/BulkAllocationPage/ViewerLink';
import { organizationSelector } from '../../../slices/organization.slice';
import { DedicatedTrackSection } from './DedicationSection/DedicatedTrackSection';
import { numberArrayParser } from '@timeedit/preferences-and-dm-commons/lib/src/utils/registration-allocation/mapping/parsers';
import { Link } from 'react-router-dom';
import { DiscardFunctions, SaveDiscardSections, SaveFunctions } from './BulkAllocationDrawer';
import { jsonToStateFilterUrlParam, nameOfStateFilterSearchParam } from '../../hooks/UseStateParamState';
import { ReloadOutlined } from '@ant-design/icons';
import { paths } from '../../../routes/routes';
import { fetchStudentFields, reloadTrackAndReservations } from '../../pages/slices/fetch.slice';
import { TTEReservation } from '../../pages/BulkAllocationPage/loadReservations';
import { GeneralInfoRow, GeneralInfoRowsWrapper } from './GeneralInfoRow';
import { LinkedInfo } from './LinkedInfo';
import { setFieldValue } from '../../utils/fields';
import { MaxStudentsSection } from './MaxStudentsSection';
import { useDedicatedSectionInfo } from './hooks';

const language = intl.messages as Record<string, string>;

type GeneralInfoProps = {
  removeSaveFunction: (id: SaveDiscardSections) => void;
  addSaveFunction: (saveFunction: SaveFunctions[number]) => void;
  addDiscardFunction: (discardFunction: DiscardFunctions[number]) => void;
};
type GeneralInfoSaveState = {
  buffer: number;
  bufferKind: string;
  hideTrack: boolean;
  dedicatedTrackString: string;
};
export function GeneralInfoSection({ removeSaveFunction, addSaveFunction, addDiscardFunction }: GeneralInfoProps) {
  const dispatch = useDispatch();
  const mapping = useMapping();
  const org = useSelector(organizationSelector);
  const studentFields = useSelector((state: TRootState) => state.allocation.studentFields);
  const allReservations = useSelector((state: TRootState) => state.allocation.reservations);
  const allRooms = useSelector((state: TRootState) => state.allocation.roomObjects);
  const changeRequestObjects = useSelector((state: TRootState) => state.issueList.changeRequestObjects);
  const studentObjects = useSelector((state: TRootState) => state.allocation.studentObjectState.studentObjects);
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const courseObjects = useSelector((state: TRootState) => state.allocation.courseObjects);
  const track = useSelector((state: TRootState) => state.drawer.clicked.track);
  const courseId = useSelector((state: TRootState) => state.drawer.clicked.courseId);
  const viewLink = useSelector((state: TRootState) => state.allocation.viewerLink);
  const timezone = useSelector((state: TRootState) => state.allocation.timeZone);
  const trackType = mapping.getId('track');
  const studentType = mapping.getId('student');
  const bufferFieldId = mapping.getId('buffer');
  const hideFieldId = mapping.getId('hideTrack');
  const dedicatedFieldId = mapping.getId('dedicatedTrack');

  const [dedicatedTrackString, setDedicatedTrackString] = useState<string>();
  const { dedicatedPercentage, parsedDedicatedTrack } = useDedicatedSectionInfo(dedicatedTrackString);
  const [bufferKind, setBufferKind] = useState<string>();
  const [buffer, setBuffer] = useState<number>();
  const [hideTrack, setHideTrack] = useState<boolean>();
  const initialState = useRef<Partial<GeneralInfoSaveState>>({});

  useEffect(() => {
    // Do not add possibility to save if nothing changed
    if (
      isDefined(initialState.current.buffer) &&
      isDefined(initialState.current.bufferKind) &&
      isDefined(initialState.current.hideTrack) &&
      isDefined(initialState.current.dedicatedTrackString) &&
      (initialState.current.buffer !== buffer ||
        initialState.current.bufferKind !== bufferKind ||
        initialState.current.hideTrack !== hideTrack ||
        initialState.current.dedicatedTrackString !== dedicatedTrackString)
    ) {
      addSaveFunction({ fn: saveGeneralInfo, id: 'GeneralInfoSection' });
      dispatch(setChangesToDiscard({ section: 'GeneralInfoSection', discard: true }));
    } else {
      dispatch(setChangesToDiscard({ section: 'GeneralInfoSection', discard: false }));
    }

    addDiscardFunction({ fn: discard, id: 'GeneralInfoSection' });
    return () => {
      removeSaveFunction('GeneralInfoSection');
    };
  }, [track, trackObjects, mapping, bufferKind, buffer, hideTrack, dedicatedTrackString]);

  useEffect(() => {
    if (isDefined(studentType) && Object.keys(studentFields).length <= 0) {
      dispatch(fetchStudentFields(mapping));
    }
  }, [mapping, studentFields, studentType]);

  const clickedTrackObject = useMemo(() => {
    return trackObjects.find((obj) => track === obj.id.toString());
  }, [track, trackObjects]);

  useEffect(() => {
    if (!isDefined(clickedTrackObject)) {
      setBuffer(0);
      setBufferKind('');
      setHideTrack(false);
      return () => {};
    }

    const { size, isPercent } = parseBuffer(clickedTrackObject, mapping);
    const hideTrackParsed = mapping.parse('hideTrack', clickedTrackObject);

    setBuffer(size);
    setBufferKind(isPercent ? '%' : '');
    setHideTrack(hideTrackParsed);

    const state = initialState.current;
    state.buffer = size;
    state.bufferKind = isPercent ? '%' : '';
    state.hideTrack = hideTrackParsed;
    state.dedicatedTrackString = JSON.stringify(mapping.parse('dedicatedTrack', clickedTrackObject));

    // reset the sate if we change selected track
    return () => {
      state.buffer = undefined;
      state.bufferKind = undefined;
      state.dedicatedTrackString = undefined;
      state.hideTrack = undefined;
    };
  }, [mapping, clickedTrackObject]);

  function firstCommentFieldId(): number {
    return numberArrayParser(viewLink.commentField)[0];
  }

  const onBufferChange = (value: number | null) => {
    if (!isNumber(value)) {
      setBuffer(0);
      return;
    }
    setBuffer(value);
  };

  const saveGeneralInfo = async () => {
    if (dedicatedPercentage > 100) throw new Error(language.dedicationCapacityTooLarge);

    const clickedTrack = trackObjects.find((obj) => track === obj.id.toString());
    if (!isDefined(clickedTrack) || !isDefined(buffer) || !isDefined(bufferKind) || !isDefined(dedicatedTrackString))
      return;

    const valid = validBufferSize(buffer, bufferKind, clickedTrack, mapping);
    if (!isEmpty(valid)) {
      throw new Error(valid);
    }
    const bufferValue = buffer + bufferKind;
    const hideValue = hideTrack ? '1' : '0';
    const fields = [
      ...updateFieldifChanged(clickedTrack, bufferValue, bufferFieldId),
      ...updateFieldifChanged(clickedTrack, dedicatedTrackString, dedicatedFieldId),
      ...updateFieldifChanged(clickedTrack, hideValue, hideFieldId),
    ];
    const toPatch = patch(clickedTrack, fields);
    if (isDefined(toPatch)) {
      await toPatch();
    }
  };

  const presentName = (id: number, objects: TTEObject[], shorthand: MappingShorthandField) => {
    const object = objects.find((c) => c.id === id);
    if (!isDefined(object)) {
      return `MissingName[${id}]`;
    }
    return mapping.parse(shorthand, object);
  };

  const findNames = (shorthands: MappingShorthandField[], id: number, objects: TTEObject[]) =>
    shorthands.map((s) => findName(id, objects, s)).join(' ');

  const findName = (id: number, objects: TTEObject[], shorthand: MappingShorthandField) =>
    presentName(id, objects, shorthand);

  const present = (ids: number[], objects: TTEObject[], shorthands: MappingShorthandField[], typeId: number) => {
    if (ids.length === 0) {
      return '-';
    }
    return ids
      .map<React.ReactNode>((id) => objectLink(id, typeId, findNames(shorthands, id, objects)))
      .reduce((acc, item) => (acc === null ? [item] : [acc, ', ', item]), null);
  };

  const presentReservationFields = () => {
    const { fields } = presentReservationRanges(asInt([track]), allReservations, timezone, viewLink.commentField);
    if (fields.size === 0) {
      return '-';
    }
    return <Tooltip title={printSet(fields)}>{printSetMax(fields, 5)}</Tooltip>;
  };

  const presentReservations = () => {
    const { ranges, count, days } = presentReservationRanges(asInt([track]), allReservations, timezone);
    if (ranges.size === 0) {
      return '-';
    }
    return (
      <Tooltip title={printSet(ranges)}>
        {count.size} {language.reservations}
        <div>{printDaysOfWeek(days)}</div>
        <div>{printSetMax(ranges, 11)}</div>
      </Tooltip>
    );
  };

  const presentRooms = () => {
    const { sizes } = new PresentRooms('room', mapping, allRooms).present(asInt([track]), allReservations);
    return <div>{presentSet(sizes)}</div>;
  };

  function currentTracks() {
    return [track].map((id) => trackObjects.find((t) => t.id === Number(id))).filter(isDefined);
  }

  function objectLink(id: number, typeId: number, name: string) {
    const viewer = new ViewerLink(org, viewLink);
    const link = viewer.objectLink(id, typeId);
    return (
      <a key={`olx${id}`} href={link} className="objectlink" target="_blank" rel="noopener noreferrer">
        {name}
      </a>
    );
  }

  useEffect(() => {
    if (isDefined(studentType) && Object.keys(studentFields).length <= 0) {
      dispatch(fetchStudentFields(mapping));
    }
  }, [mapping, studentFields, studentType]);

  const calc = new Calc(mapping, currentTracks());
  const spotsTaken = calc.spotsTaken(studentObjects);

  const commentSection = isValidId(firstCommentFieldId()) && (
    <GeneralInfoRow
      title={mapping.fieldname(firstCommentFieldId())}
      value={
        <div className="refresh">
          {presentReservationFields()}
          <Tooltip title="Refresh">
            <Button
              className="refresh__button"
              size="small"
              shape="circle"
              type="text"
              icon={<ReloadOutlined />}
              onClick={() =>
                dispatch(
                  reloadTrackAndReservations({
                    mapping,
                    trackIds: trackObjects.map((t) => t.id),
                    reloadReservations: true,
                  }),
                )
              }
            />
          </Tooltip>
        </div>
      }
    />
  );

  const roomSection = mapping.isMapped('room') && (
    <>
      <GeneralInfoRow
        title={mapping.typename('room')}
        value={present(
          new PresentRooms('room', mapping, allRooms).roomIds(asInt([track]), allReservations),
          allRooms,
          ['roomName'],
          mapping.getId('room'),
        )}
      />
      <GeneralInfoRow title={mapping.fieldname('roomSize')} value={presentRooms()} />
    </>
  );

  const changeRequest = () => {
    if (!mapping.isMapped('changeRequest')) {
      return false;
    }

    const ids = new PresentRooms('changeRequest', mapping, changeRequestObjects).roomIds(
      asInt([track]),
      allReservations,
    );

    if (ids.length === 0) {
      return false;
    }

    return (
      <GeneralInfoRow
        title={mapping.typename('changeRequest')}
        value={present(ids, changeRequestObjects, ['changeRequestName'], mapping.getId('changeRequest'))}
      />
    );
  };

  return (
    <>
      <TEDrawerSection label={language.generalInfo}>
        <GeneralInfoRowsWrapper>
          <GeneralInfoRow
            title={mapping.typename('track')}
            value={present([Number(track)], trackObjects, ['activityType', 'trackNumber'], mapping.getId('track'))}
          />
          <GeneralInfoRow
            title={mapping.typename('course')}
            value={present([courseId], courseObjects, ['courseLabel'], mapping.getId('course'))}
          />
          <GeneralInfoRow
            title={language.schedule}
            value={
              <>
                {presentReservations()}{' '}
                {fullViewerLink({ allReservations, org, timezone, objectId: track, objectType: trackType, viewLink })}
              </>
            }
          />
          {roomSection}
          {changeRequest()}
          {commentSection}
        </GeneralInfoRowsWrapper>
      </TEDrawerSection>
      <TEDrawerSection label={language.allocation}>
        <MultiProgress
          value={spotsTaken}
          compact
          end={calc.buffer()}
          max={calc.maxStudents()}
          explainValue={language.allocated}
          explainEnd={mapping.fieldname('buffer')}
          formatHelp={() => calc.presentBuffer()}
        />
        <GeneralInfoRowsWrapper>
          <GeneralInfoRow
            title={language.allocated}
            value={
              <>
                {spotsTaken}
                <Link
                  style={{ marginLeft: '10px' }}
                  to={`${paths.studentAdjustment}?${nameOfStateFilterSearchParam}=${jsonToStateFilterUrlParam({
                    courseId: convertToString(courseId),
                    trackIds: [track],
                  })}`}
                >
                  {language.viewStudents}
                </Link>
              </>
            }
          />
          {mapping.isMapped('maxStudents') && (
            <GeneralInfoRow
              title={mapping.fieldname('maxStudents')}
              value={
                <MaxStudentsSection
                  removeSaveFunction={removeSaveFunction}
                  clickedTrack={clickedTrackObject}
                  addSaveFunction={addSaveFunction}
                  addDiscardFunction={addDiscardFunction}
                />
              }
            />
          )}
          {mapping.isMapped('buffer') && (
            <GeneralInfoRow
              title={mapping.fieldname('buffer')}
              value={
                <div className="batch-grid">
                  <InputNumber className="buffer__input" min={0} max={9000} value={buffer} onChange={onBufferChange} />
                  <Select
                    defaultValue=""
                    onChange={setBufferKind}
                    value={bufferKind}
                    options={[
                      { value: '', label: 'seats' },
                      { value: '%', label: '%' },
                    ]}
                    popupMatchSelectWidth={false}
                  />
                </div>
              }
            />
          )}
          {mapping.isMapped('hideTrack') && (
            <GeneralInfoRow
              title={mapping.fieldname('hideTrack')}
              value={<Switch checked={hideTrack} onChange={(value) => setHideTrack(value)} />}
            />
          )}
          {mapping.isMapped('dedicatedTrack') && (
            <GeneralInfoRow
              title={mapping.fieldname('dedicatedTrack')}
              value={
                <DedicatedTrackSection
                  setDedicatedTrackString={setDedicatedTrackString}
                  parsedDedicatedTrack={parsedDedicatedTrack}
                  addDiscardFunction={addDiscardFunction}
                  dedicatedPercentage={dedicatedPercentage}
                />
              }
            />
          )}
          <LinkedInfo
            removeSaveFunction={removeSaveFunction}
            addSaveFunction={addSaveFunction}
            addDiscardFunction={addDiscardFunction}
          />
        </GeneralInfoRowsWrapper>
      </TEDrawerSection>
    </>
  );

  function discard() {
    setBuffer((prev) => initialState.current.buffer ?? prev);
    setBufferKind((prev) => initialState.current.bufferKind ?? prev);
    setHideTrack((prev) => initialState.current.hideTrack ?? prev);
  }
}

export function patch(track: TTEObject, fields: TTEFieldValue[]) {
  if (fields.length === 0) {
    return undefined;
  }
  return () => allocationApi.updateObjects({ data: [{ id: track.id, fields }] });
}

export function updateFieldifChanged(track: TTEObject, value: string, fieldId: number) {
  const field = track?.fields?.find((f) => f.fieldId === fieldId);
  if (isDefined(field?.values[0]) && field?.values[0] === value) return [];
  return setFieldValue(value, fieldId);
}

type FullViewerLinkProps = {
  org: TOrganizationPublic;
  viewLink: ViewLink;
  timezone: string;
  objectId: string;
  objectType: number;
  allReservations: TTEReservation[];
};
export function fullViewerLink({
  org,
  viewLink,
  timezone,
  objectId,
  objectType,
  allReservations,
}: FullViewerLinkProps) {
  const viewer = new ViewerLink(org, viewLink);
  const { begins, ends } = presentReservationRanges(asInt([objectId]), allReservations, timezone);
  const period =
    begins.size === 0
      ? ''
      : `&p=${toDateInURL(Math.min(...begins), timezone)}-${toDateInURL(Math.max(...ends), timezone)}`;
  const ids = viewer.idsWithType([objectId], objectType);
  const objects = `&objects=${ids}`;
  const link = `${viewer.scheduleLink()}${period}${objects}`;
  return (
    <div>
      <a key={`fvl${ids}`} href={link} target="_blank" rel="noopener noreferrer">
        {language.viewSchedule}
      </a>
    </div>
  );
}

function asInt(ids: string[]): number[] {
  return ids.map(Number);
}
