import { Badge, Typography } from 'antd';
import React from 'react';
import { IssueListSection } from './IssueListSections';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';
import intl from '../../../i18n/intl';
import { uniq } from 'lodash';
import { useMapping } from '../../services/mapping';

const language = intl.messages as Record<string, string>;

export function IssueListContent() {
  const issues = useSelector((state: TRootState) => state.issueList.issues);
  const issuesCount = useSelector((state: TRootState) => state.issueList.issuesCount);
  const changeRequests = useSelector((state: TRootState) => state.issueList.changeRequests);
  const mapping = useMapping();
  const [coursesPending, tracksPending] = changeRequests.reduce<[number[], number[]]>(
    (acc, changeRequest) => {
      const [courses, tracks] = acc;
      return [uniq([...courses, changeRequest.courseId]), uniq([...tracks, ...changeRequest.trackIds])];
    },
    [[], []],
  );

  return (
    <div className="issue-list-content__wrapper">
      <div>
        <Typography.Text strong className="issue-list-content__first-item__title">
          {language.issueList}
        </Typography.Text>
        <Badge size="default" showZero count={issuesCount} />
      </div>
      {issues.overEnrollment.courseIds.length > 0 ? (
        <IssueListSection
          helpParagraph={`${issues.overEnrollment.courseIds.length} ${mapping.typename('course')}`}
          issueText={language.overEnrollmentIssueText}
          coursesToFilter={issues.overEnrollment.courseIds}
          useWarningIcon
        />
      ) : (
        ''
      )}
      {issues.noAllocation.trackIds.length > 0 && issues.noAllocation.courseIds.length ? (
        <IssueListSection
          helpParagraph={`${issues.noAllocation.trackIds.length} ${mapping.typename('track')}`}
          issueText={language.noAllocationIssueText}
          coursesToFilter={issues.noAllocation.courseIds}
          useWarningIcon
        />
      ) : (
        ''
      )}
      {coursesPending.length > 0 && tracksPending.length ? (
        <IssueListSection
          helpParagraph={`${tracksPending.length} ${mapping.typename('track')}`}
          issueText={language.changeRequestIssueText}
          coursesToFilter={coursesPending}
        />
      ) : (
        ''
      )}
    </div>
  );
}
