import { DedicatedTrack, Mapping, TTEObject } from '@timeedit/registration-shared';
import { EAppProperties, EApplicationObjectTypeGroup } from '@timeedit/types/lib/enums';

export const CourseFirstField = 'courseLabel';
export const CourseSecondField = 'courseTitle';

export function presentCategory(mapping: Mapping) {
  return (dedicated: DedicatedTrack) => {
    if (dedicated.kind === 'category') {
      return dedicated.data.map((category) => `${mapping.fieldname(category.id)} / ${category.value}`).join(', ');
    }
    return '';
  };
}

export function presentRelation(mapping: Mapping) {
  return (dedicated: DedicatedTrack, fetchedObjects: TTEObject[]) => {
    if (dedicated.kind === 'relation') {
      const foundRelations = fetchedObjects.reduce<TTEObject[]>((foundRelations, obj) => {
        const found = dedicated.data.some((data) => data.id === obj.id);
        if (found) {
          return [...foundRelations, obj];
        }

        return foundRelations;
      }, []);

      if (foundRelations.length > 0) {
        return foundRelations.map(presentLabel(mapping)).join(', ');
      }
    }
    return '';
  };
}

export function presentLabel(mapping: Mapping) {
  return (object: TTEObject) => {
    return mapping.string(
      {
        objectTypeGroup: mapping.getObjectTypeKeyById(object.types[0]) as EApplicationObjectTypeGroup,
        appProperty: EAppProperties.LABEL,
      },
      object,
    );
  };
}
