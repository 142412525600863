import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { TTEObject, isDefined } from '@timeedit/registration-shared';
import { Select, Spin, notification } from 'antd';
import { debounce } from 'lodash';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Mapping, MappingShorthand } from '../../services/mapping';
import { MappingShorthandField } from '../../services/mapping/mapping.service';
import { allocationApi } from '../../services/registration-allocation.service';
import { useSelector } from 'react-redux';
import { configService } from '../../../services/config.service';
import { DiscardFunctions } from './BulkAllocationDrawer';
import { TRootState } from '../../..';
import { updateSearchedObjects } from '../../pages/slices/allocation.slice';
import { Dispatch } from 'redux';
import { convertToString } from '../../pages/BulkAllocationPage/utils';
import { createCategoryDropdowns } from './CategoryDropdowns';
import { SelectProps } from 'antd/lib';
import intl from '../../../i18n/intl';

const language = intl.messages as Record<string, string>;

type SearchOption = { value: number; label: string };

type ObjectSearcherProps = {
  placeholder: string;
  style?: React.CSSProperties;
  className?: string;
  mapping: Mapping;
  wide?: boolean;
  fields: MappingShorthandField[];
  type: MappingShorthand;
  ids?: number[];
  objects?: TTEObject[];
  onlyVirtual?: boolean;
  dispatch: Dispatch<any>;
  onChange: (ids: number[], option?: SearchOption | SearchOption[]) => void;
  resetCallback?: MutableRefObject<DiscardFunctions[number]['fn'][]>;
  hideCategoryDropdowns?: boolean;
  size?: SelectProps['size'];
  alwaysEmpty?: boolean; // If the selector should always be empty
  changeCallbackOnClick?: boolean; // Will immediately call onChange when an option is selected and will not do it when dropdown is closed
};

export default function ObjectSearcher(props: ObjectSearcherProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<SearchOption[]>([]);
  const [currentIds, setCurrentIds] = useState<number[]>([]);
  const [categoryValues, setCategoryValues] = useState<Map<number, string[]>>(new Map());
  const [loading, setLoading] = useState(false);
  const fields = useSelector((state: TRootState) => state.allocation.fields);
  const selectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDefined(props.objects)) {
      props.dispatch(updateSearchedObjects(props.objects));
      const newOptions = sort(createOptions(props.objects));
      setOptions(newOptions);
      if (isDefined(props.ids)) {
        setCurrentIds(props.ids);
        return;
      }
      setCurrentIds(newOptions.map((o) => o.value));
      return;
    }
    if (!isDefined(props.ids)) {
      debounceSearch('');
      return;
    }
    props.resetCallback?.current.push(() => handleChange(props.ids));
    if (props.ids.length === 0) {
      handleChange(props.ids);
      debounceSearch('');
      return;
    }
    allocationApi.loadObjects({ ids: props.ids, useCache: true }).then((response) => {
      const newOptions = createOptions(response.data);
      if (newOptions.length > 0) {
        props.dispatch(updateSearchedObjects(response.data));
        setOptions(newOptions);
        handleChange(props.ids);
      }
    });
  }, [props.ids, props.objects]);

  function createOptions(objects: TTEObject[]) {
    return objects
      .filter((o) => (isDefined(props.onlyVirtual) && props.onlyVirtual ? o.virtual : true))
      .map((object: TTEObject) => ({
        value: object.id,
        label: name(object),
      }));
  }

  function sort(options: SearchOption[]) {
    return options.sort((a, b) => a.label.localeCompare(b.label));
  }

  const name = (object: TTEObject) => {
    return props.fields.map((shorthand) => props.mapping.string(shorthand, object)).join(' ');
  };

  const handleChange = (ids: number[] | undefined) => {
    if (!isDefined(ids)) {
      return;
    }
    setCurrentIds(ids);
    const changeParent = !isDropdownOpen;
    if (changeParent || props.changeCallbackOnClick) {
      props.onChange(ids);
    }
  };

  useEffect(() => {
    if (!isDefined(selectorRef.current)) {
      return;
    }
    const list = selectorRef.current.getElementsByClassName('ant-select-selector');
    const behavior = isDropdownOpen ? 'smooth' : undefined;
    for (let index = 0; index < list.length; index++) {
      const item = list.item(index);
      if (isDefined(item)) {
        item.scrollTo({
          top: 100000,
          left: 0,
          behavior,
        });
      }
    }
  }, [currentIds]);

  const handleDropdown = (open: boolean) => {
    setIsDropdownOpen(open);
    if (open) {
      return;
    }
    if (!props.changeCallbackOnClick) {
      props.onChange(currentIds);
    }
  };

  const debounceSearch = useCallback(
    debounce((text: string) => {
      setLoading(true);
      const category = Array.from(categoryValues, ([fieldId, values]) => ({ fieldId, values }));
      allocationApi
        .loadObjects({
          text,
          fields: props.fields.map((f) => props.mapping.getId(f)),
          typeId: props.mapping.getId(props.type),
          useCache: true,
          limit: 20,
          category,
        })
        .then((response) => {
          props.dispatch(updateSearchedObjects(response.data));
          setOptions(createOptions(response.data));
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          notification.error({
            duration: 0,
            key: configService().NOTIFICATION_KEY,
            message: 'Object searcher failed.',
            description: ` Details:  ${e}`.slice(0, 600),
          });
          console.error(e);
        });
    }, 300),
    [props.mapping, props.fields],
  );

  const handleSearch = (searchText: string) => {
    debounceSearch(searchText);
  };

  const categoryDropdowns = useMemo(() => {
    if (props.hideCategoryDropdowns) {
      return '';
    }
    const changeValues = (id: number, values: string[]) => {
      setCategoryValues((prev) => {
        prev.set(id, values);
        return prev;
      });
      debounceSearch('');
    };
    return createCategoryDropdowns(props.mapping, props.type, fields, changeValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, props.fields]);

  const searchClassName = `objectsearcher-list drawer__item--margin ${props.wide ? '  objectsearcher-fixed' : ''}`;

  const selector = (
    <div ref={selectorRef}>
      <Select
        mode="multiple"
        showSearch
        allowClear
        size={props.size}
        value={props.alwaysEmpty ? [] : currentIds}
        className={searchClassName}
        placeholder={`${language.search} ${props.placeholder}`}
        filterOption={false}
        style={props.style}
        defaultActiveFirstOption={false}
        suffixIcon={<SearchOutlined />}
        onSearch={handleSearch}
        onChange={handleChange}
        options={options}
        onDropdownVisibleChange={handleDropdown}
        removeIcon={<DeleteOutlined />}
        notFoundContent={loading ? <Spin size="small" /> : <span>No search results found</span>}
      />
    </div>
  );

  return (
    <div className={`${convertToString(props.className)}`}>
      {selector}
      {categoryDropdowns}
    </div>
  );
}
