import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { TRootState } from '../../../index';
import { BatchAllocateContent } from './BatchAllocateContent';
import './BatchAllocateModal.scss';
import { AllocateModalFooter } from './AllocateModalFooter';
import { MAX_STEPS, MaxStepRange } from './utils';
import intl from '../../../i18n/intl';
import { countSelectedTracks } from '../Table/utils';
import { useMapping } from 'registration-allocation/services/mapping';

const language = intl.messages as Record<string, string>;

type AllocationBatchModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function BatchAllocateModal({ open, setOpen }: AllocationBatchModalProps) {
  const selectedTrackIds = useSelector((state: TRootState) => state.allocation.selected.tracks);
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const mapping = useMapping();

  const nbrOfSelectedTracks = countSelectedTracks({ selectedTracks: selectedTrackIds, trackObjects });

  const [currentStep, setCurrentStep] = useState<MaxStepRange<typeof MAX_STEPS>>(0);

  return (
    <div>
      <Modal
        className="batch-allocate-modal"
        title={`Allocate ${mapping.typename('student').toLowerCase()} - ${nbrOfSelectedTracks} ${mapping.typename('track').toLowerCase()}(s) ${language.selected}`}
        open={open}
        closable
        onCancel={onCancel}
        destroyOnClose
        width={'auto'}
        maskClosable={false}
        footer={<AllocateModalFooter currentStep={currentStep} setCurrentStep={setCurrentStep} onCancel={onCancel} />}
      >
        <BatchAllocateContent currentStep={currentStep} />
      </Modal>
    </div>
  );

  function onCancel() {
    setOpen(false);
  }
}
