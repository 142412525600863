import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMapping } from '../../services/mapping';
import { TRootState } from '../../..';
import { Button, notification } from 'antd';
import { exhaustiveMatchingGuard, isErrorWithMessage, StudentInfo } from '@timeedit/registration-shared';
import {
  setAllocationFailedGroups,
  setAllocationSuccessGroups,
  updateTrackObjects,
} from '../../pages/slices/allocation.slice';
import { configService } from '../../../services/config.service';
import { allocationApi } from '../../services/registration-allocation.service';
import { isEmpty } from 'lodash';
import { fetchTrackConflicts, reloadTrackAndReservations } from '../../pages/slices/fetch.slice';
import intl from '../../../i18n/intl';
import { inStepRange, MAX_STEPS, MaxStepRange } from './utils';
import { loadObjectById } from '../../pages/BulkAllocationPage';
import { useAbortController } from '../../hooks/UseAbortController';

const language = intl.messages as Record<string, string>;

type AllocateModalFooterProps = {
  currentStep: MaxStepRange<typeof MAX_STEPS>;
  setCurrentStep: React.Dispatch<React.SetStateAction<MaxStepRange<typeof MAX_STEPS>>>;
  onCancel: () => void;
};

export function AllocateModalFooter({ currentStep, setCurrentStep, onCancel }: AllocateModalFooterProps) {
  const studentInfo = useSelector((state: TRootState) => state.allocation.selected.studentInfo);
  const next = useCallback(() => createNext(setCurrentStep), [setCurrentStep]);
  const previous = useCallback(() => createPrevious(setCurrentStep), [setCurrentStep]);
  const { allocateLoading, batchAllocate } = useBatchAllocate();
  const { findConflicts } = useFindConflicts();

  return <div>{showButtonByStep(currentStep)}</div>;

  function showButtonByStep(step: MaxStepRange<typeof MAX_STEPS>) {
    switch (step) {
      case 0:
        return (
          <Button type="primary" onClick={next}>
            {language.next}
          </Button>
        );
      case 1:
        return (
          <div>
            <Button onClick={previous}>{language.previous}</Button>{' '}
            <Button type="primary" onClick={next}>
              {language.next}
            </Button>
          </div>
        );
      case 2:
        return (
          <div>
            <Button onClick={previous}>{language.previous}</Button>{' '}
            <Button
              type="primary"
              loading={allocateLoading}
              onClick={async () => {
                const batchPromise = batchAllocate(studentInfo);
                const conflictsPromise = findConflicts();

                await Promise.all([batchPromise, conflictsPromise]);

                next();
              }}
            >
              {language.allocate}
            </Button>
          </div>
        );
      case 3:
        return (
          <Button type="primary" onClick={onCancel}>
            {language.close}
          </Button>
        );
      default:
        return exhaustiveMatchingGuard(step as never);
    }
  }
}

function useFindConflicts() {
  const studentObjects = useSelector((state: TRootState) => state.allocation.studentObjectState.studentObjects);
  const selectedStudents = useSelector((state: TRootState) => state.allocation.selected.students);
  const selectedTracks = useSelector((state: TRootState) => state.allocation.selected.tracks);
  const dispatch = useDispatch();
  const mapping = useMapping();
  const { signal } = useAbortController();

  const findConflicts = useCallback(runConflicts, [
    dispatch,
    mapping,
    selectedStudents,
    studentObjects,
    selectedTracks,
    signal,
  ]);
  async function runConflicts() {
    const studentMembers = studentObjects
      .filter((obj) => selectedStudents.includes(obj.id))
      .flatMap((obj) => obj.members.flatMap((m) => m.objectId));

    const tracks = await loadObjectById(studentMembers, mapping.getId('track'), true);
    dispatch(updateTrackObjects(tracks));

    const trackIds = selectedTracks.map((track) => parseInt(track, 10));

    await fetchTrackConflicts({
      dispatch,
      trackIds: [...tracks.map((t) => t.id), ...trackIds],
      abortSignal: signal(),
    });
  }

  return useMemo(() => ({ findConflicts }), [findConflicts]);
}

function useBatchAllocate() {
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const [allocateLoading, setAllocateLoading] = useState<boolean>(false);
  const { signal } = useAbortController();
  const dispatch = useDispatch();
  const mapping = useMapping();

  const batchAllocate = useCallback(runAllocation, [trackObjects, dispatch, mapping, signal]);

  async function runAllocation(studentInfo: StudentInfo) {
    setAllocateLoading(true);
    dispatch(setAllocationFailedGroups({}));
    dispatch(setAllocationSuccessGroups({}));
    if (Object.keys(studentInfo).length === 0) {
      setAllocateLoading(false);
      return notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: 'No students available to allocate.',
        description: '',
      });
    }

    try {
      const response = await allocationApi.allocate({
        data: { studentInfo, conflictControl: 'partial' },
        successMessage: true,
        signal: signal(),
        errorMessage: false,
      });

      if (response.status === 200) {
        if (!isEmpty(response.data?.failedGroups)) {
          dispatch(setAllocationFailedGroups(response.data.failedGroups));
        }
        if (!isEmpty(response.data?.successGroups)) {
          dispatch(setAllocationSuccessGroups(response.data?.successGroups));
        }
        dispatch(
          reloadTrackAndReservations({ mapping, trackIds: trackObjects.map((t) => t.id), reloadReservations: false }),
        );
        setAllocateLoading(false);
      } else {
        throw new Error(`Could not allocate students to tracks.`);
      }
    } catch (error) {
      const errorMessage = isErrorWithMessage(error) ? error?.message : null;
      if (errorMessage !== 'canceled') {
        notification.error({
          duration: 0,
          key: configService().NOTIFICATION_KEY,
          message: 'Allocation failed.',
          description: ` Details: \n ${error} \n ${JSON.stringify(studentInfo)}`.slice(0, 600),
        });
      }
    }
    setAllocateLoading(false);
    return Promise.resolve();
  }

  return useMemo(() => ({ batchAllocate, allocateLoading }), [allocateLoading, batchAllocate]);
}
function createNext(setter: Dispatch<SetStateAction<MaxStepRange<typeof MAX_STEPS>>>) {
  setter((previous) => {
    const newVal = previous + 1;
    if (inStepRange(newVal, MAX_STEPS)) {
      return newVal;
    }
    return previous;
  });
}
function createPrevious(setter: Dispatch<SetStateAction<MaxStepRange<typeof MAX_STEPS>>>) {
  setter((previous) => {
    const newVal = previous - 1;
    if (inStepRange(newVal, MAX_STEPS)) {
      return newVal;
    }
    return previous;
  });
}
