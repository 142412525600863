import React from 'react';
import { Button, Spin } from 'antd';
import { BaseButtonProps } from 'antd/es/button/button';
import { useAutoAllocation } from './useAutoAllocation';

export default function AutoAllocateButton(props: BaseButtonProps) {
  const { size } = props;
  const { startAutoAllocation, autoAllocationRunning: isRunning } = useAutoAllocation();

  return (
    <Button
      icon={isRunning && <Spin size="small" />}
      size={size}
      type="default"
      onClick={startAutoAllocation}
      disabled={isRunning}
    >
      Auto Allocate
    </Button>
  );
}
