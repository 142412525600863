import { AllowRegistrationStatus, isDefined, isString, UnsafeRecord } from '@timeedit/registration-shared';
import { teServerDateFormat } from '../../settings/Date';
import moment from 'moment';
import { getMapping, useMapping } from '../../services/mapping';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { convertToString, formatDateString } from '../../pages/BulkAllocationPage/utils';
import { CourseDataSource, TrackListDataSource, TracksDataSource } from './utils';
import { CourseFirstField } from 'registration-allocation/utils/text';

export const DUMMY_ROW_NAME = 'dummyrow-';
const DUMMY_ROW_LOADING = `${DUMMY_ROW_NAME}loading-`;

export function useCreateDataSource(): CourseDataSource[] {
  const trackLists = useSelector((state: TRootState) => state.allocation.trackLists);
  const courses = useSelector((state: TRootState) => state.allocation.courseObjects);
  const expandedCourse = useSelector((state: TRootState) => state.allocation.expandedCourses);

  const mapping = useMapping();

  return useMemo(() => {
    const trackFieldIds =
      mapping.store.mappingData.objectTypes
        .find((objType) => objType.applicationObjectTypeGroup === mapping.getKey('track'))
        ?.fields.reduce((fieldIds: number[], field) => {
          if (isDefined(field.fieldId)) return [...fieldIds, field.fieldId];
          return fieldIds;
        }, []) ?? [];

    const courseFieldIds =
      mapping.store.mappingData.objectTypes
        .find((objType) => objType.applicationObjectTypeGroup === mapping.getKey('course'))
        ?.fields.reduce((fieldIds: number[], field) => {
          if (isDefined(field.fieldId)) return [...fieldIds, field.fieldId];
          return fieldIds;
        }, []) ?? [];

    if (courseFieldIds.length === 0 || trackFieldIds.length === 0) return [];

    const courseDataSources = courses.reduce<CourseDataSource[]>(
      (courseDataSources: CourseDataSource[], currentCourse) => {
        const courseFieldValueDataSource = courseFieldIds.reduce<UnsafeRecord<string, string>>(
          (courseDataSource, fieldId) => {
            const fieldValue = mapping.string(fieldId, currentCourse);

            if (!isDefined(fieldValue)) return courseDataSource;

            const key = mapping.getFieldKeyById(fieldId);

            if (Object.keys(courseDataSource).includes(key)) {
              return courseDataSource;
            }
            return { ...courseDataSource, [key]: mapping.stringKey(key, currentCourse) };
          },
          {},
        );

        const isLoading = !expandedCourse.includes(currentCourse.id.toString());
        const courseTrackLists = trackLists.filter((trackList) => trackList.courseId === currentCourse.id);

        const tracksListDataSources = courseTrackLists.reduce<TrackListDataSource[]>(
          (trackListDataSources, trackList) => {
            const tracksDataSources = trackList.allocationObjects.reduce<TracksDataSource[]>(
              (tracksDataSources, allocObj) => {
                const trackDataSource = trackFieldIds.reduce<TracksDataSource>(
                  (trackDataSource, fieldId) => {
                    const fieldValue = mapping.string(fieldId, allocObj);

                    if (!isDefined(fieldValue)) return trackDataSource;

                    const key = mapping.getFieldKeyById(fieldId);

                    if (Object.keys(trackDataSource).includes(key)) {
                      return trackDataSource;
                    }

                    return { ...trackDataSource, [key]: mapping.stringKey(key, allocObj) };
                  },
                  {
                    id: allocObj.id.toString(),
                    key: allocObj.id.toString(),
                    courseId: trackList.courseId.toString(),
                    allocationObjects: [allocObj],
                  },
                );

                return [...tracksDataSources, trackDataSource];
              },
              [],
            );

            // We combine track data source into one track list data source
            const trackListDataSource: TrackListDataSource = {
              ...makeTrackListFieldDataSource(tracksDataSources),
              [mapping.getKey('activityType')]: trackList.activityType,
              allocationObjects: trackList.allocationObjects,
              id: trackList.key,
              key: trackList.key,
              courseId: trackList.courseId.toString(),
              children: tracksDataSources,
              loading: isLoading,
            };

            return [...trackListDataSources, trackListDataSource];
          },
          [],
        );

        // Creating a dummy child that can display loading component
        const courseChildren =
          tracksListDataSources.length === 0
            ? [
                {
                  key: `${dummyRowName(isLoading)}${currentCourse.id.toString()}`,
                  id: `${dummyRowName(isLoading)}${currentCourse.id.toString()}`,
                  courseId: currentCourse.id.toString(),
                  loading: isLoading,
                  allocationObjects: [],
                  children: undefined,
                },
              ]
            : tracksListDataSources;
        const courseDataSource: CourseDataSource = {
          ...courseFieldValueDataSource,
          id: currentCourse.id.toString(),
          key: currentCourse.id.toString(),
          children: courseChildren,
          trackLists: courseTrackLists,
        };
        return [...courseDataSources, courseDataSource];
      },
      [],
    );
    courseDataSources.sort(sortCourses);
    return courseDataSources;
  }, [trackLists, courses, mapping]);

  function sortCourses(a: CourseDataSource, b: CourseDataSource) {
    return sortKey(a).localeCompare(sortKey(b));
  }

  function sortKey(object: CourseDataSource) {
    return convertToString(object[mapping.getKey(CourseFirstField)]);
  }

  function dummyRowName(loading: boolean) {
    return loading ? DUMMY_ROW_LOADING : DUMMY_ROW_NAME;
  }
}

/**
 * We want to combine data from multiple tracks to create data that should be displayed
 * for one single track list.
 * E.g. if we have two tracks with different start dates, we want to display the earliest start date of all tracks.
 * @param tracksDataSource the calculated data source for each track that is connected to one track list
 * @returns combined data that should be displayed for one track list
 */
function makeTrackListFieldDataSource(tracksDataSource: TracksDataSource[]) {
  const mapping = getMapping();
  return tracksDataSource.reduce<Partial<TracksDataSource>>(
    (trackListFieldsDataSource, dataSource) => {
      const { startDate, endDate } = calculateDate({ trackListFieldsDataSource, dataSource });
      const allowRegistration = calculateAllowRegistration({ trackListFieldsDataSource, dataSource });
      const maxStudents = calculateMaxStudents({ trackListFieldsDataSource, dataSource });
      return {
        [mapping.getKey('startDate')]: startDate,
        [mapping.getKey('endDate')]: endDate,
        [mapping.getKey('allowRegistration')]: allowRegistration,
        [mapping.getKey('maxStudents')]: maxStudents,
      };
    },
    {
      [mapping.getKey('startDate')]: '-',
      [mapping.getKey('endDate')]: '-',
      [mapping.getKey('allowRegistration')]: '-',
      [mapping.getKey('maxStudents')]: '-',
    },
  );

  type CalculateDataSourceValues = {
    trackListFieldsDataSource: Partial<TracksDataSource>;
    dataSource: TracksDataSource;
  };

  function calculateMaxStudents({ dataSource, trackListFieldsDataSource }: CalculateDataSourceValues) {
    const maxStudents = dataSource[mapping.getKey('maxStudents')];
    if (!isString(maxStudents)) return trackListFieldsDataSource[mapping.getKey('maxStudents')];
    const parsedMaxStudents = Number(maxStudents);
    if (Number.isNaN(parsedMaxStudents)) return trackListFieldsDataSource[mapping.getKey('maxStudents')];

    const parsedGroupMaxStudents = Number(trackListFieldsDataSource[mapping.getKey('maxStudents')]);
    if (Number.isNaN(parsedGroupMaxStudents)) {
      return parsedMaxStudents.toString();
    }

    return (parsedMaxStudents + parsedGroupMaxStudents).toString();
  }

  function calculateAllowRegistration({ dataSource, trackListFieldsDataSource }: CalculateDataSourceValues) {
    const allowRegistration = dataSource[mapping.getKey('allowRegistration')];
    if (!isString(allowRegistration)) return trackListFieldsDataSource[mapping.getKey('allowRegistration')];

    // If any are open, the whole group is open, handle open case
    if (trackListFieldsDataSource[mapping.getKey('allowRegistration')] === AllowRegistrationStatus.enum.FORCE_OPEN)
      return trackListFieldsDataSource[mapping.getKey('allowRegistration')];
    if (allowRegistration === AllowRegistrationStatus.enum.FORCE_OPEN) return allowRegistration;

    // If nothing is open, but have during interval, handle during interval case
    if (trackListFieldsDataSource[mapping.getKey('allowRegistration')] === AllowRegistrationStatus.enum.DURING_INTERVAL)
      return trackListFieldsDataSource[mapping.getKey('allowRegistration')];
    if (allowRegistration === AllowRegistrationStatus.enum.DURING_INTERVAL) return allowRegistration;

    // If nothing is open nor during interval, handle closed case
    if (trackListFieldsDataSource[mapping.getKey('allowRegistration')] === AllowRegistrationStatus.enum.FORCE_CLOSE)
      return trackListFieldsDataSource[mapping.getKey('allowRegistration')];
    if (allowRegistration === AllowRegistrationStatus.enum.FORCE_CLOSE) return allowRegistration;

    return trackListFieldsDataSource[mapping.getKey('allowRegistration')];
  }

  function calculateDate({ dataSource, trackListFieldsDataSource }: CalculateDataSourceValues) {
    const startDate = dataSource[mapping.getKey('startDate')];
    const endDate = dataSource[mapping.getKey('endDate')];

    if (!isString(endDate) || !isString(startDate))
      return {
        startDate: trackListFieldsDataSource[mapping.getKey('startDate')],
        endDate: trackListFieldsDataSource[mapping.getKey('endDate')],
      };

    const parsedStartDate = moment(startDate, teServerDateFormat, true);
    const parsedEndDate = moment(endDate, teServerDateFormat, true);
    if (!parsedStartDate.isValid() || !parsedEndDate.isValid())
      return {
        startDate: trackListFieldsDataSource[mapping.getKey('startDate')],
        endDate: trackListFieldsDataSource[mapping.getKey('endDate')],
      };

    const groupStartDate = trackListFieldsDataSource[mapping.getKey('startDate')];
    const groupEndDate = trackListFieldsDataSource[mapping.getKey('endDate')];
    if (groupStartDate === '-' || groupEndDate === '-' || !isString(groupStartDate) || !isString(groupEndDate)) {
      return { startDate, endDate };
    }

    const startMomentDate = formatDateString(startDate);
    const endMomentDate = formatDateString(endDate);

    const beforeStartDate = startMomentDate.isBefore(groupStartDate);
    const afterEndDate = endMomentDate.isAfter(groupEndDate);

    const newStartDate = beforeStartDate ? startDate : trackListFieldsDataSource[mapping.getKey('startDate')];
    const newEndDate = afterEndDate ? endDate : trackListFieldsDataSource[mapping.getKey('endDate')];
    return { endDate: newEndDate, startDate: newStartDate };
  }
}
