import { TStartOrEndBatchSetRequest, TStartOrEndBatchSetResult } from '@timeedit/preferences-and-dm-commons/lib/src';
import api from '../../services/api.service';
import { configService } from '../../services/config.service';
import { TAPIRequestShorthand } from '../../types/api.type';
import {
  LoadBody,
  TTEFieldValue,
  AddStudentsToTracksOverrides,
  GetStaffCoursesBody,
  StudentInfo,
  UnsafeRecord,
} from '@timeedit/registration-shared';
import z from 'zod';
import { TFilterValue } from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';

export type GetStaffCoursesProps = Omit<GetStaffCoursesBody, 'fields'> & { signal?: AbortSignal } & {
  fields?: {
    exactSearchFields?: { fieldId: number; values: TFilterValue }[];
    generalSearchFieldIds?: number[];
    searchText?: string;
  };
};

type KeyValue = { [key: number]: number[] };
interface AllocateProps extends Omit<TAPIRequestShorthand, 'data' | 'endpoint'> {
  data: {
    studentInfo: StudentInfo;
    conflictControl: ConflictControlStrategy;
    overrides?: AddStudentsToTracksOverrides;
  };
}
export type ConflictControlStrategy = 'partial' | 'none';
interface DeAllocateProps extends Omit<TAPIRequestShorthand, 'data' | 'endpoint'> {
  data: { studentInfo: StudentInfo };
}

interface DeallocateAllProps extends Omit<TAPIRequestShorthand, 'data' | 'endpoint'> {
  data: { tracks: number[] };
}

interface TrackConflictProps extends Omit<TAPIRequestShorthand, 'data' | 'endpoint'> {
  data: { tracks: number[] };
}
interface StudentConflictProps extends Omit<TAPIRequestShorthand, 'data' | 'endpoint'> {
  data: { students: number[] };
}

interface UpdateObjectsProps extends Omit<TAPIRequestShorthand, 'data' | 'endpoint'> {
  data: {
    id: number;
    fields: TTEFieldValue[];
  }[];
}

interface MakeChangeRequestProps extends Omit<TAPIRequestShorthand, 'data' | 'endpoint'> {
  data: {
    trackWithChangeRequest: UnsafeRecord<number, number>;
    reservationComment?: { fieldId: string; comment: string };
  };
}

export type LoadObjects = LoadBody & { errorMessage?: boolean; signal?: AbortSignal };

const ConflictCheckResponse = createApiResponseSchema(z.custom<KeyValue>());

export const allocationApi = {
  ...api,
  allocate: (props: AllocateProps) =>
    api.post({
      ...props,
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/add-members`,
    }),
  deAllocate: (props: DeAllocateProps) =>
    api.post({
      ...props,
      successMessage: false,
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/remove-members`,
    }),

  deallocateAll: (props: DeallocateAllProps) =>
    api.post({
      ...props,
      successMessage: false,
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/remove-all-students`,
    }),

  findStudentConflicts: (props: StudentConflictProps) =>
    api.post({
      ...props,
      successMessage: false,
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/student-conflicts`,
    }),

  getConflicts: async (props: TrackConflictProps) => {
    const response = await api.post({
      ...props,
      successMessage: false,
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/track-conflicts`,
    });
    return ConflictCheckResponse.safeParseAsync(response);
  },

  updateObjects: (props: UpdateObjectsProps) =>
    api.post({
      ...props,
      successMessage: false,
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/update-objects`,
      data: props.data,
    }),

  findTypes: (payload: UnsafeRecord<string, unknown>) =>
    api.post({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/find-types`,
      data: payload,
      successMessage: false,
    }),

  findFields: (payload: UnsafeRecord<string, unknown>) =>
    api.post({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/find-fields`,
      data: payload,
      successMessage: false,
    }),

  registrationPeriodsStartOrEndBatchSet: (payload: TStartOrEndBatchSetRequest) =>
    api.post({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/batch-set-registration-periods`,
      successMessage: false,
      data: {
        ...payload,
        writeCoursesWithRegistrationRunning: payload.writeCoursewWithRegistrationRunning,
        writeCoursewWithRegistrationRunning: undefined,
      },
    }) as Promise<{ message: string; status: number; data: TStartOrEndBatchSetResult }>,

  clearCache: () =>
    api.post({
      successMessage: 'Cache cleared',
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/clear-cache`,
    }),

  loadObjects: ({ signal, ...data }: LoadObjects) => {
    return api.post({
      data,
      signal,
      successMessage: false,
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/load-objects`,
    });
  },

  loadReservationsFor: (objectIds: number[], typeId: number, useCache: boolean) =>
    api.post({
      successMessage: false,
      data: { objectIds, typeId, useCache },
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/load-reservations-for`,
    }),

  getMapping: async () => {
    const { data } = await api.get({ endpoint: `${configService().REACT_APP_REGISTRATION_URL}/mapping` });
    return data;
  },

  loadStudentsPerCourse: (courseId: number) =>
    api.get({
      successMessage: false,
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/load-students-per-course/${courseId}`,
    }),

  staffCourses: ({ signal, ...data }: GetStaffCoursesProps) =>
    api.post({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/staff-courses`,
      data,
      successMessage: false,
      errorMessage: false,
      signal,
    }),

  setRegistrationHomepageHTML: () => {
    console.log('Not implemented.');
    return Promise.resolve({
      status: 200,
      data: '',
    });
  },

  getRegistrationHomepageHTML: () => {
    console.log('Not implemented.');
    return Promise.resolve({
      status: 200,
      data: '',
    });
  },

  getTimeZones: async () =>
    api.get({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/time-zones`,
      successMessage: false,
    }),

  linkTracks: (trackToLink: number, ids: number[]) =>
    api.post({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/do-link-tracks`,
      data: {
        trackToLink,
        ids,
      },
      successMessage: false,
    }),

  getLinkedTracks: (ids: number[]) =>
    api.post({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/get-linked-tracks`,
      data: {
        ids,
      },
      successMessage: false,
    }),
  getIssueList: () =>
    api.get({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/issue-list`,
      successMessage: false,
    }),
  getChangeRequests: () =>
    api.get({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/change-request`,
      successMessage: false,
    }),
  makeChangeRequests: (props: MakeChangeRequestProps) =>
    api.post({
      ...props,
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/change-request`,
      successMessage: false,
    }),

  getViewLink: () =>
    api.get({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/view-link`,
      successMessage: false,
    }),

  autoAllocate: () =>
    api.post({
      endpoint: `${configService().REACT_APP_REGISTRATION_URL}/auto-allocate`,
      successToastType: 'success',
      successToastTitle: 'Auto Allocation ran successfully',
      successMessage: 'Done',
    }),
};

function createApiResponseSchema<ItemType extends z.ZodTypeAny>(itemSchema: ItemType) {
  return z.object({
    status: z.number(),
    message: z.string(),
    data: itemSchema,
  });
}
