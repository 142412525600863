// eslint-disable-next-line max-classes-per-file
import {
  TTEObject,
  isDefined,
  createIdNumberFromString,
  TTEFieldValue,
  fromTimestamp,
  isArray,
  isString,
  StudentInfo,
} from '@timeedit/registration-shared';
import moment from 'moment';
import { momentDateformat } from '../../settings/Date';
import { AllocationGroup, AllocationObjectWithCourse } from '.';
import { Mapping } from '../../services/mapping';
import { RangeReduce } from './RangeReduce';
import { TTEReservation } from './loadReservations';
import { numberArrayParser } from '@timeedit/preferences-and-dm-commons/lib/src/utils/registration-allocation/mapping/parsers';
import _, { isEmpty } from 'lodash';
import { MappingShorthand } from '../../services/mapping/mapping.service';
import intl from 'i18n/intl';
import { TFilterValue } from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';

// TODO: Assumes that date is of form DD/MM/YYYY
export function formatDateString(str: string) {
  const [day, month, year] = str.split(/[/-]/);

  return moment(`${month}-${day}-${year}`, momentDateformat);
}

export function toDate(serverTime: number, timezone: string) {
  const localDate = fromTimestamp(serverTime, timezone);
  return new Date(localDate.local);
}

export function toDateInURL(serverTime: number, timezone: string) {
  const date = toDate(serverTime, timezone);
  return `${date.getFullYear()}${twoDigits(date.getMonth() + 1)}${twoDigits(date.getDate())}`;
}

type CreateAllocationGroupsProps = {
  allocationObjects: AllocationObjectWithCourse[];
  mapping: Mapping;
  students: TTEObject[];
};

export function CreateAllocationGroups({ allocationObjects, mapping, students }: CreateAllocationGroupsProps) {
  return allocationObjects.reduce((allocationGroups: AllocationGroup[], allocationObject) => {
    const { courseId } = allocationObject;
    const activityType = mapping.parse('activityType', allocationObject);

    if (!activityType) return allocationGroups;

    const allocatedStudents = allocationObject.members.reduce((allocatedStudents: number[], member) => {
      const matchingStudent = students.find((student) => student.id === member.objectId);
      if (isDefined(matchingStudent)) {
        return [...allocatedStudents, matchingStudent.id];
      }
      return allocatedStudents;
    }, []);

    const key = createIdNumberFromString(`${courseId}-${activityType}`).toString();
    const existingIndex = allocationGroups.findIndex(({ key: existingKey }) => existingKey === key);

    if (existingIndex === -1) {
      const newAllocationGroup: AllocationGroup = {
        allocationObjects: [allocationObject],
        activityType,
        courseId,
        key,
        students: allocatedStudents,
      };
      return [...allocationGroups, newAllocationGroup];
    }

    const newStudentsForGroup = allocatedStudents.filter(
      (studentId) => !allocationGroups[existingIndex].students.includes(studentId),
    );
    allocationGroups[existingIndex].allocationObjects.push(allocationObject);
    allocationGroups[existingIndex].students.push(...newStudentsForGroup);
    allocationGroups[existingIndex].allocationObjects.sort((a, b) =>
      mapping.parse('trackNumber', a).localeCompare(mapping.parse('trackNumber', b)),
    );

    return allocationGroups;
  }, []);
}

function twoDigits(time: number) {
  return time < 10 ? `0${time}` : `${time}`;
}

export function presentTime(time: number, timezone: string) {
  const date = toDate(time, timezone);
  return `${twoDigits(date.getHours())}:${twoDigits(date.getMinutes())}`;
}

// ts-unused-exports:disable-next-line
export function presentDay(time: number, timezone: string) {
  const date = toDate(time, timezone);
  return moment.weekdays(true)[date.getDay()];
}

export function presentSet(set: Set<number>) {
  return RangeReduce.pairPresent([...set].sort((a, b) => a - b));
}

// ts-unused-exports:disable-next-line
export function presentRange(begin: number, end: number, timezone: string) {
  return `${presentTime(begin, timezone)} - ${presentTime(end, timezone)}`;
}

// ts-unused-exports:disable-next-line
export function presentWeek(time: number, timezone: string) {
  const date = toDate(time, timezone);
  const start = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date.getTime() - start.getTime()) / (24 * 60 * 60 * 1000));
  return Math.ceil(days / 7);
}

// ts-unused-exports:disable-next-line
export function dayOfWeek(time: number, timezone: string) {
  return toDate(time, timezone).getDay();
}

export function presentText(text: string, max?: number) {
  const maxLength = isDefined(max) ? max : 40;
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
}

export function printSetMax(set: Set<unknown>, max: number) {
  const dots = set.size > max ? `... +${set.size - max}` : '';
  const list = [...set].sort().slice(0, max);
  return list.join(', ') + dots;
}

export function printSetBoolean(set: Set<boolean>, name: string) {
  return set.has(true) ? name : '';
}

export function printSet(set: Set<unknown>) {
  return [...set].sort().join(', ');
}

export function printDaysOfWeek(set: Set<number>) {
  const weekdays = moment.localeData(intl.locale).weekdays();
  return [...set]
    .sort()
    .map((day) => weekdays[day] ?? day)
    .join(', ');
}

export function convertToString(name: unknown) {
  return isDefined(name) ? `${name}` : '';
}

export function isMapped(id: number) {
  return id > 0;
}

export function isValidId(id: number) {
  return id > 0;
}

export const mergeOnString = (first: string[], second: string[]) =>
  first.concat(second.filter((s) => !first.find((o) => o === s)));

export const mergeOnId = (first: TTEObject[], second: TTEObject[]) =>
  first.concat(second.filter((s) => !first.find((o) => o.id === s.id)));

export const mergeOnResId = (first: TTEReservation[], second: TTEReservation[]) =>
  first.concat(second.filter((s) => !first.find((o) => o.id === s.id)));

// ts-unused-exports:disable-next-line
export const mergeFieldOnId = (first: TTEFieldValue[], second: TTEFieldValue[]) =>
  first.concat(second.filter((s) => !first.find((o) => o.fieldId === s.fieldId)));

export const allRelatedUniqIds = (input: TTEObject | TTEObject[]): number[] => [
  ...new Set(
    asArray(input).reduce((total, current) => {
      if (!isDefined(current.relations)) {
        return total;
      }
      return [...total, ...current.relations.flatMap((r) => r.objectIds)];
    }, [] as number[]),
  ),
];

export const allMemberUniqIds = (input: TTEObject | TTEObject[]): number[] => [
  ...new Set(
    asArray(input).reduce((total, current) => {
      if (!isDefined(current.members)) {
        return total;
      }
      return [...total, ...current.members.flatMap((r) => r.objectId)];
    }, [] as number[]),
  ),
];

const asArray = (input: TTEObject | TTEObject[]) => (Array.isArray(input) ? input : [input]);
type MakeStudentInfo = {
  selectedStudents: number[];
  selectedTracks: string[];
};
export function makeStudentInfo({ selectedStudents, selectedTracks }: MakeStudentInfo): StudentInfo {
  return selectedStudents.reduce(
    (studentInfo, studentId) => ({
      ...studentInfo,
      [studentId]: [...new Set(selectedTracks.map((trackId) => Number(trackId)))],
    }),
    {},
  );
}

function fillFields(reservation: TTEReservation, fieldIds: number[]) {
  const result = new Set<string>();
  if (fieldIds.length === 0) {
    return result;
  }
  reservation.fields?.forEach((field) => {
    if (!fieldIds.includes(field.fieldId)) {
      return;
    }
    field.values.forEach((value) => {
      if (!isEmpty(value.trim())) {
        result.add(value);
      }
    });
  });
  return result;
}

export function presentReservationRanges(
  objectIds: number[],
  allReservations: TTEReservation[],
  timezone: string,
  commentField?: string,
) {
  const ranges = new Set<string>();
  const weeks = new Set<number>();
  const days = new Set<number>();
  const begins = new Set<number>();
  const ends = new Set<number>();
  const count = new Set<number>();
  const fields = new Set<string>();
  const fieldIds = numberArrayParser(commentField);
  for (const id of objectIds) {
    const reservations = allReservations.filter((r) => hasObject(r, id));
    if (reservations.length === 0) {
      continue;
    }
    for (const reservation of reservations) {
      count.add(reservation.id);
      fillFields(reservation, fieldIds).forEach(fields.add, fields);
      if (isDefined(reservation.begin) && isDefined(reservation.end)) {
        ranges.add(presentRange(reservation.begin, reservation.end, timezone));
        weeks.add(presentWeek(reservation.begin, timezone));
        days.add(dayOfWeek(reservation.begin, timezone));
        begins.add(reservation.begin);
        ends.add(reservation.end);
      }
    }
  }
  return { ranges, weeks, begins, ends, count, fields, days };
}

function hasObject(reservation: TTEReservation, id: number): boolean {
  return !isDefined(reservation.objects) ? false : reservation.objects?.some((o) => o.objectId === id);
}

export class PresentRooms {
  private readonly roomType: number;

  private readonly mapping: Mapping;

  private readonly allRooms: TTEObject[];

  constructor(shorthand: MappingShorthand, mapping: Mapping, allRooms: TTEObject[]) {
    this.roomType = mapping.getId(shorthand);
    this.mapping = mapping;
    this.allRooms = allRooms;
  }

  roomIds(objectIds: number[], allReservations: TTEReservation[]): number[] {
    const ids = new Set<number>();
    for (const id of objectIds) {
      const reservations = allReservations.filter((r) => hasObject(r, id));
      if (reservations.length === 0) {
        continue;
      }
      for (const reservation of reservations) {
        this.findRooms(reservation).forEach(ids.add, ids);
      }
    }
    return [...ids];
  }

  present(objectIds: number[], allReservations: TTEReservation[]) {
    const sizes = new Set<number>();
    const text = new Set<string>();
    const roomNames = new Set<string>();
    for (const id of objectIds) {
      const reservations = allReservations.filter((r) => hasObject(r, id));
      if (reservations.length === 0) {
        text.add('-');
        continue;
      }
      for (const reservation of reservations) {
        const roomIds = this.findRooms(reservation);
        if (roomIds.length === 0) {
          text.add('No rooms');
        }
        const rooms = roomIds.map((id) => this.allRooms.find((r) => r.id === id)).filter(isDefined);
        if (rooms.length === 0) {
          text.add('Missing room sizes');
        }
        rooms.forEach((room) => roomNames.add(this.mapping.parse('roomName', room)));
        sizes.add(this.roomSizeSum(rooms));
      }
    }
    return { roomNames, sizes, text };
  }

  findRooms(reservation: TTEReservation): number[] {
    if (!isDefined(reservation.objects)) {
      return [];
    }
    return reservation.objects?.filter((o) => o.typeId === this.roomType).map((o) => o.objectId);
  }

  roomSizeSum(objects: TTEObject[]): number {
    return objects.map((o) => this.mapping.parse('roomSize', o)).reduce((sum, size) => sum + size, 0);
  }
}

export class Calc {
  private readonly mapping: Mapping;

  readonly tracks: TTEObject[];

  constructor(mapping: Mapping, tracks: TTEObject[]) {
    this.mapping = mapping;
    this.tracks = isDefined(tracks) ? tracks : [];
  }

  spotsTaken(students: TTEObject[]): number {
    return this.tracks.reduce<number>(
      (spotsTaken, track) => this.calcSpotsTakenOnTrack(track, students) + spotsTaken,
      0,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  calcSpotsTakenOnTrack(track: TTEObject, students: TTEObject[]): number {
    if (!isDefined(track)) return 0;
    return track.members.reduce((allocatedStudents: number, member) => {
      const student = students.find((student) => student.id === member.objectId);
      if (isDefined(student)) {
        return allocatedStudents + 1;
      }
      return allocatedStudents;
    }, 0);
  }

  buffer(): number {
    return this.tracks.reduce<number>((spotsTaken, track) => parseBuffer(track, this.mapping).seats + spotsTaken, 0);
  }

  maxStudents(): number {
    return this.tracks.reduce<number>((spotsTaken, track) => this.mapping.parse('maxStudents', track) + spotsTaken, 0);
  }

  bufferText(track: TTEObject): string {
    const { size, isPercent, seats } = parseBuffer(track, this.mapping);
    if (seats === 0 && size === 0) {
      return '';
    }
    if (isPercent) {
      return `${seats} (${size}%)`;
    }
    return `${size}`;
  }

  presentBuffer(): string {
    return _.uniq(this.tracks.map((track) => this.bufferText(track)).filter(isNotEmpty))
      .sort()
      .join(', ');
  }
}

const isNotEmpty = (text: string) => !isEmpty(text);

export function parseBuffer(
  object: TTEObject,
  mapping: Mapping,
): { size: number; isPercent: boolean; seats: number; maxStudents: number } {
  const buffer = mapping.parse('buffer', object);
  const maxStudents = mapping.parse('maxStudents', object);
  const seats = buffer.isPercent ? Math.round((maxStudents * buffer.size) / 100) : buffer.size;
  return { ...buffer, seats, maxStudents };
}

export function parseBufferKind(
  track: TTEObject | undefined,
  mapping: Mapping,
): { buffer: number; bufferKind: string } {
  if (!isDefined(track)) {
    return { buffer: 0, bufferKind: '' };
  }
  const { size, isPercent } = parseBuffer(track, mapping);
  return { buffer: size, bufferKind: isPercent ? '%' : '' };
}

export function validBufferSize(value: number, kind: string, object: TTEObject, mapping: Mapping): string {
  if (value < 0) {
    return 'Value must be atleast 0. ';
  }
  if (!isEmpty(kind)) {
    if (value > 100) {
      return 'Value must be lower or equals to 100. ';
    }
    return '';
  }
  const buffer = parseBuffer(object, mapping);
  if (value > buffer.maxStudents) {
    return `Value must be lower or equals to ${buffer.maxStudents}. `;
  }
  return '';
}

export function findRooms(reservation: TTEReservation, roomTypeId: number): number[] {
  if (!isDefined(reservation.objects)) {
    return [];
  }
  return reservation.objects?.filter((o) => o.typeId === roomTypeId).map((o) => o.objectId);
}

export function checkFilterValue(filterValue: TFilterValue) {
  if (!isDefined(filterValue)) return false;
  if (isString(filterValue) && filterValue === '') return false;
  if (isArray(filterValue) && filterValue.length === 0) return false;
  if (isArray(filterValue) && filterValue.length === 1 && filterValue[0] === '') return false;
  return true;
}
