import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { TTEObject } from '@timeedit/registration-shared';
import {
  updateSearchedObjects,
  updateExpandedCourses,
  updateReservations,
  updateRoomObjects,
  updateTrackObjects,
  updateStudentObjects,
} from '../../pages/slices/allocation.slice';
import { allMemberUniqIds, allRelatedUniqIds, findRooms, isValidId } from '../../pages/BulkAllocationPage/utils';
import { loadObjectById, loadReservationsFor } from '../../pages/BulkAllocationPage';
import { useMapping } from '../../services/mapping';
import { configService } from '../../../services/config.service';
import '../../pages/BulkAllocationPage/BulkAllocationPage.scss';
import { fetchAndUpdateLinkedTracks } from '../../pages/slices/fetch.slice';
import { nonNegativeFiniteNumberParser } from '@timeedit/preferences-and-dm-commons/lib/src/utils/registration-allocation/mapping/parsers';

export function useLoadCourseRelated() {
  const dispatch = useDispatch();
  const courses = useSelector((state: TRootState) => state.allocation.courseObjects);
  const related = useSelector((state: TRootState) => state.allocation.searchedObjects);
  const expandedCourses = useSelector((state: TRootState) => state.allocation.expandedCourses);
  const mapping = useMapping();

  function needsToBeLoaded(courseId: string) {
    return !expandedCourses.includes(courseId);
  }

  return async function loadCourseRelatedData(courseIds: string[]) {
    const ids = courseIds.filter(needsToBeLoaded).map(nonNegativeFiniteNumberParser).filter(isValidId);
    if (ids.length === 0) {
      return;
    }

    try {
      const relatedToCourse = allRelatedUniqIds(courses.filter((course) => ids.includes(course.id)));
      const tracksForCourse = await loadObjectById(relatedToCourse, mapping.getId('track'), true);
      const studentsOnTracks = await loadObjectById(allMemberUniqIds(tracksForCourse), mapping.getId('student'), true);

      const relatedToDedicatedIds = dedicatedRelatedIds(tracksForCourse);
      const dedicatedRelatedObjects = await loadObjectById(relatedToDedicatedIds, 0, true);

      dispatch(updateExpandedCourses(courseIds));
      dispatch(updateSearchedObjects(dedicatedRelatedObjects));

      dispatch(updateStudentObjects({ studentObjects: studentsOnTracks, coursesWithStudentsLoaded: ids }));
      dispatch(updateTrackObjects(tracksForCourse));

      dispatch(fetchAndUpdateLinkedTracks(tracksForCourse.map((t) => t.id)));

      loadReservationsAndRooms(tracksForCourse);
    } catch (error) {
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: 'Load course related data failed',
        description: ` Details:  ${error}`.slice(0, 600),
      });
    }
  };

  function dedicatedRelatedIds(tracks: TTEObject[]) {
    return tracks.flatMap((track) => {
      const dedicatedTrack = mapping.parse('dedicatedTrack', track);
      if (dedicatedTrack.kind === 'relation') {
        return dedicatedTrack.data
          .filter((data) => related.every((relatedObj) => relatedObj.id !== data.id))
          .map((data) => data.id);
      }
      return [];
    });
  }

  async function loadReservationsAndRooms(allTracks: TTEObject[]) {
    if (!mapping.isMapped('track')) {
      return;
    }
    const reservations = await loadReservationsFor(
      allTracks.map((t) => t.id),
      mapping.getId('track'),
      true,
    );
    dispatch(updateReservations(reservations));

    if (!mapping.isMapped('room')) {
      return;
    }

    const roomIds = reservations.flatMap((r) => findRooms(r, mapping.getId('room')));
    const rooms = await loadObjectById(roomIds, 0, true);

    dispatch(updateRoomObjects(rooms));
  }
}
