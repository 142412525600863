import { TOrganizationPublic } from '@timeedit/types/lib/types';
import { convertToString } from './utils';
import { isEmpty } from 'lodash';

export type ViewLink = {
  viewerEntry?: string;
  viewerPath?: string;
  viewerPage?: string;
  commentField?: string;
};

export class ViewerLink {
  private readonly org: TOrganizationPublic;

  private readonly entry: string;

  private readonly pageId: string;

  constructor(org: TOrganizationPublic, link: ViewLink) {
    this.org = org;
    const path = convertToString(link.viewerPath);
    const page = convertToString(link.viewerPage);
    this.entry = isEmpty(path) ? 'public' : path; // default public
    this.pageId = isEmpty(page) ? '3' : page; // default 3
  }

  // eslint-disable-next-line class-methods-use-this
  idsWithType(ids: string[], typeId: number): string {
    return ids.map((id) => `${id}.${typeId}`).join(',');
  }

  signature() {
    return this.org.customerSignature.slice('cloud_'.length);
  }

  scheduleLink() {
    return `${this.base()}/r.html?sid=${this.pageId}&h=t`;
  }

  objectLink(id: number, typeId: number) {
    return `${this.base()}/objects/${id}.html?sid=${this.pageId}&fr=t&types=${typeId}`;
  }

  base() {
    return `https://cloud.timeedit.net/${this.signature()}/web/${this.entry}`;
  }
}
