import { TType, TTEObject, TField } from '@timeedit/registration-shared';
import { chunk, compact, keyBy, pick } from 'lodash';
import api from '../../services/api.service';
import { getObjectTypeLabel } from '../components/Modal/CreateNewImportTemplateModal/CreateNewImportTemplateModal.utils';

const LIMIT = 100;

type TObjectManagerField = Record<string, TField>;
type TObjectManagerObjectType = Record<string, TType>;
type TObjectManagerObject = Record<string, TTEObject>;

const doGettingItems = async (
  { extIds, options }: { extIds: string[]; options?: Record<string, any> },
  exec: ({
    extIds,
    includeReferenceFields,
  }: {
    extIds: string[];
    includeReferenceFields?: boolean;
  }) => Promise<{ results: (TField | TType | TTEObject)[] }>,
) => {
  const chunks = chunk(extIds, LIMIT);
  const objectResults = await Promise.all(
    chunks.map(async (extIds) => {
      const response = await exec({ extIds, ...(options || {}) });
      return response?.results || [];
    }),
  );
  return objectResults;
};

const doGettingItemsByIds = async (
  ids: number[],
  exec: ({ ids }: { ids: number[] }) => Promise<{ results: (TField | TType)[] }>,
) => {
  const chunks = chunk(ids, LIMIT);
  const objectResults = await Promise.all(
    chunks.map(async (ids) => {
      const response = await exec({ ids });
      return response?.results || [];
    }),
  );
  return objectResults;
};

class TEObjectManager {
  fields: TObjectManagerField;

  fieldsById: Record<number, string>;

  objectTypes: TObjectManagerObjectType;

  objectTypesById: Record<number, TType['extId']>;

  objects: TObjectManagerObject;

  loadingTypes: boolean;

  loadingFields: boolean;

  loadingObjects: boolean;

  constructor() {
    this.fields = {};
    this.fieldsById = {};
    this.objectTypes = {};
    this.objectTypesById = {};
    this.objects = {};
    this.loadingTypes = false;
    this.loadingFields = false;
    this.loadingObjects = false;
  }

  async getObjects(extIds: string[]): Promise<Partial<TObjectManagerObject>> {
    const missingExtIds = extIds.filter((key) => !this.objects[key]);
    if (missingExtIds.length) {
      this.loadingObjects = true;
      const results = (await doGettingItems(
        { extIds: missingExtIds, options: { includeReferenceFields: true } },
        api.findObjects,
      )) as TTEObject[][];
      results.forEach((items) => {
        items.forEach((obj) => {
          this.objects[obj.extId] = obj;
        });
      });
      this.loadingObjects = false;
    }
    return pick(this.objects, extIds);
  }

  init({ fields, objectTypes }: { fields?: TField[]; objectTypes: TType[] }) {
    if (fields) {
      this.fields = {
        ...this.fields,
        ...keyBy(fields, 'extId'),
      };
      this.fieldsById = Object.values(this.fields).reduce((results, field) => {
        return {
          ...results,
          [field.id]: field.extId,
        };
      }, {});
    }
    if (objectTypes) {
      this.objectTypes = {
        ...this.objectTypes,
        ...keyBy(objectTypes, 'extId'),
      };
      this.objectTypesById = Object.values(this.objectTypes).reduce((results, type) => {
        return {
          ...results,
          [type.id]: type.extId,
        };
      }, {});
    }
  }

  async getObjectTypes(extIds: string[]): Promise<Partial<TObjectManagerObjectType>> {
    const missingExtIds = extIds.filter((key) => !this.objectTypes[key]);
    if (missingExtIds.length) {
      this.loadingTypes = true;
      const results = (await doGettingItems({ extIds: missingExtIds }, api.findTypes)) as TType[][];
      let fieldIds: number[] = [];
      results.forEach((items) => {
        items.forEach((obj) => {
          this.objectTypes[obj.extId] = obj;
          this.objectTypesById[obj.id] = obj.extId;
          fieldIds = [...fieldIds, ...(obj.fields ?? [])];
        });
      });
      await this.getFieldsById(fieldIds);
      this.loadingTypes = false;
    }
    return pick(this.objectTypes, extIds);
  }

  async getFields(extIds: string[]): Promise<Partial<TObjectManagerField>> {
    const missingExtIds = extIds.filter((key) => !this.fields[key]);
    if (missingExtIds.length) {
      this.loadingFields = true;
      const results = (await doGettingItems({ extIds: missingExtIds }, api.findFields)) as TField[][];
      results.forEach((items) => {
        items.forEach((obj) => {
          this.fields[obj.extId] = obj;
          this.fieldsById[obj.id] = obj.extId;
        });
      });
      this.loadingFields = false;
    }
    return pick(this.fields, extIds);
  }

  async getFieldsById(ids: number[]): Promise<Partial<TObjectManagerField>> {
    const missingExtIds = ids.filter((key) => !this.fieldsById[key]);
    const fieldsResult = compact(ids.map((id) => this.fieldsById[id]));
    if (missingExtIds.length) {
      this.loadingFields = true;
      const results = (await doGettingItemsByIds(missingExtIds, api.findFields)) as TField[][];
      results.forEach((items) => {
        items.forEach((obj) => {
          this.fields[obj.extId] = obj;
          this.fieldsById[obj.id] = obj.extId;
          fieldsResult.push(obj.extId);
        });
      });
      this.loadingFields = false;
    }

    return pick(this.fields, fieldsResult);
  }

  getObjectTypeLabel(extId: string, defaultLabel?: string) {
    return this.objectTypes[extId]?.name || defaultLabel || extId;
  }

  getFieldLabel(extId: string, defaultLabel?: string) {
    return this.fields[extId]?.name || defaultLabel || extId;
  }

  getObjectLabel(extId: string) {
    const obj = this.objects[extId];
    if (!obj) return extId;
    const objectType = obj.types?.[0]; // object always be belonged in 1 type
    // @ts-ignore - Ignore until registration-shared updated
    const objectPrimaryField = this.objectTypes[this.objectTypesById[objectType]]?.primaryField;
    return obj.fields.find((field) => field.fieldId === objectPrimaryField)?.values?.[0] || extId;
  }

  getObjectTypeLabelByObject(extId: string) {
    const obj = this.objects[extId];
    if (!obj) return extId;
    const objectType = obj.types?.[0];
    if (!objectType) return extId;
    return this.objectTypes[this.objectTypesById[objectType]]?.name;
  }

  get loading() {
    return this.loadingFields || this.loadingTypes;
  }
}

export default new TEObjectManager();
