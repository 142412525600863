import React, { useEffect, useState } from 'react';
import { TEDrawerSection } from '@timeedit/ui-components';
import { AllowRegistrationStatus } from '@timeedit/registration-shared';
import intl from '../../../i18n/intl';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { useMapping } from '../../services/mapping';
import { GeneralInfoRow, GeneralInfoRowsWrapper } from './GeneralInfoRow';

type Dates = { start: string; end: string };
const language = intl.messages as Record<string, string>;

export function RegistrationPeriodSection() {
  const mapping = useMapping();
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const trackId = useSelector((state: TRootState) => state.drawer.clicked.track);

  const [allowRegStatus, setAllowRegStatus] = useState<{ status: AllowRegistrationStatus; changed: boolean }>();
  const [rangePicker, setRangePicker] = useState<Dates & { changed: boolean }>();

  useEffect(() => {
    const track = trackObjects.find((t) => t.id === Number(trackId));
    const status = mapping.parse('allowRegistration', track);
    const start = mapping.parse('startDate', track);
    const end = mapping.parse('endDate', track);
    setRangePicker({ start, end, changed: false });
    setAllowRegStatus({ status, changed: false });
  }, [trackId, trackObjects, mapping]);

  return (
    <TEDrawerSection label={language.registrationPeriod}>
      <GeneralInfoRowsWrapper>
        <GeneralInfoRow title="Status" value={translate(allowRegStatus?.status)} />
        <GeneralInfoRow title="Dates" value={`${rangePicker?.start} - ${rangePicker?.end}`} />
      </GeneralInfoRowsWrapper>
    </TEDrawerSection>
  );

  function translate(status?: AllowRegistrationStatus) {
    switch (status) {
      case 'FORCE_OPEN':
        return language.openForce;
      case 'FORCE_CLOSE':
        return language.closedForce;
      default:
        return language.openDate;
    }
  }
}
