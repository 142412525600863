import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import intl from '../../../i18n/intl';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { useMapping } from '../../services/mapping';
import { TTEObject, isDefined } from '@timeedit/registration-shared';
import { allocationApi } from '../../services/registration-allocation.service';
import { DiscardFunctions, SaveDiscardSections, SaveFunctions } from './BulkAllocationDrawer';
import { setChangesToDiscard } from '../../pages/slices/drawer.slice';
import { TreeSelect } from 'antd';
import { GeneralInfoRow } from './GeneralInfoRow';
import { fetchAndUpdateLinkedTracks } from '../../pages/slices/fetch.slice';
import BranchesOutlined from '@ant-design/icons/lib/icons/BranchesOutlined';

const language = intl.messages as Record<string, string>;

type LinkedInfoProps = {
  removeSaveFunction: (id: SaveDiscardSections) => void;
  addSaveFunction: (saveFunction: SaveFunctions[number]) => void;
  addDiscardFunction: (discardFunction: DiscardFunctions[number]) => void;
};

type LinkedTrackSaveState = {
  linkedValues: number[];
};

interface TreeOptions {
  title: ReactNode;
  value: string;
  label: ReactNode;
  children?: TreeOptionChildren[];
  selectable?: boolean;
}
interface TreeOptionChildren {
  title: ReactNode;
  value: string;
  label: ReactNode;
  selectable?: boolean;
}

export function LinkedInfo({ removeSaveFunction, addDiscardFunction, addSaveFunction }: LinkedInfoProps) {
  const mapping = useMapping();
  const dispatch = useDispatch();
  const clickedTrackId = useSelector((state: TRootState) => state.drawer.clicked.track);
  const linkedTracksMap = useSelector((state: TRootState) => state.allocation.linkedTracks);
  const trackLists = useSelector((state: TRootState) => state.allocation.trackLists);
  const clickedCourse = useSelector((state: TRootState) => state.drawer.clicked.courseId);
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const [linkedValues, setLinkedValues] = useState<number[]>(linkedTracksMap[clickedTrackId] ?? []);
  const initialLinkedState = useRef<Partial<LinkedTrackSaveState>>({});

  useEffect(() => {
    // For the function to have updated linkedValues, we have to recreate it everytime it changes.
    const hasChanges =
      linkedValues.some((value) => !initialLinkedState.current.linkedValues?.includes(value)) ||
      linkedValues.length !== initialLinkedState.current.linkedValues?.length;

    if (hasChanges && isDefined(initialLinkedState.current.linkedValues)) {
      addSaveFunction({
        id: 'LinkedSection',
        fn: saveLinkedSection,
      });
      dispatch(setChangesToDiscard({ section: 'LinkedSection', discard: true }));
    } else {
      dispatch(setChangesToDiscard({ section: 'LinkedSection', discard: false }));
    }

    addDiscardFunction({ id: 'LinkedSection', fn: discard });

    return () => {
      removeSaveFunction('LinkedSection');
    };
  }, [clickedTrackId, trackObjects, mapping, linkedValues]);

  const availableTracks = useMemo<TTEObject[]>(() => {
    const clickedTrack = trackObjects.find((obj) => clickedTrackId === obj.id.toString());
    if (!clickedTrack) {
      return [];
    }
    const clickedTrackActivityType = mapping.parse('activityType', clickedTrack);
    const sameCourseTrackLists = trackLists.filter((trackList) => trackList.courseId === clickedCourse);

    return sameCourseTrackLists.reduce<TTEObject[]>((availableTracksToLink, trackList) => {
      if (trackList.activityType !== clickedTrackActivityType) {
        return [...availableTracksToLink, ...trackList.allocationObjects];
      }
      return availableTracksToLink;
    }, []);
  }, [clickedTrackId, clickedCourse, trackLists, trackObjects, mapping]);

  useEffect(() => {
    const alreadyLinked = linkedTracksMap[clickedTrackId] ?? [];
    setLinkedValues(alreadyLinked);
    const state = initialLinkedState.current;
    state.linkedValues = alreadyLinked;
    return () => {
      state.linkedValues = undefined;
    };
  }, [clickedTrackId, linkedTracksMap]);

  const allowedOptionsToLink = useMemo(() => {
    return availableTracks.reduce<TreeOptions[]>(
      (allowedOptions, track) => {
        // Don't allow to link to a track that already have a link
        const linked = linkedTracksMap[track.id.toString()] ?? [];
        const hasLinks = linked.length > 0;
        const alreadyLinked = linkedTracksMap[clickedTrackId] ?? [];
        if (hasLinks && !alreadyLinked.includes(track.id)) {
          return allowedOptions;
        }

        const activityType = mapping.parse('activityType', track);

        const trackNumber = mapping.parse('trackNumber', track);
        const trackIdString = track.id.toString();

        const parent = allowedOptions.find((option) => option.title === activityType);
        const trackNumberTitle = (
          <span>
            <BranchesOutlined className="track__icon" /> {trackNumber}
          </span>
        );
        const selectorLabel = (
          <span>
            {activityType} {trackNumberTitle}
          </span>
        );

        if (parent?.children) {
          parent.children.push({
            title: trackNumberTitle,
            value: trackIdString,
            label: selectorLabel,
          });
          return allowedOptions;
        }

        return [
          ...allowedOptions,
          {
            selectable: false,
            title: activityType,
            value: `parent-${trackIdString}`,
            label: activityType,
            children: [
              {
                title: trackNumberTitle,
                value: trackIdString,
                label: selectorLabel,
              },
            ],
          },
        ];
      },
      [{ title: '-', value: '-', label: '-' }],
    );
  }, [availableTracks, mapping, linkedTracksMap]);

  useEffect(() => {
    if (!isDefined(initialLinkedState.current.linkedValues)) {
      initialLinkedState.current.linkedValues = linkedValues;
    }
  }, [linkedValues]);

  return (
    <GeneralInfoRow
      title={language.linked}
      value={
        <TreeSelect
          labelInValue
          treeDefaultExpandAll
          className={'te-w-full'}
          onChange={(values) => {
            const link = parseInt(values.value, 10);

            setLinkedValues(Number.isNaN(link) ? [] : [link]);
          }}
          treeData={allowedOptionsToLink}
          value={{
            value: linkedValues[0]?.toString() ?? '-',
            label: findLabelFromId(linkedValues[0]),
          }}
        />
      }
    />
  );

  function discard() {
    const areIdentical =
      linkedValues.every((val) => initialLinkedState.current.linkedValues?.includes(val)) &&
      linkedValues.length === initialLinkedState.current.linkedValues?.length;
    if (!areIdentical) {
      setLinkedValues((prev) => initialLinkedState.current.linkedValues ?? prev);
    }
  }

  function findLabelFromId(id: number) {
    let label: ReactNode = '-';
    for (const option of allowedOptionsToLink) {
      if (option.children) {
        const found = option.children.find((child) => parseInt(child.value, 10) === id);
        if (isDefined(found)) {
          label = found.label;
          break;
        }
      }
    }
    return label;
  }

  async function saveLinkedSection() {
    const failedTracksResponse = await allocationApi.linkTracks(parseInt(clickedTrackId, 10), linkedValues);

    if (failedTracksResponse.status === 200) {
      const failedTracks: number[] = failedTracksResponse.data;

      const tracksToFindLinksFor = [
        parseInt(clickedTrackId, 10),
        ...allowedOptionsToLink
          .flatMap((option) => option?.children)
          .reduce<number[]>((acc, option) => {
            return option ? [...acc, parseInt(option.value, 10)] : acc;
          }, []),
        ...(linkedTracksMap[clickedTrackId] ?? []),
      ];

      dispatch(fetchAndUpdateLinkedTracks(tracksToFindLinksFor));

      if (failedTracks.length > 0) {
        throw new Error(`${language.linkingTracksFailed}: ${failedTracks.join(', ')}`);
      }
    }
    return Promise.resolve();
  }
}
