import React, { memo, useEffect, useState } from 'react';
import { Filters } from '@timeedit/ui-components';
import {
  EKindOfFilter,
  TFilterOptions,
  TFilterValueSet,
} from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import { useMapping } from '../../services/mapping';
import { checkBoxOptions, getCourseCategoryValues, presentCourse } from './common';
import { SetSearchParamState } from '../../hooks/UseStateParamState';
import { useSelector } from 'react-redux';
import { TRootState } from 'index';
import './bulkAllocationFilter.scss';
import intl from '../../../i18n/intl';
import { useObjectSearch } from 'registration-allocation/hooks/useObjectSearch';
import { TTEObject } from '@timeedit/registration-shared';
import { allocationApi } from 'registration-allocation/services/registration-allocation.service';

const language = intl.messages as Record<string, string>;

type BulkAllocationFilterProps = {
  searchParamFilters?: TFilterValueSet;
  setFilters: (filters: SetSearchParamState<TFilterValueSet>) => void;
};

function BulkAllocationFilterFn({ setFilters, searchParamFilters }: BulkAllocationFilterProps) {
  const mapping = useMapping();
  const courses = useSelector((state: TRootState) => state.allocation.courseObjects);
  const [courseOptions, fetchCourses, coursesLoading] = useObjectSearch({
    initialBody: {
      fields: {
        generalSearchFieldIds: mapping.searchableFields(mapping.getId('course')),
        searchText: '',
      },
    },
    request: allocationApi.staffCourses,
    searchParamKey: mapping.getKey('course'),
    searchParamFilters,
    savedSearchCallback: (ids) => ({ idsToFilter: ids }),
    labelCallback: (object) => presentCourse(object, mapping),
  });
  const [programOptions, fetchPrograms, programsLoading] = useObjectSearch({
    initialBody: {
      typeId: mapping.getId('program'),
      relations: {
        typeId: mapping.getId('course'),
      },
      fields: mapping.searchableFields(mapping.getId('program')),
      text: '',
    },
    searchParamKey: mapping.getKey('program'),
    searchParamFilters,
    savedSearchCallback: (ids) => ({ ids }),
    request: allocationApi.loadObjects,
    labelCallback: (obj) => mapping.parse('programName', obj),
  });
  const fields = useSelector((state: TRootState) => state.allocation.fields);

  const [filterOptions, setFilterOptions] = useState<TFilterOptions>({});
  const [otherFilterOptions, setOtherFilterOptions] = useState<TFilterOptions>({});

  useEffect(() => {
    getCourseCategoryValues(
      fields,
      mapping,
      {
        fieldsToExclude: [mapping.getId('courseRegistrationPeriodSet')],
        fieldsForNormalFilter: [mapping.getId('semester')],
      },
      (params) => {
        setOtherFilterOptions(params.otherFilterOptionsToSet);
        setFilterOptions({
          ...params.normalFilterOptionsToSet,
          [mapping.getKey('course')]: {
            key: mapping.getKey('course'),
            label: mapping.typename('course'),
            kindOfFilter: EKindOfFilter.DROPDOWN,
            allowMultiple: true,
            showSearch: true,
            onSearch: (text) => {
              fetchCourses({ fields: { searchText: text } });
            },
            loading: coursesLoading,
            options: courseOptions,
            allowClear: true,
            autoClearSearchValue: false,
            className: 'bulkdropdown',
            debounce: true,
          },
          [mapping.getKey('program')]: {
            key: mapping.getKey('program'),
            label: mapping.typename('program'),
            kindOfFilter: EKindOfFilter.DROPDOWN,
            allowMultiple: true,
            showSearch: true,
            onSearch: (text) => {
              fetchPrograms({ text });
            },
            loading: programsLoading,
            options: programOptions,
            allowClear: true,
            autoClearSearchValue: false,
            className: 'bulkdropdown',
            debounce: true,
          },
          [mapping.getId('courseRegistrationPeriodSet')]: {
            key: mapping.getId('courseRegistrationPeriodSet').toString(),
            label: mapping.fieldname('courseRegistrationPeriodSet'),
            kindOfFilter: EKindOfFilter.DROPDOWN,
            allowMultiple: false,
            showSearch: false,
            type: 'boolean',
            options: [{ label: '-', value: '' }, ...checkBoxOptions],
            className: 'bulkdropdown',
          },
          'COURSE-IDS': {
            key: 'COURSE-IDS',
            label: language.issueList,
            kindOfFilter: EKindOfFilter.DROPDOWN,
            options: courses.map((object: TTEObject) => ({
              value: object.id,
              label: presentCourse(object, mapping),
            })),
            allowMultiple: true,
            allowClear: true,
            autoClearSearchValue: false,
            debounce: true,
            className: 'bulkdropdown',
          },
        });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseOptions, coursesLoading, programOptions, programsLoading, mapping, searchParamFilters, fields]);

  return (
    <Filters
      filterOptions={filterOptions}
      filtersValue={searchParamFilters ?? {}}
      otherFilterOptions={otherFilterOptions}
      onChange={setFilters}
    />
  );
}

export const BulkAllocationFilter = memo(BulkAllocationFilterFn);
