/**
 * Rewritten into typescript from TE Viewer
 * Formats a list of numbers into shortened format
 * example [1,2,3,4,7,8,9] formats into 1-4, 7-9
 */
export class RangeReduce {
  static pairPresent(collection: number[]): string {
    const pairs = RangeReduce.pair(collection);
    const result = pairs.reduce<string[]>((result, pair, index) => {
      if (pair.length === 0) {
        return result;
      }

      if (pair.length === 2) {
        if (index === pairs.length - 1) {
          return [...result, pair[0].toString(), '-', pair[1].toString()];
        }
        return [...result, pair[0].toString(), '-', pair[1].toString(), ', '];
      }
      return [...result, pair[0].toString(), ', '];
    }, []);
    const text = result.join('');
    if (text.charCodeAt(text.length - 1) === 32) {
      return text.substring(0, text.length - 2);
    }
    return text;
  }

  static pair(collection: number[]): number[][] {
    return RangeReduce.pairFromArray(collection);
  }

  static pairFromArray(array: number[]): number[][] {
    const result: number[][] = [];
    let list: number[] = [];
    for (let index = 0; index < array.length; index++) {
      const current = array[index];
      const previous = index > 0 ? array[index - 1] : -10;
      const next = index + 1 < array.length ? array[index + 1] : -10;
      if (previous + 1 === current && current + 1 === next) {
        continue;
      }
      if (previous + 1 === current) {
        list.push(current);
        list = [];
      } else {
        list = [current];
        result.push(list);
      }
    }
    return result;
  }
}
