import React from 'react';
import { Text } from '@timeedit/registration-components';
import intl from '../../../i18n/intl';
import './BatchAllocateContent.scss';
import { useMapping } from '../../services/mapping';

const language = intl.messages as Record<string, string>;

export function AllocationMethodStep() {
  const mapping = useMapping();
  return (
    <div>
      <Text strong>{language.allocationSettings}:</Text>
      <ul className="step_text-list">
        <li>
          <Text strong>No conflicts,</Text> students will not be allocated to a group if it will create conflicts
        </li>
        <li>
          <Text strong>Fill {mapping.typename('track').toLowerCase()},</Text> {mapping.typename('track').toLowerCase()}{' '}
          will be filled one-by-one as far as all other rules are met
        </li>
      </ul>
    </div>
  );
}
