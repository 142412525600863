import { TTEObject, isArray, isDefined } from '@timeedit/registration-shared';
import { AllocationGroup } from '../../pages/BulkAllocationPage';
import React from 'react';

type FindEnrolledStudents = { courseIds: number[]; students: TTEObject[] };
export function findEnrolledStudents({ courseIds, students }: FindEnrolledStudents): number[] {
  return students
    .filter((student) =>
      student.relations.some(({ objectIds }) => objectIds.some((objId) => courseIds.includes(objId))),
    )
    .map(({ id }) => id);
}

export type TracksDataSource = {
  id: string;
  key: string;
  courseId: string;
  allocationObjects: TTEObject[];
  [key: string]: string | TTEObject[];
};
export type TrackListDataSource = {
  id: string;
  key: string;
  courseId: string;
  children?: TracksDataSource[];
  allocationObjects: TTEObject[];
  loading: boolean;
  [key: string]: string | TTEObject[] | TracksDataSource[] | undefined | boolean;
};

export type CourseDataSource = {
  id: string;
  key: string;
  children: TrackListDataSource[];
  trackLists: AllocationGroup[];
  [key: string]: string | TrackListDataSource[] | AllocationGroup[];
};
export type BAllocateDataSource = CourseDataSource | TrackListDataSource | TracksDataSource;

export function isTracksDataSource(dataSource: BAllocateDataSource): dataSource is TracksDataSource {
  return !('children' in dataSource);
}

export function isTrackListDataSource(dataSource: BAllocateDataSource): dataSource is TrackListDataSource {
  if ('children' in dataSource && 'loading' in dataSource) {
    if (isDefined(dataSource.children)) {
      const children = dataSource.children as TracksDataSource[];
      return children.every((child) => !('children' in child));
    }

    // If loading is false and children are undefined, then something is wrong and it shouldn't be a TrackListDataSource.
    const loading = dataSource.loading as boolean;
    return loading;
  }
  return false;
}

export function isCourseDataSource(dataSource: BAllocateDataSource): dataSource is CourseDataSource {
  // First, check if 'children' is a property and is an array.
  if ('children' in dataSource && isArray(dataSource.children)) {
    // Assert the type of children as TrackListDataSource[] to satisfy TypeScript's type checking.
    const children = dataSource.children as TrackListDataSource[];
    // Now perform the .every check on the asserted type.
    return children.every((child) => 'children' in child);
  }
  return false;
}

type FindMatchingSingleChildTrackList = {
  trackLists: AllocationGroup[];
  record: TracksDataSource;
};
export function findMatchingSingleChildTrackList({ record, trackLists }: FindMatchingSingleChildTrackList) {
  return trackLists.find(
    (tL) => tL.allocationObjects.length === 1 && tL.allocationObjects[0].id.toString() === record.key,
  );
}

/**
 * Calculates the rows that should be expanded by default which are the tracklists with only one child
 * @param dataSource The data source
 */

export function calculateDefaultExpandedRows(dataSource: CourseDataSource[]) {
  return dataSource.reduce<React.Key[]>((acc, value) => {
    const trackListsDSSingleTrack = value.children
      .filter((child) => child.children?.length === 1)
      .map((trackListDS) => trackListDS.key.toString());
    return [...acc, ...trackListsDSSingleTrack];
  }, []);
}

type CountSelectedTracksProps = {
  trackObjects: TTEObject[];
  selectedTracks: string[];
};
export function countSelectedTracks({ trackObjects, selectedTracks }: CountSelectedTracksProps) {
  return trackObjects.filter((trackObj) => selectedTracks.includes(trackObj.id.toString())).length;
}

type FilterOutMissingIdsProps = {
  currentIds: string[];
  dataSourceToMatch: CourseDataSource[];
};
/**
 *
 * @param currentIds - The current ids
 * @params dataSourceToMatch - The data source that is source of truth
 * @returns An array of ids that are in the currentIds and also in the dataSourceToMatch
 */
export function filterOutMissingIds({ currentIds, dataSourceToMatch }: FilterOutMissingIdsProps) {
  const allIds = dataSourceToMatch.reduce((allIds: string[], data) => {
    const moreIds = [data.key];
    data.children.forEach((child) => {
      moreIds.push(child.key);
      child.children?.forEach((grandChild) => {
        moreIds.push(grandChild.key);
      });
    });
    allIds.push(...moreIds);
    return allIds;
  }, []);

  return currentIds.filter((id) => allIds.includes(id));
}
