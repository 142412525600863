import { useDispatch } from 'react-redux';
import { DedicationKindSectionProps } from './DedicatedTrackSection';
import { useMapping } from '../../../services/mapping';
import React, { useEffect, useMemo, useRef } from 'react';
import { DiscardFunctions } from '../BulkAllocationDrawer';
import ObjectSearcher from '../ObjectSeacher';
import { addToDedicatedRelation } from './DedicationUtils';

export function RelationSection({
  setDedicatedTrackString,
  initialDedication,
  dedicatedPercentage,
}: DedicationKindSectionProps) {
  const dispatch = useDispatch();
  const mapping = useMapping();
  const resetCallback = useRef<DiscardFunctions[number]['fn'][]>([]);

  const studentRelatedIds = useMemo(() => {
    if (initialDedication.kind !== 'relation') {
      return [];
    }
    return initialDedication.data.map((related) => related.id);
  }, [initialDedication]);

  useEffect(() => {
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDedication]);

  return (
    <ObjectSearcher
      className="te-mt-2 relation-section-selector"
      mapping={mapping}
      ids={studentRelatedIds}
      type="program"
      fields={['programName']}
      placeholder={`${mapping.typename('program')}`}
      onChange={(ids) => onChange(ids, dedicatedPercentage)}
      resetCallback={resetCallback}
      dispatch={dispatch}
      hideCategoryDropdowns
      size="small"
      alwaysEmpty
      changeCallbackOnClick
    />
  );
  function setInitialValues() {
    setDedicatedTrackString(JSON.stringify(initialDedication));
    resetCallback.current.forEach((callback) => callback());
  }
  function onChange(ids: number[], dedicatedPercentage: number) {
    setDedicatedTrackString((prevDedicatedTrackString) => {
      const previousDedicatedTrack = mapping.parse('dedicatedTrack', prevDedicatedTrackString);
      return addToDedicatedRelation({ newRelationIds: ids, previousDedicatedTrack, dedicatedPercentage });
    });
  }
}
