import { useSelector } from 'react-redux';
import { TRootState } from '../../../..';
import React, { useEffect, useMemo } from 'react';
import { Cascader } from 'antd';
import intl from '../../../../i18n/intl';
import { DedicationKindSectionProps } from './DedicatedTrackSection';
import { useMapping } from '../../../services/mapping';
import { addToDedicatedCategory } from './DedicationUtils';
import { createCategoryOptions } from '../../AllocationModal/utils';

const language = intl.messages as Record<string, string>;

export function CategorySection({
  setDedicatedTrackString,
  initialDedication,
  dedicatedPercentage,
}: DedicationKindSectionProps) {
  const mapping = useMapping();
  const studentFields = useSelector((state: TRootState) => state.allocation.studentFields);
  const options = useMemo(
    () => createCategoryOptions(studentFields),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [studentFields],
  );

  useEffect(() => {
    setInitialValues(initialDedication);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDedication]);

  return (
    <Cascader
      className="drawer__item--margin te-mt-2 category-section-selector"
      options={options}
      showCheckedStrategy={'SHOW_CHILD'}
      onChange={(newIdValue) => {
        const [id, value] = newIdValue;
        onChange(id, value, dedicatedPercentage);
      }}
      value={[]}
      placeholder={<div className="category-section-selector__placeholder-text">{language.add}</div>}
      displayRender={() => <div className="category-section-selector__placeholder-text">{language.add}</div>}
      allowClear={false}
      expandTrigger="hover"
      size="small"
    />
  );

  function onChange(id: number | string, value: number | string, dedicatedPercentage: number) {
    setDedicatedTrackString((prevDedicatedTrackString) => {
      const previousDedicatedTrack = mapping.parse('dedicatedTrack', prevDedicatedTrackString);
      return addToDedicatedCategory({ newCategory: { id, value }, previousDedicatedTrack, dedicatedPercentage });
    });
  }

  function setInitialValues(initialDedication: DedicationKindSectionProps['initialDedication']) {
    setDedicatedTrackString(() => {
      if (initialDedication.kind !== 'category') {
        return JSON.stringify({ kind: 'category', data: [] });
      }
      return JSON.stringify(initialDedication);
    });
  }
}
