import React from 'react';
import { Filters } from '@timeedit/ui-components';
import {
  EKindOfFilter,
  TFilterOptions,
  TFilterValueSet,
} from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import intl from '../../../i18n/intl';
import { useMapping } from '../../services/mapping';
import { TStudentsAndTracksOfCourse } from '@timeedit/preferences-and-dm-commons/lib/src';
import { TStudentsAdjustmentFilters } from '../../pages/StudentAdjustmentsPage';
import { convertToString } from 'registration-allocation/pages/BulkAllocationPage/utils';
import { presentCourse } from './common';
import { useObjectSearch } from 'registration-allocation/hooks/useObjectSearch';
import { allocationApi } from 'registration-allocation/services/registration-allocation.service';

const language = intl.messages as Record<string, string>;

type StudentsAdjustmentFilterProps = {
  trackListsLookup: TStudentsAndTracksOfCourse['trackListsLookup'] | undefined;
  trackLookup: TStudentsAndTracksOfCourse['trackLookup'] | undefined;
  filterValuesInput?: Partial<TStudentsAdjustmentFilters>;
  setFilters: (filters: TFilterValueSet) => void;
  courseOptionsLoading: boolean;
  courseLoading?: boolean;
  courseSelected: boolean;
};
export function StudentsAdjustmentFilter({
  trackListsLookup,
  trackLookup,
  filterValuesInput,
  setFilters,
  courseOptionsLoading,
  courseLoading,
  courseSelected,
}: StudentsAdjustmentFilterProps) {
  const mapping = useMapping();

  const [courseOptions, fetchCourses, coursesLoading] = useObjectSearch({
    initialBody: {
      fields: {
        generalSearchFieldIds: mapping.searchableFields(mapping.getId('course')),
        searchText: '',
      },
    },
    searchParamKey: 'courseId',
    searchParamFilters: filterValuesInput,
    savedSearchCallback: (ids) => ({ idsToFilter: ids }),
    request: allocationApi.staffCourses,
    labelCallback: (object) => presentCourse(object, mapping),
  });

  const filterOptions: TFilterOptions = {
    courseId: {
      key: 'courseId',
      label: mapping.typename('course'),
      kindOfFilter: EKindOfFilter.DROPDOWN,
      allowMultiple: false,
      showSearch: true,
      onSearch: (text) => {
        fetchCourses({ fields: { searchText: text } });
      },
      loading: coursesLoading,
      options: courseOptions,
      allowClear: true,
      autoClearSearchValue: false,
      className: 'bulkdropdown',
      debounce: true,
    },
    trackLists: {
      key: 'trackLists',
      label: mapping.fieldname('activityType'),
      kindOfFilter: EKindOfFilter.DROPDOWN,
      allowMultiple: true,
      loading: courseOptionsLoading || courseLoading,
      disabled: !courseSelected,
      placeholder: !courseSelected ? language.selectCourseFirst : undefined,
      options: trackListsLookup
        ? Object.values(trackListsLookup)
            .map((trackList) => ({
              value: convertToString(trackList.id),
              label: trackList.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
      allowClear: true,
      autoClearSearchValue: false,
      className: 'bulkdropdown',
    },
    trackIds: {
      key: 'trackIds',
      label: mapping.typename('track'),
      kindOfFilter: EKindOfFilter.DROPDOWN,
      allowMultiple: true,
      loading: courseOptionsLoading || courseLoading,
      disabled: !courseSelected,
      placeholder: !courseSelected ? language.selectCourseFirst : undefined,
      options: trackLookup
        ? Object.values(trackLookup)
            .map((track) => ({
              value: convertToString(track.id),
              label: track.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
      allowClear: true,
      autoClearSearchValue: false,
      className: 'bulkdropdown',
    },
  };

  const otherFilterOptions: TFilterOptions = {
    allocationAmount: {
      key: 'allocationAmount',
      label: language.allocationAmount,
      kindOfFilter: EKindOfFilter.DROPDOWN,
      type: 'number',
      options: [],
    },
  };

  return (
    <Filters
      filterOptions={filterOptions}
      filtersValue={filterValuesInput ?? {}}
      onChange={(values) => {
        setFilters(values);
      }}
      otherFilterOptions={otherFilterOptions}
    />
  );
}
