import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '@timeedit/ui-components';
import { Typography } from 'antd';
import React from 'react';
import { MultiProgress } from '@timeedit/registration-components';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { useMapping } from '../../services/mapping';
import './ManageStudentHeader.scss';
import { fullViewerLink } from '../Drawer/GeneralInfoSection';
import { organizationSelector } from '../../../slices/organization.slice';
import { TTEObject } from '@timeedit/registration-shared';

type ManageStudentHeaderProps = {
  handleGoBack: () => void;
  student: TTEObject;
  progress: {
    allocated: number;
    total: number;
  };
};
export function ManageStudentHeader({ handleGoBack, student, progress }: ManageStudentHeaderProps) {
  const org = useSelector(organizationSelector);
  const columnKeys = useSelector((state: TRootState) => state.studentAdjustment.columnKeys);
  const timezone = useSelector((state: TRootState) => state.allocation.timeZone);
  const viewLink = useSelector((state: TRootState) => state.allocation.viewerLink);
  const mapping = useMapping();
  const studentType = mapping.getId('student');

  return (
    <div className="manage_student_header__wrapper">
      <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleGoBack} />
      <div>
        <Typography.Title data-student-id={student.id} level={3}>
          {mapping.parse('studentName', student)}
        </Typography.Title>
        <div className="manage_student_header__status_container">
          {createFieldTexts()}
          <span className="manage_student_header__multi_progress">
            <MultiProgress end={0} max={progress.total} value={progress.allocated} compact formatHelp={() => ''} />
          </span>
          <span className="manage_student_header__viewer_link">
            {fullViewerLink({
              allReservations: [],
              org,
              timezone,
              objectId: student.id.toString(),
              objectType: studentType,
              viewLink,
            })}
          </span>
        </div>
      </div>
    </div>
  );
  function createFieldTexts() {
    return columnKeys.map((fieldId) => {
      const fieldName = mapping.fieldname(fieldId);
      const fieldValue = mapping.string(fieldId, student);

      return (
        <div key={`createFieldTexts-${fieldName}-${fieldId}`} className="manage_student_header__field_text">
          <Typography.Text>{fieldName}:</Typography.Text>
          <Typography.Text>{fieldValue === '' ? '-' : fieldValue}</Typography.Text>
        </div>
      );
    });
  }
}
