import { isDefined } from '@timeedit/registration-shared';
import { ColumnsType } from 'antd/es/table';
import {
  isCourseDataSource,
  isTrackListDataSource,
  isTracksDataSource,
  BAllocateDataSource,
  findMatchingSingleChildTrackList,
} from './utils';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';

type DataSourceType = 'track' | 'singleTrack' | 'trackList' | 'course';
export function useCreateOnCell(
  colSpan?: number,
  hide?: boolean,
): Exclude<ColumnsType<BAllocateDataSource>[number]['onCell'], undefined> {
  const indexWithDataSourceType: Record<number, DataSourceType> = {};
  const trackLists = useSelector((state: TRootState) => state.allocation.trackLists);

  return (record: BAllocateDataSource, index: number | undefined) => {
    if (!isDefined(index)) return {};
    let style = {};

    if (isTracksDataSource(record)) {
      const matchingTrackList = findMatchingSingleChildTrackList({ trackLists, record });
      if (isDefined(matchingTrackList)) {
        style = {
          ...style,
          borderTop:
            indexWithDataSourceType[index - 1] === 'track' || indexWithDataSourceType[index - 1] === 'trackList'
              ? '1px solid #f0f0f0'
              : 'none',
        };

        indexWithDataSourceType[index] = 'singleTrack';
      } else {
        style = {
          ...style,
          borderBottom: 'none',
        };
        indexWithDataSourceType[index] = 'track';
      }
    }
    if (isTrackListDataSource(record)) {
      // The tracklist with only one child has a display:none but it is still in the html so it will be counted in here
      if (record.children?.length === 1) {
        indexWithDataSourceType[index] = indexWithDataSourceType[index - 1];
      } else {
        style = {
          ...style,
          borderTop:
            indexWithDataSourceType[index - 1] === 'track' || indexWithDataSourceType[index - 1] === 'trackList'
              ? '1px solid #f0f0f0'
              : 'none',
          borderBottom: 'none',
        };
        indexWithDataSourceType[index] = 'trackList';
      }
    }
    if (isCourseDataSource(record)) {
      style = {
        ...style,
      };
      if (isDefined(colSpan) && colSpan > 1) {
        return {
          style,
          colSpan,
        };
      }
      if (hide) {
        return {
          colSpan: 0,
        };
      }
      indexWithDataSourceType[index] = 'course';
    }

    return {
      style,
    };
  };
}
