import { useEffect, useRef } from 'react';

// Inspired by https://stackoverflow.com/a/55808960/2710714
export function useDeepEffect(fn: () => void, deps: unknown[]) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = JSON.stringify(prevDeps.current) === JSON.stringify(deps);

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }
    return undefined;
  }, deps);
}
