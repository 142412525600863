import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setPageFilters } from '../pages/slices/allocation.slice';
import { paths } from '../../routes/routes';
import { isEmpty } from 'lodash';
import { isDefined } from '@timeedit/registration-shared';

export const nameOfStateFilterSearchParam = 'stateFilter';

// The reason why we need this as an extra function: If we later on decide to change the state format, we can do it here.
export function jsonToStateFilterUrlParam(jsonInput: unknown): string {
  const stringified = JSON.stringify(jsonInput);
  const urlified = encodeURIComponent(stringified);
  return urlified;
}
type UseSearchParamsProps<T extends object> = {
  searchParamName: string; // string as JSON
  pageName: keyof typeof paths;
  defaultValue: T | undefined;
};

export type SetSearchParamState<T> = T | ((previousState: T) => T);
// Inspired by https://blog.logrocket.com/use-state-url-persist-state-usesearchparams/
export function useSearchParamsState<T extends object>({
  pageName,
  searchParamName,
  defaultValue,
}: UseSearchParamsProps<T>): readonly [
  searchParamsState: T | undefined,
  setSearchParamsState: (newState: SetSearchParamState<T>) => void,
] {
  const previousState = useRef<T | undefined>(defaultValue);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsRaw = searchParams.get(searchParamName);
  const dispatch = useDispatch();

  const acquiredSearchParam: T | undefined = useMemo(() => {
    try {
      let newState: T | undefined;
      if (isDefined(searchParamsRaw)) {
        newState = JSON.parse(searchParamsRaw);
      } else if (isDefined(defaultValue)) {
        newState = defaultValue;
      }

      // Remove empty values
      for (const key in newState) {
        if (isEmpty(newState[key])) {
          delete newState[key];
        }
      }

      previousState.current = newState;
      return newState;
    } catch (err) {
      console.error(err);
      return {} as T;
    }
  }, [searchParams]);

  const setSearchParamsState = (input: T | ((previousState: T) => T)) => {
    const newState =
      typeof input === 'function' ? (input as (previousState: T | undefined) => T)(previousState?.current) : input;

    const next = {
      ...[...searchParams.entries()].reduce((o, [key, value]) => ({ ...o, [key]: value }), {}),
      [searchParamName]: JSON.stringify(newState),
    };
    setSearchParams(next);
    previousState.current = newState;
  };

  useEffect(() => {
    if (!isEmpty(defaultValue) && isEmpty(acquiredSearchParam)) {
      setSearchParamsState(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Saving the filter state to redux if we unmount
  useEffect(() => {
    return () => {
      dispatch(setPageFilters({ key: pageName, value: acquiredSearchParam }));
    };
  }, [dispatch, acquiredSearchParam, pageName]);

  return [acquiredSearchParam, setSearchParamsState];
}
