import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Registration, UnsafeRecord } from '@timeedit/registration-shared';

export type RegistrationState = UnsafeRecord<string, StudentRegistrationState>;

type StudentRegistrationStatus = 'initial' | 'loading' | 'fetching' | 'fulfilled' | 'error';

interface StudentRegistrationState {
  status: StudentRegistrationStatus;
  registration: Registration;
  error?: unknown;
}

export const initialRegistration: StudentRegistrationState = {
  status: 'initial',
  registration: {
    studentId: 0,
    courses: {},
    trackLists: {},
    tracks: {},
    events: {},
    conflicts: [],
    timezone: '',
  },
};

const initialState: RegistrationState = {};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<{ studentId: number; status: StudentRegistrationStatus }>) => {
      const studentState = state[action.payload.studentId];
      return {
        ...state,
        [action.payload.studentId]: {
          ...studentState,
          status: action.payload.status,
        },
      };
    },
    setRegistration: (state, action: PayloadAction<{ studentId: number; registration: Registration }>) => {
      const studentState = state[action.payload.studentId];
      return {
        ...state,
        [action.payload.studentId]: {
          ...studentState,
          status: 'fulfilled',
          registration: action.payload.registration,
        },
      };
    },
    setError: (state, action: PayloadAction<{ studentId: number; error: unknown }>) => {
      const studentState = state[action.payload.studentId];
      return {
        ...state,
        [action.payload.studentId]: {
          ...studentState,
          status: 'error',
          error: action.payload,
        },
      };
    },
  },
});

export const { setError, setStatus, setRegistration } = registrationSlice.actions;
export default registrationSlice.reducer;
