import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Typography, notification } from 'antd';
import { configService } from '../../../services/config.service';
import { TTEObject, isDefined } from '@timeedit/registration-shared';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { updateStudentObjects, setTrackLists } from '../slices/allocation.slice';
import intl from '../../../i18n/intl';
import { CreateAllocationGroups, allRelatedUniqIds, checkFilterValue } from './utils';
import './BulkAllocationPage.scss';
import { allocationApi, GetStaffCoursesProps, LoadObjects } from '../../services/registration-allocation.service';
import { useMapping } from '../../services/mapping';
import { BulkAllocationTable } from '../../components/Table/BulkAllocationTable';
import {
  fetchAndUpdateIssueList,
  fetchAndUpdatePossibleChangeRequests,
  fetchRelatedCourses,
} from '../slices/fetch.slice';
import { IssueListButton } from '../../components/IssueList/IssueListButton';
import { TFilterValue, TFilterValueSet } from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import { isArray, uniq } from 'lodash';
import { TTEReservation } from './loadReservations';
import { useAbortController } from '../../hooks/UseAbortController';
import { BulkAllocationDrawer } from 'registration-allocation/components/Drawer/BulkAllocationDrawer';

const language = intl.messages as Record<string, string>;

export type AllocationObjectWithCourse = TTEObject & { courseId: number };
export type AllocationGroup = {
  key: string;
  allocationObjects: TTEObject[];
  activityType: string;
  courseId: number;
  students: number[];
};

export function BulkAllocationPage() {
  const dispatch = useDispatch();
  const tracks = useSelector((state: TRootState) => state.allocation.trackObjects);
  const courses = useSelector((state: TRootState) => state.allocation.courseObjects);
  const issuesStatus = useSelector((state: TRootState) => state.issueList.issuesStatus);

  const students = useSelector((state: TRootState) => state.allocation.studentObjectState.studentObjects);
  const mapping = useMapping();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const trackType = mapping.getId('track');
  const courseType = mapping.getId('course');
  const studentType = mapping.getId('student');
  const { signal } = useAbortController();

  useEffect(() => {
    loadStudentsAndTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, trackType, studentType]);

  // Here we add extra properties so we can populate dataSource,
  // for instance the course id, so we can get the fields on the course object
  // If there are no matching courses for the allocationObject we don't add it to the dataSource
  const tracksWithCourses = useMemo(() => {
    return tracks.reduce<AllocationObjectWithCourse[]>((allocObjects, obj) => {
      let matchingCourseId: number | undefined;
      const hasCourse = obj.relations.some(({ objectIds }) => {
        return objectIds.some((objId) => {
          return courses.some(({ id }) => {
            if (id === objId) {
              matchingCourseId = id;
              return true;
            }
            return false;
          });
        });
      });

      if (hasCourse && isDefined(matchingCourseId)) {
        return [...allocObjects, { ...obj, courseId: matchingCourseId }];
      }
      return allocObjects;
    }, []);
  }, [tracks, courses]);

  const trackLists = useMemo(
    () => CreateAllocationGroups({ allocationObjects: tracksWithCourses, students, mapping }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tracksWithCourses, mapping],
  );
  useEffect(() => {
    dispatch(setTrackLists(trackLists));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackLists]);

  useEffect(() => {
    dispatch(fetchAndUpdatePossibleChangeRequests(mapping));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapping]);

  // After courses have loaded we load the issue list (so we do not double load and we hit the cache)
  useEffect(() => {
    if (courses.length > 0) {
      if (issuesStatus === 'initial') {
        dispatch(fetchAndUpdateIssueList());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  const filtersChanged = async (newFilterValue?: TFilterValueSet) => {
    const loadCoursesData: GetStaffCoursesProps = {
      fields: {},
    };

    loadCoursesData.signal = signal();

    if (newFilterValue?.['COURSE-IDS']) {
      const courseIdKeyValues = newFilterValue['COURSE-IDS'];
      if (isArray(courseIdKeyValues)) {
        const numberIds = courseIdKeyValues.map((val) => val.toString()).map((id) => parseInt(id, 10));
        const idsToFilter = [...(loadCoursesData.idsToFilter ?? []), ...numberIds];
        if (idsToFilter.length > 0) {
          loadCoursesData.idsToFilter = idsToFilter;
        }
      }
    }

    if (newFilterValue?.[mapping.getKey('courseLabel')]) {
      loadCoursesData.fields = {
        ...loadCoursesData.fields,
        generalSearchFieldIds: mapping.searchableFields(mapping.getId('course')),
        searchText: newFilterValue[mapping.getKey('courseLabel')] as string,
      };
    }

    if (newFilterValue?.[mapping.getKey('program')]) {
      const programs = newFilterValue[mapping.getKey('program')] as number[];
      if (programs?.length && programs.length > 0)
        loadCoursesData.relations = {
          typeId: mapping.getId('program'),
          objectIds: programs,
        };
    }

    if (newFilterValue?.[mapping.getKey('course')]) {
      loadCoursesData.idsToFilter = newFilterValue[mapping.getKey('course')] as number[];
    }

    const exactSearchFields = [];
    for (const [key, filterValues] of Object.entries(newFilterValue ?? {})) {
      if (!checkFilterValue(filterValues)) {
        continue;
      }

      const fieldId = parseInt(key, 10);
      if (!Number.isNaN(fieldId)) {
        exactSearchFields.push({
          fieldId,
          values: isArray(filterValues) ? filterValues : ([filterValues] as TFilterValue),
        });
      }
    }
    loadCoursesData.fields = { ...loadCoursesData.fields, exactSearchFields };

    await loadCourses(loadCoursesData);
  };

  return (
    <div
      // TODO: Remove the styling once we fix overflow on the standardlayout component in ui-components
      style={{
        overflowY: 'auto',
        height: 'inherit',
      }}
    >
      <div>
        <IssueListButton />
        <Typography.Title level={3} className="title__text">
          {language.bulkAllocation}
        </Typography.Title>
        <Typography.Text type="secondary">{language.bulkAllocationSubtitle}</Typography.Text>
      </div>

      <Divider className="content__divider" />
      <BulkAllocationTable filtersChanged={filtersChanged} setOpenDrawer={setOpenDrawer} />
      <BulkAllocationDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
    </div>
  );

  async function loadCourses(data?: GetStaffCoursesProps) {
    if (isDefined(courseType)) {
      dispatch(fetchRelatedCourses(data));
    }
  }

  function sortNameCourse(course: TTEObject) {
    return mapping.string('courseLabel', course);
  }

  async function loadStudentsAndTrack() {
    if (
      isDefined(studentType) &&
      students.length <= 0 &&
      isDefined(trackType) &&
      tracks.length <= 0 &&
      isDefined(courseType)
    ) {
      try {
        const allCourses = [...courses];
        allCourses.sort((a, b) => sortNameCourse(a).localeCompare(sortNameCourse(b)));
        const firstPart = allCourses.slice(0, 10);
        for (const part of [firstPart, allCourses]) {
          const relatedToCourses = allRelatedUniqIds(part);
          if (relatedToCourses.length === 0) return;
          loadObjectById(relatedToCourses, studentType, true).then((studentsOnCourses) => {
            dispatch(
              updateStudentObjects({
                studentObjects: studentsOnCourses,
                coursesWithStudentsLoaded: part.map((o) => o.id),
              }),
            );
          });
        }
      } catch (error) {
        notification.error({
          duration: 0,
          key: configService().NOTIFICATION_KEY,
          message: 'Failed to load data',
          description: ` Details: ${error}`.slice(0, 600),
        });
      }
    }
  }
}

/**
 *
 * @param ids If ids is empty an empty array is returned.
 * @param typeId The type id of the objects
 * @param useCache if the backend cache should be used.
 * @param signal a signal to abort the request
 * @returns a promise with array of objects or an empty array
 */
export async function loadObjectById(
  ids: number[],
  typeId: number,
  useCache: boolean,
  signal?: AbortSignal,
): Promise<TTEObject[]> {
  if (!isDefined(ids) || ids.length === 0) {
    return Promise.resolve([]);
  }
  return loadObjectBy({ ids: uniq(ids), typeId, useCache, signal });
}

export async function loadObjectBy(props: LoadObjects): Promise<TTEObject[]> {
  return (await allocationApi.loadObjects(props)).data;
}

export async function loadReservationsFor(
  objectIds: number[],
  typeId: number,
  cache: boolean,
): Promise<TTEReservation[]> {
  if (!isDefined(objectIds) || objectIds.length === 0) {
    return Promise.resolve([]);
  }
  const uniqueIds = uniq(objectIds);
  const reservations = (await allocationApi.loadReservationsFor(uniqueIds, typeId, cache)).data;
  return reservations;
}
