import { isDefined, TField, UnsafeRecord } from '@timeedit/registration-shared';
import { convertToString } from '../../pages/BulkAllocationPage/utils';

export const MAX_STEPS = 3;

// Will count from 0 to N and create a type which is 0 | 1 | ... | N
type Range<N extends number, Result extends unknown[] = []> = Result['length'] extends N
  ? Result[number] | N
  : Range<N, [...Result, Result['length']]>;

export type MaxStepRange<T extends number> = Range<T>;

export type CSVType = {
  course: string;
  activityName: string;
  trackName: string;
  studentId: number;
  completeOnActivity: 'Yes' | 'No';
  outcome: 'Pass' | 'Fail';
  reason: string;
  conflicting: string;
  linkToStudent: string;
};

export function inStepRange<T extends number>(n: number, max: T): n is MaxStepRange<T> {
  return Number.isInteger(n) && n >= 0 && n <= max;
}

export function conditionalQuotes(str: string) {
  // Check if the string needs to be wrapped with double quotes
  // Escape any double quotes and wrap the value in double quotes
  return str.includes(',') || str.includes('"') ? `"${str.replace(/"/g, '""')}"` : str;
}

// We use 'any' here because after we remap the data we don't know what the property names are
export function convertToCSV(data: UnsafeRecord<string, any>[]): string {
  if (data.length === 0) return '';

  // Get the headers from the first data object
  const headers = Object.keys(data[0]);

  // Map the data to CSV format
  const csvRows = data.map((row) => {
    return headers
      .map((header) => {
        const value = row[header];
        if (!isDefined(value)) {
          return '';
        }
        const stringValue = String(value);

        return conditionalQuotes(stringValue);
      })
      .join(',');
  });

  // Join the headers and rows into a single CSV string
  return [headers.join(','), ...csvRows].join('\n');
}

export function downloadCSV(csv: string, filename: string) {
  // Create a Blob with the CSV data and type
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  // Create an anchor tag for downloading
  const link = document.createElement('a');
  if (isDefined(link.download)) {
    const url = URL.createObjectURL(blob);

    // Set the URL and download attribute of the anchor tag and name of the file
    link.href = url;
    link.download = filename;
    link.style.visibility = 'hidden';

    // Trigger the download by clicking the anchor tag
    // Append and remove the anchor from the document
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function createCategoryOptions(studentFields: TField[]) {
  return studentFields
    .filter((field) => isDefined(field.categories) && field.categories.length > 0)
    .flatMap((field) => [
      { value: field.id, label: field.name, children: studentCategoryChildren({ id: field.id, studentFields }) },
    ]);
}

type StudentCategoryChildren = {
  id: number;
  studentFields: TField[];
};

function studentCategoryChildren({ id, studentFields }: StudentCategoryChildren) {
  const field = studentFields.find((field) => field.id === id);
  if (!isDefined(field)) {
    return [];
  }
  if (!isDefined(field.categories)) {
    return [];
  }
  return field.categories.flatMap((text) => [{ value: convertToString(text), label: convertToString(text) }]);
}
