import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TEObjectLabeledFieldSelector, TEObjectSelector } from '../../../slices/integration.slice';
import { TPathway, TPathwayConfig } from '../PathwayService.type';
import { PathwayCoursesModal as PathwayCoursesModalComponent } from '@timeedit/ui-components';
import { compact } from 'lodash';
import { TEObject } from '@timeedit/ui-components/lib/src/types/TEObject.type';

interface Props {
  open?: boolean;
  onClose: () => void;
  pathway?: Partial<TPathway>;
  courses?: TEObject[];
  form?: TPathwayConfig;
  title?: string;
}
function PathwayCoursesModal(props: Props) {
  const { open, onClose, pathway, courses, form, title } = props;
  const labeledFields = useSelector(TEObjectLabeledFieldSelector());
  const objectScope = form?.objectScope;
  const allObjectScopes = useSelector(TEObjectSelector(objectScope));

  const selectedScopedObject = useMemo(() => {
    return allObjectScopes.find((obj) => obj.te_extid === pathway?.scopedObject);
  }, [allObjectScopes, pathway]);

  const modalTitle = useMemo(() => {
    if (title) return title;
    if (!pathway || !objectScope) return '';
    return compact([selectedScopedObject?.[labeledFields[objectScope] || ''], pathway?.name].join(' '));
  }, [title, selectedScopedObject, labeledFields, objectScope, pathway]);

  return (
    <PathwayCoursesModalComponent
      open={open}
      onCancel={onClose}
      onOk={onClose}
      title={modalTitle}
      mandatoryCourseIds={pathway?.mandatoryCourseIds || []}
      electiveCourseIds={pathway?.electiveCourseIds || []}
      courses={courses || []}
      fieldSettings={{
        label: labeledFields?.[form?.pathwaysSettings.course.datasource || ''],
        credits: form?.pathwaysSettings.course.fields.credits || '',
      }}
    />
  );
}

export default PathwayCoursesModal;
