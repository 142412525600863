import { ExpandableConfig } from 'antd/es/table/interface';
import { BAllocateDataSource, calculateDefaultExpandedRows, isCourseDataSource } from './utils';
import { Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';
import '../../pages/BulkAllocationPage/BulkAllocationPage.scss';
import { useLoadCourseRelated } from './useLoadCourseRelated';

export function useCreateExpandable(): { expandable: ExpandableConfig<BAllocateDataSource>; collapseAll: () => void } {
  const dataSource = useSelector((state: TRootState) => state.allocation.overviewCourseDataSource);

  const loadCourseRelated = useLoadCourseRelated();

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    const defaultExpandedRows = calculateDefaultExpandedRows(dataSource);
    setExpandedKeys((previousKeys) => previousKeys.concat(defaultExpandedRows));
  }, [dataSource]);

  const expandable = useMemo<ExpandableConfig<BAllocateDataSource>>(() => {
    return {
      expandedRowKeys: expandedKeys,
      expandRowByClick: true,
      onExpand(expanded, record) {
        if (expanded && isCourseDataSource(record)) {
          loadCourseRelated([record.key]);
        }
        setExpandedKeys((previousKeys) => {
          if (expanded) {
            return [...previousKeys, record.key];
          }
          return previousKeys.filter((key) => key !== record.key);
        });
      },

      expandIcon: ({ expanded, onExpand, record }) => {
        if (record.children) {
          return (
            <Button
              className="te-expand-icon"
              type="text"
              onClick={(e) => {
                onExpand(record, e);
                e.stopPropagation();
              }}
              icon={
                <CaretDownOutlined
                  style={{
                    transform: `rotate(${expanded ? '0deg' : '270deg'})`,
                  }}
                />
              }
            />
          );
        }
        return '';
      },
    };
  }, [expandedKeys, loadCourseRelated]);

  function collapseAll() {
    setExpandedKeys([]);
  }

  return { expandable, collapseAll };
}
