import { TableProps } from 'antd';
import { useCreateOnCell } from './useCreateOnCell';
import React, { useEffect, useMemo, useState } from 'react';
import { setSelected } from '../../pages/slices/allocation.slice';
import { useDispatch, useSelector } from 'react-redux';
import { convertToString, makeStudentInfo } from '../../pages/BulkAllocationPage/utils';
import { findEnrolledStudents, BAllocateDataSource, isTrackListDataSource, filterOutMissingIds } from './utils';
import { TRootState } from '../../..';
import { useLoadCourseRelated } from './useLoadCourseRelated';
import { uniq } from 'lodash';
import { DUMMY_ROW_NAME } from './useCreateDataSource';
import { isDefined } from '@timeedit/registration-shared';

type CreateRowSelectionProps = {
  rowSelection: TableProps<BAllocateDataSource>['rowSelection'];
  deSelectAll: () => void;
};
export function useCreateRowSelection(): CreateRowSelectionProps {
  const dispatch = useDispatch();
  const onCell = useCreateOnCell();
  const loadCourseRelated = useLoadCourseRelated();
  const trackLists = useSelector((state: TRootState) => state.allocation.trackLists);
  const students = useSelector((state: TRootState) => state.allocation.studentObjectState.studentObjects);
  const tracks = useSelector((state: TRootState) => state.allocation.trackObjects);
  const courses = useSelector((state: TRootState) => state.allocation.courseObjects);
  const dataSource = useSelector((state: TRootState) => state.allocation.overviewCourseDataSource);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    const ids = selectedKeys.reduce((selectedTracks: string[], key) => {
      const trackListsMatchingCourseOrActivity = trackLists.filter(({ key: groupKey, courseId }) => {
        return groupKey === key || courseId.toString() === key;
      });

      if (trackListsMatchingCourseOrActivity.length > 0) {
        const tracks = trackListsMatchingCourseOrActivity.flatMap((tL) =>
          tL.allocationObjects.map((aO) => aO.id.toString()),
        );
        return [...selectedTracks, ...tracks];
      }
      const matchingTrack = tracks.find((track) => track.id.toString() === key);
      if (isDefined(matchingTrack)) {
        return [...selectedTracks, key];
      }

      return selectedTracks;
    }, []);

    const trackCourseIds = trackLists.reduce((selectedCourseIds: number[], group) => {
      const selectedAllocationObjects = group.allocationObjects.filter((obj) => ids.includes(obj.id.toString()));
      if (selectedAllocationObjects.length === 0 || selectedCourseIds.includes(group.courseId)) {
        return selectedCourseIds;
      }
      return [...selectedCourseIds, group.courseId];
    }, []);

    const filterCourseIds = courses.filter((o) => ids.includes(o.id.toString())).map((o) => o.id);
    const courseIds = uniq([...trackCourseIds, ...filterCourseIds]);

    const selectedTracks = uniq(ids);

    const selectedStudents = findEnrolledStudents({ courseIds, students });
    const studentInfo = makeStudentInfo({ selectedStudents, selectedTracks });

    dispatch(
      setSelected({
        courseIds,
        studentInfo,
        students: selectedStudents,
        tracks: selectedTracks,
      }),
    );
  }, [selectedKeys, trackLists, tracks, students, courses, dispatch]);

  useEffect(() => {
    setSelectedKeys((prev) => filterOutMissingIds({ currentIds: prev, dataSourceToMatch: dataSource }));
  }, [dataSource]);

  const rowSelection: TableProps<BAllocateDataSource>['rowSelection'] = useMemo(() => {
    return {
      checkStrictly: false,
      getCheckboxProps(record: BAllocateDataSource) {
        if (isTrackListDataSource(record) && record.loading) {
          return { style: { display: 'none' } };
        }
        if (!record.loading && record.id.startsWith(DUMMY_ROW_NAME)) {
          return { style: { display: 'none' } };
        }
        return {};
      },
      onCell,
      selectedRowKeys: selectedKeys,
      onChange(rowKeys: React.Key[]) {
        const keys = rowKeys.map(convertToString);
        setAndLoadKeys(keys);
      },
    };
  }, [selectedKeys, trackLists, students, tracks]);

  return {
    rowSelection,
    deSelectAll: () => {
      setSelectedKeys([]);
    },
  };

  function setAndLoadKeys(keys: string[]) {
    setSelectedKeys(keys);

    if (keys.length > 0) {
      keys.forEach((key) => loadCourseRelated([key]));
    }
  }
}
