import { Button } from '@timeedit/ui-components';
import React, { useEffect, useState } from 'react';
import { allocationApi } from '../../services/registration-allocation.service';
import { Input, Spin, Typography, notification } from 'antd';
import { configService } from '../../../services/config.service';
import intl from '../../../i18n/intl';

const language = intl.messages as Record<string, string>;

// TODO: Hard coded texts in this file because it will probably not be used
export function RegistrationSettingsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    allocationApi.getRegistrationHomepageHTML().then((response) => {
      if (response.status === 200) {
        setTextValue((previousText) => {
          if (previousText === '') {
            return response.data;
          }
          return previousText;
        });
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <div
      // TODO: Remove the styling once we fix overflow on the standardlayout component in ui-components
      style={{
        overflowY: 'auto',
        height: 'inherit',
      }}
    >
      <Typography.Text>Set HTML for Registration Page</Typography.Text>

      {isLoading ? (
        <Spin />
      ) : (
        <Input.TextArea
          value={textValue}
          onChange={(e) => {
            setTextValue(e.target.value);
          }}
          autoSize={{ minRows: 4 }}
          style={{ margin: '1rem', width: '97%' }}
          placeholder="HTML for registration homepage"
        />
      )}
      <Button
        onClick={async () => {
          try {
            await allocationApi.setRegistrationHomepageHTML();
          } catch (error) {
            notification.error({
              duration: 0,
              key: configService().NOTIFICATION_KEY,
              message: 'Error setting HTML for registration homepage',
              description: ` Details: \n ${error}`.slice(0, 600),
            });
          }
        }}
      >
        {language.save}
      </Button>
    </div>
  );
}
