/* eslint-disable no-await-in-loop */
import { DefaultContentLayout } from '@timeedit/ui-components';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Modal,
  TimePicker,
  DatePicker,
  notification,
  Spin,
  Checkbox,
  Collapse,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { allocateDateTimeFormat } from '@timeedit/preferences-and-dm-commons/lib/src';
import { Mapping, useMapping } from '../../services/mapping';
import dayjs from 'dayjs';
import { loadObjectBy, loadObjectById } from '../BulkAllocationPage';
import { chunk, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { allocationApi } from '../../services/registration-allocation.service';
import { configService } from '../../../services/config.service';
import intl, { getInlineString } from '../../../i18n/intl';
import ObjectSearcher from '../../components/Drawer/ObjectSeacher';
import {
  MappingShorthandField,
  TField,
  TStartOrEndBatchSetResult,
  TTEObject,
  UnsafeRecord,
  allRelatedUniqIds,
  isArray,
  isDefined,
} from '@timeedit/registration-shared';
import { createCategoryDropdowns } from 'registration-allocation/components/Drawer/CategoryDropdowns';
import { TRootState } from '../../..';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { EFieldType } from '@timeedit/types/lib/enums';
import { TableRowSelection } from 'antd/es/table/interface';
import { teServerDateFormat } from 'registration-allocation/settings/Date';
import { ReloadOutlined } from '@ant-design/icons';

const language = intl.messages as Record<string, string>;

type TCourse = {
  id: number;
  startDate: string;
  endDate: string;
  course: TTEObject;
};

const DEFAULT_PAGE_SIZE = 1000;
const format = 'HH:mm';
const firstColumnFields: MappingShorthandField[] = ['courseLabel', 'courseTitle'];

export function RegistrationPeriodsPage() {
  const dispatch = useDispatch();
  const mapping = useMapping();

  const allfields = useSelector((state: TRootState) => state.allocation.fields);
  const [courseFields, setCourseFields] = useState<TField[]>([]);
  const [categoryValues, setCategoryValues] = useState<Map<number, string[]>>(new Map());
  const [searchText, setSearchText] = useState<string>('');

  const [tableData, setTableData] = useState<TCourse[]>([]);

  const [tableLoading, setTableLoading] = useState<boolean>();
  const [selectedCourseIds, setSelectedCourseIds] = useState<number[]>([]);

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const [openEditStartOrEndModal, setOpenEditStartOrEndModal] = useState<'start' | 'end' | null>(null);
  const [dateSetInModal, setDateSetInModal] = useState<dayjs.Dayjs | null>(dayjs().startOf('year'));
  const [timeSetInModal, setTimeSetInModal] = useState<dayjs.Dayjs | null>(dayjs('00:00', format));

  const [writeCoursesWithRegistrationPeriodSet, setWriteCoursesWithRegistrationPeriodSet] = useState<boolean>(true);
  const [writeCoursesWithRegistrationRunning, setWriteCoursesWithRegistrationRunning] = useState<boolean>(true);
  const [isSaving, setSaving] = useState<boolean>(false);
  const loading = 'Loading...';
  const NO_TRACKS_TEXT = `0 ${mapping.typename('track').toLowerCase()}`;

  function presentValue(object: TTEObject, field: TField) {
    if (!isDefined(object)) return '-';
    if (!isDefined(object.fields)) return '-';
    const values = object.fields.find((f) => f.fieldId === field.id)?.values;
    if (!isDefined(values)) return 'no values';
    if (field.fieldType === EFieldType.CHECKBOX) {
      if (values.length === 0) return language.no;
      return values.map((v) => (v === '1' ? language.yes : language.no)).join(', ');
    }
    return values.join(', ');
  }

  const createTableData = (courses: TTEObject[]): TCourse[] => {
    return courses.map((course) => {
      const fieldKeys: UnsafeRecord<string, string> = {};
      courseFields.forEach((field) => {
        fieldKeys[`fff${field.id}`] = presentValue(course, field);
      });
      return {
        id: course.id,
        course,
        startDate: loading,
        endDate: loading,
        name: `${mapping.string('courseLabel', course)} - ${mapping.string('courseTitle', course)}`,
        ...fieldKeys,
      };
    });
  };

  const findDate = (tracks: TTEObject[], mapping: Mapping, shorthand: 'startDate' | 'endDate'): string => {
    if (tracks.length === 0) {
      return NO_TRACKS_TEXT;
    }
    const dates = tracks.map((t) => mapping.string(shorthand, t));
    if (dates.every((date) => !date)) {
      return `${language.registrationPeriodNotSet}`;
    }
    // Ideal case: All tracks have same value. Return the first one in that case
    if (uniq(dates).length === 1) {
      return dates[0];
    }
    return language.registrationPeriodInconsistent;
  };

  function updateTable(changes: TCourse[]) {
    setTableData((previousValue) => {
      if (!previousValue) return [];
      const newValue = [...previousValue];
      for (const change of changes) {
        const index = previousValue?.findIndex((c) => c.id === change.id);
        newValue[index] = { ...newValue[index], startDate: change.startDate, endDate: change.endDate };
      }
      return newValue;
    });
  }

  const loadTracks = async (mapping: Mapping, allCourses: TCourse[]) => {
    const chunks = chunk(allCourses, 100);
    for (const courses of chunks) {
      const allTracks = await loadObjectById(
        allRelatedUniqIds(courses.map((c) => c.course)),
        mapping.getId('track'),
        true,
      );

      const changes: TCourse[] = [];
      for (const course of courses) {
        if (course.startDate !== loading) continue;

        if (!course.course.relations.length) {
          changes.push({ id: course.id, course: course.course, startDate: NO_TRACKS_TEXT, endDate: NO_TRACKS_TEXT });
          continue;
        }

        const related = allRelatedUniqIds(course.course);
        const tracks = allTracks.filter((t) => related.includes(t.id));

        if (!tracks.length) {
          changes.push({ id: course.id, course: course.course, startDate: NO_TRACKS_TEXT, endDate: NO_TRACKS_TEXT });
          continue;
        }
        changes.push({
          id: course.id,
          course: course.course,
          startDate: findDate(tracks, mapping, 'startDate'),
          endDate: findDate(tracks, mapping, 'endDate'),
        });
      }
      updateTable(changes);
    }
  };

  const onCourseChange = (ids: number[]) => {
    if (ids.length === 0) {
      return;
    }
    loadCourses(() => loadObjectById(ids, mapping.getId('course'), true));
  };

  function sortNameCourse(course: TTEObject) {
    return mapping.string('courseLabel', course);
  }

  const loadCourses = async (courseLoader: () => Promise<TTEObject[]>) => {
    setSelectedCourseIds([]);
    setTableLoading(true);
    try {
      const found = await courseLoader();
      setTableLoading(false);
      found.sort((a, b) => sortNameCourse(a).localeCompare(sortNameCourse(b)));
      const data = createTableData(found);
      setTableData(data);
      loadTracks(mapping, data);
    } catch (e) {
      setTableLoading(false);
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: language.loadingCoursesFailedDetails,
        description: ` Details: ${e}`.slice(0, 600),
      });
      console.error('Error loading courses', e);
    }
  };

  function reloadCourseTable(data: TStartOrEndBatchSetResult) {
    setTableLoading(true);
    const reload: TCourse[] = [...(tableData ?? [])];
    for (const courseId of data.successFullySet) {
      const index = tableData?.findIndex((c) => c.id === courseId);
      if (isDefined(index) && index !== -1) {
        reload[index] = {
          ...(tableData ?? [])[index],
          startDate: loading,
          endDate: loading,
        };
      }
    }
    setTableData(reload);
    loadTracks(mapping, reload);
    setTableLoading(false);
  }

  const saveCourses = async () => {
    if (!selectedCourseIds?.length) return;
    if (!openEditStartOrEndModal) return;

    setSaving(true);

    const format = allocateDateTimeFormat.split(' ');
    const registrationDateToSet = `${dateSetInModal?.format(format[0])} ${timeSetInModal
      ?.set('seconds', 0)
      .set('millisecond', 0)
      .format(format[1])}`;

    const fieldname =
      openEditStartOrEndModal === 'start' ? mapping.fieldname('startDate') : mapping.fieldname('endDate');
    const savedMessage = getInlineString(
      'savePeriodSuccess',
      fieldname,
      selectedCourseIds?.length,
      mapping.typename('course'),
    );
    try {
      const { data } = await allocationApi.registrationPeriodsStartOrEndBatchSet({
        courseIds: selectedCourseIds,
        startOrEnd: openEditStartOrEndModal,
        registrationDateToSet,
        writeCoursesWithRegistrationPeriodSet,
        writeCoursewWithRegistrationRunning:
          writeCoursesWithRegistrationRunning && writeCoursesWithRegistrationPeriodSet,
      });
      setOpenEditStartOrEndModal(null);
      setWriteCoursesWithRegistrationPeriodSet(true);
      setWriteCoursesWithRegistrationRunning(true);

      if (
        data.courseNotFound.length > 0 ||
        data.noTracksFound.length > 0 ||
        Object.keys(data.errorsWhileSetting).length > 0 ||
        data.excludedBecauseRegistrationRunning.length > 0 ||
        data.excludedRegistrationPeriodSet.length
      ) {
        notification.error({
          duration: 0,
          key: configService().NOTIFICATION_KEY,
          message: language.storingCoursesFailedPartiallyFailed,
          description: JSON.stringify(data, null, 2),
        });
      } else {
        notification.success({
          duration: 5,
          key: configService().NOTIFICATION_KEY,
          message: 'Saved',
          description: savedMessage,
        });
      }
      reloadCourseTable(data);
    } catch (e) {
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: language.storingCoursesFailedDetails,
        description: ` Details: ${e}`.slice(0, 600),
      });
      console.error('Error storing courses', e);
    } finally {
      setSaving(false);
    }
  };

  const columns = useMemo((): ColumnsType<TCourse> => {
    if (courseFields.length === 0) return [];
    const result = [
      {
        title: mapping.typename('course'),
        key: 'name',
        dataIndex: 'name',
        width: 350,
      },
      {
        title: mapping.fieldname('startDate'),
        key: 'startDate',
        dataIndex: 'startDate',
        width: 150,
      },
      {
        title: mapping.fieldname('endDate'),
        key: 'endDate',
        dataIndex: 'endDate',
        width: 150,
      },
    ];

    courseFields.forEach((field) => {
      result.push({
        title: mapping.fieldname(field.id),
        key: `fff${field.id}`,
        dataIndex: `fff${field.id}`,
        width: width(field),
      });
    });
    return result;
  }, [courseFields, mapping]);

  function width(field: TField): number {
    if (!isDefined(field)) return 200;
    if (
      field.fieldType === EFieldType.CHECKBOX ||
      field.fieldType === EFieldType.INTEGER ||
      field.fieldType === EFieldType.LENGTH
    )
      return 100;
    return 200;
  }

  useEffect(() => {
    if (!allfields.length) return;
    const all = mapping.includeFields(mapping.getId('course'));
    const used = firstColumnFields.map((f) => mapping.getId(f));
    const ids = all
      .filter((id) => !used.includes(id))
      .map((id) => allfields.find((f) => f.id === id))
      .filter(isDefined);
    setCourseFields(ids);
  }, [allfields, mapping]);

  useEffect(() => {
    if (!courseFields.length) return;
    if (!mapping) return;
    const category = Array.from(categoryValues, ([fieldId, values]) => ({ fieldId, values }));
    const fields = mapping.searchableFields(mapping.getId('course'));
    loadCourses(() =>
      loadObjectBy({
        text: searchText,
        fields,
        category,
        typeId: mapping.getId('course'),
        limit: pageSize,
        useCache: true,
      }),
    );
  }, [pageSize, courseFields, categoryValues, searchText, mapping]);

  const categoryDropdowns = useMemo(() => {
    const changeValues = (id: number, values: string[]) => {
      setCategoryValues((prev) => {
        const result = new Map(prev);
        result.set(id, values);
        return result;
      });
    };
    return createCategoryDropdowns(mapping, 'course', allfields, changeValues);
  }, [mapping, allfields]);

  const searchfilters = (
    <div>
      {categoryDropdowns}
      <div className={'drawer__item--margin categoryTitle'}>
        <div>{language.search}</div>
        <Search
          placeholder={`${language.search} ${mapping.typename('course')}`}
          allowClear
          size="small"
          onSearch={setSearchText}
          style={{ width: 200 }}
        />
      </div>
    </div>
  );

  const pageSizeOptions = [10, 100, 1000, 10000].map((v) => {
    return { value: v, label: `Max ${v}` };
  });

  const clearAllCaches = async () => {
    await allocationApi.clearCache();
  };

  const footer = (
    <>
      <Button
        size="small"
        style={{ marginRight: 16 }}
        disabled={!selectedCourseIds?.length}
        onClick={() => {
          setOpenEditStartOrEndModal('start');
        }}
      >
        {language.edit} {mapping.fieldname('startDate')}
      </Button>
      <Button
        size="small"
        style={{ marginRight: 16 }}
        disabled={!selectedCourseIds?.length}
        onClick={() => {
          setOpenEditStartOrEndModal('end');
        }}
      >
        {language.edit} {mapping.fieldname('endDate')}
      </Button>
      {selectedCourseIds?.length} selected
      <div style={{ marginLeft: 'auto' }}>
        <Select
          style={{ marginRight: 16, width: 100 }}
          onChange={setPageSize}
          size="small"
          value={pageSize}
          options={pageSizeOptions}
        />
        <Tooltip placement="left" title="Clear cache">
          <Button
            className="refresh__button"
            size="small"
            shape="circle"
            type="text"
            icon={<ReloadOutlined />}
            onClick={(e) => clearAllCaches()}
          />
        </Tooltip>
        {/* TODO Add edit columns
        <Button
          size="small"
          onClick={() => {
            // setOpenChangeColumns(true);
          }}
        >
          {language.edit} {language.columns}
        </Button>
        */}
      </div>
    </>
  );

  const rowSelection: TableRowSelection<TCourse> = {
    selectedRowKeys: selectedCourseIds,
    onSelect: (record: TCourse, selected: boolean, rows: TCourse[]) => {
      setSelectedCourseIds(rows.map((r) => r.id));
    },
    onSelectAll: (selected: boolean, selectedRows: TCourse[], changeRows: TCourse[]) => {
      setSelectedCourseIds(selected ? tableData.map((r) => r.id) : []);
    },
  };

  return (
    <DefaultContentLayout title={language.registrationPeriods}>
      <Collapse
        ghost
        className="ghostcollapse"
        size="small"
        items={[
          {
            label: `${mapping.typename('course')}`,
            children: (
              <ObjectSearcher
                className="drawer__item--margin te-mt-2"
                wide
                mapping={mapping}
                type="course"
                fields={firstColumnFields}
                placeholder={`${mapping.typename('course')}`}
                onChange={onCourseChange}
                dispatch={dispatch}
              />
            ),
          },
        ]}
      />
      <div style={{ paddingTop: 15, paddingBottom: 10 }}>{searchfilters}</div>
      <div className="objecttable">
        <Table<TCourse>
          loading={tableLoading}
          dataSource={tableData}
          columns={columns}
          rowKey="id"
          tableLayout={'fixed'}
          pagination={{
            position: ['topLeft'],
            defaultPageSize: 200,
            showSizeChanger: false,
            pageSizeOptions: [200],
            size: 'small',
            showTotal: (total, range) => (
              <div style={{ minWidth: 150 }}>
                Showing {range[0] === 1 ? 1 : range[0] - 1} - {range[1]} of {total}
              </div>
            ),
          }}
          rowSelection={rowSelection}
          footer={() => footer}
        />
      </div>
      {openEditStartOrEndModal && (
        <Modal
          closable={false}
          open={!!openEditStartOrEndModal}
          onCancel={() => setOpenEditStartOrEndModal(null)}
          maskClosable={false}
          cancelButtonProps={{ disabled: isSaving }}
          okText={language.save_changes}
          cancelText={language.cancel}
          title={getInlineString(
            'editFor',
            mapping.fieldname(openEditStartOrEndModal === 'start' ? 'startDate' : 'endDate'),
            selectedCourseIds?.length,
            mapping.typename('course'),
          )}
          onOk={saveCourses}
          okButtonProps={{ disabled: isSaving }}
        >
          <div className="te-flex te-gap-3">
            {isSaving && <Spin spinning={isSaving} style={{ paddingRight: 10 }} />}
            <div className="batch-grid">
              {language.dateGen}
              <DatePicker
                defaultValue={dateSetInModal}
                onChange={(val) => setDateSetInModal(val)}
                format={teServerDateFormat}
                showNow={false}
              />
            </div>
            <div className="batch-grid">
              {language.timeGen}
              <TimePicker
                defaultValue={timeSetInModal}
                format={format}
                onChange={(val) => setTimeSetInModal(val)}
                needConfirm={false}
                changeOnScroll
                showNow={false}
                onCalendarChange={(val) => {
                  if (!isArray(val)) {
                    setTimeSetInModal(val);
                  }
                }}
              />
            </div>
          </div>
          <div className="te-flex te-mt-2 te-gap-3">
            <Checkbox
              defaultChecked
              value={writeCoursesWithRegistrationPeriodSet}
              onChange={(e) => setWriteCoursesWithRegistrationPeriodSet(e.target.checked)}
            />
            {language.writeCoursesWithRegistrationPeriodSet}
          </div>
          <div className="te-flex te-mt-2 te-gap-3">
            <Checkbox
              defaultChecked
              value={writeCoursesWithRegistrationRunning}
              disabled={!writeCoursesWithRegistrationPeriodSet}
              onChange={(e) => setWriteCoursesWithRegistrationRunning(e.target.checked)}
            />
            {language.writeCoursewWithRegistrationRunning}
          </div>
        </Modal>
      )}
    </DefaultContentLayout>
  );
}
