import { InputNumber } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { DiscardFunctions, SaveDiscardSections, SaveFunctions } from './BulkAllocationDrawer';
import { useMapping } from '../../services/mapping';
import { isDefined, TTEObject } from '@timeedit/registration-shared';
import { patch, updateFieldifChanged } from './GeneralInfoSection';
import { setChangesToDiscard } from '../../pages/slices/drawer.slice';
import { useDispatch } from 'react-redux';

type MaxStudentsSectionProps = {
  clickedTrack: TTEObject | undefined;
  removeSaveFunction: (id: SaveDiscardSections) => void;
  addSaveFunction: (saveFunction: SaveFunctions[number]) => void;
  addDiscardFunction: (discardFunction: DiscardFunctions[number]) => void;
};
export function MaxStudentsSection({
  removeSaveFunction,
  addDiscardFunction,
  addSaveFunction,
  clickedTrack,
}: MaxStudentsSectionProps) {
  const mapping = useMapping();
  const dispatch = useDispatch();

  const [maxStudents, setMaxStudents] = useState<number>();
  const initialMaxStudents = useRef<number | undefined>(undefined);

  useEffect(() => {
    const maxStudents = mapping.parse('maxStudents', clickedTrack);
    setMaxStudents(maxStudents);
    initialMaxStudents.current = maxStudents;

    return () => {
      initialMaxStudents.current = undefined;
      removeSaveFunction('MaxStudentsSection');
      dispatch(setChangesToDiscard({ section: 'MaxStudentsSection', discard: false }));
    };
  }, [clickedTrack, mapping]);

  useEffect(() => {
    const changed = maxStudents !== initialMaxStudents.current;
    if (changed) {
      addSaveFunction({ fn: save, id: 'MaxStudentsSection' });
      addDiscardFunction({
        fn: disacrd,
        id: 'LinkedSection',
      });
      dispatch(setChangesToDiscard({ section: 'MaxStudentsSection', discard: true }));
    } else {
      removeSaveFunction('MaxStudentsSection');
      dispatch(setChangesToDiscard({ section: 'MaxStudentsSection', discard: false }));
    }
  }, [maxStudents]);

  return (
    <div className="batch-grid">
      <InputNumber
        className="max-students__input"
        min={0}
        max={999}
        value={maxStudents}
        onChange={onMaxStudentsChange}
      />
    </div>
  );

  function onMaxStudentsChange(value: number | null) {
    setMaxStudents(value ?? 0);
  }
  async function save() {
    const maxStudentsFieldId = mapping.getId('maxStudents');

    if (!isDefined(clickedTrack) || !isDefined(maxStudents)) return;

    const maxStudentsValue = maxStudents.toString();
    const fields = [...updateFieldifChanged(clickedTrack, maxStudentsValue, maxStudentsFieldId)];
    const toPatch = patch(clickedTrack, fields);
    if (isDefined(toPatch)) {
      await toPatch();
    }
  }

  function disacrd() {
    setMaxStudents(initialMaxStudents.current);
  }
}
