import { createMappingUtilsForApp, config, OfKind, parsers } from '@timeedit/registration-shared';

export type MappingShorthand = keyof typeof config;
export type MappingShorthandField = keyof OfKind<'field', typeof config>;

/**
 * Function that creates an instance of the mapping utils for an organization.
 * @param organizationId - the id of the organization to create the mapping for
 * @returns an instance of the mapping utils for the organization
 */
export const createMappingInstance = createMappingUtilsForApp({
  config,
  parsers,
});

export type Mapping = ReturnType<typeof createMappingInstance>;
