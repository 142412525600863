import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { AutoAllocationSection } from './AutoAllocationSection';
import { RegistrationPeriodSection } from './RegistrationPeriodSection';
import { Collapse, Drawer } from 'antd';
import { isDefined, Mapping } from '@timeedit/registration-shared';
import { GeneralInfoSection } from './GeneralInfoSection';
import { useDispatch, useSelector } from 'react-redux';
import { clickedInitialState, setClicked } from '../../pages/slices/drawer.slice';
import { useMapping } from '../../services/mapping';
import { DrawerExtraButtons } from './DrawerExtraButtons';
import { TRootState } from '../../..';
import { DiscardDrawerModal } from './DiscardDrawerModal';
import { resetChangesToDiscard } from 'registration-allocation/pages/slices/drawer.slice';

interface BulkAllocationDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

export function createSaveDiscardSectionValues(mapping: Mapping) {
  // Values are used to display message if we would want to do that on save
  return {
    Default: 'Data', // A default value
    LinkedSection: 'Links',
    GeneralInfoSection: 'General Info',
    DedicatedTrackSectionDiscard: mapping.fieldname('dedicatedTrack'),
    MaxStudentsSection: mapping.fieldname('maxStudents'),
  } as const;
}
export type SaveDiscardSections = keyof ReturnType<typeof createSaveDiscardSectionValues>;

export type SaveFunctions = { fn: () => Promise<void>; id: SaveDiscardSections }[];
export type DiscardFunctions = { fn: () => void; id: SaveDiscardSections }[];

export function BulkAllocationDrawer({ openDrawer, setOpenDrawer }: BulkAllocationDrawerProps) {
  const dispatch = useDispatch();
  const saveFunctionsRef = useRef<SaveFunctions>([]);
  const discardFunctionsRef = useRef<DiscardFunctions>([]);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);

  const mapping = useMapping();

  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const trackId = useSelector((state: TRootState) => state.drawer.clicked.track);
  const changesToDiscard = useSelector((state: TRootState) => state.drawer.changesToDiscard);
  const track = trackObjects.find((t) => t.id === Number(trackId));

  // On track change, reset the discard and save functions
  useEffect(() => {
    return () => {
      discardFunctionsRef.current = [];
      saveFunctionsRef.current = [];
    };
  }, [trackId, trackObjects, mapping]);

  useEffect(() => {
    return () => {
      onClose();
    };
  }, []);

  return (
    <Drawer
      title={
        <span
          data-track-id={track?.id}
        >{`${mapping.parse('activityType', track)} ${mapping.parse('trackNumber', track)}`}</span>
      }
      width={Math.min(500, window.innerWidth - 100)}
      placement="right"
      open={openDrawer}
      onClose={onClose}
      autoFocus={false}
      mask={false}
      extra={<DrawerExtraButtons saveFunctionsRef={saveFunctionsRef} discardFunctionsRef={discardFunctionsRef} />}
    >
      <GeneralInfoSection
        removeSaveFunction={removeSaveFunction}
        addSaveFunction={addSaveFunction}
        addDiscardFunction={addDiscardFunction}
      />
      <RegistrationPeriodSection />
      <div>
        {
          <Collapse
            expandIconPosition="end"
            ghost
            items={[
              {
                key: 'drawerbottomsections',
                label: ``,
                children: <div>{mapping.isMapped('autoAllocation') ? <AutoAllocationSection /> : ''}</div>,
              },
            ]}
          />
        }
      </div>
      <DiscardDrawerModal open={openDiscardModal} onOk={onCloseNoDiscard} onCancel={() => setOpenDiscardModal(false)} />
    </Drawer>
  );

  function addSaveFunction(saveFunction: SaveFunctions[number]) {
    removeSaveFunction(saveFunction.id);
    if (isDefined(saveFunction.fn)) {
      saveFunctionsRef.current.push(saveFunction);
    }
  }

  function removeSaveFunction(id: SaveDiscardSections) {
    saveFunctionsRef.current = saveFunctionsRef.current.filter((fn) => fn.id !== id);
  }

  function addDiscardFunction(discardFunction: DiscardFunctions[number]) {
    removeDiscardFunction(discardFunction.id);
    discardFunctionsRef.current.push(discardFunction);
  }

  function removeDiscardFunction(id: SaveDiscardSections) {
    discardFunctionsRef.current = discardFunctionsRef.current.filter((fn) => fn.id !== id);
  }

  function onClose() {
    const anythingToDiscard = Object.values(changesToDiscard).some((value) => value);
    if (anythingToDiscard) {
      setOpenDiscardModal(true);
    } else {
      onCloseNoDiscard();
    }
  }
  function onCloseNoDiscard() {
    dispatch(resetChangesToDiscard());
    setOpenDiscardModal(false);
    setOpenDrawer(false);
    dispatch(setClicked(clickedInitialState));
  }
}
