import { WarningOutlined } from '@ant-design/icons';
import { Divider, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import intl from '../../../i18n/intl';
import { jsonToStateFilterUrlParam, nameOfStateFilterSearchParam } from '../../hooks/UseStateParamState';

const language = intl.messages as Record<string, string>;

type IssueListSectionProps = {
  issueText: string;
  helpParagraph: string;
  coursesToFilter: number[];
  useWarningIcon?: boolean;
};
export function IssueListSection({ helpParagraph, issueText, coursesToFilter, useWarningIcon }: IssueListSectionProps) {
  return (
    <>
      <Divider className="issue-list-section__divider" />
      <div className="issue-list-section__title">
        {useWarningIcon ? <WarningOutlined className="issue-list-section__icon--warning" /> : ''}
        <Typography.Text strong>{issueText}</Typography.Text>
      </div>
      <div className="issue-list-section__paragraph">
        <Typography.Paragraph>
          {helpParagraph}

          <Link
            className="issue-list-section__paragraph--link"
            to={`./?${nameOfStateFilterSearchParam}=${jsonToStateFilterUrlParam({
              'COURSE-IDS': coursesToFilter.map((id) => id),
            })}`}
          >
            {language.view}
          </Link>
        </Typography.Paragraph>
      </div>
    </>
  );
}
