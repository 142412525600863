import { Modal, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ImportActivitiesModalContext, { TImportActivitiesSettings, initialValue } from './ImportActivitiesModal.context';
import { AxiosError } from 'axios';

import { TABS } from './ImportActivitiesModal.constants';
import ImportActivitiesModalUpload from './ImportActivitiesModalUpload';
import ImportActivitiesModalPreview from './ImportActivitiesModalPreview';
import ImportActivitiesModalFooter from './ImportActivitiesModalFooter';
import ImportActivitiesModalNav from './ImportActivitiesModalNav';

import './ImportActivitiesModal.scss';
import {
  fetchActivityImportTemplate,
  fetchActivityImports,
  importActivities,
} from 'activities/services/activities.service';
import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';
import { userSelector } from 'slices/auth.slice';
import { ActivityEvents, TActivityImportError } from 'activities/pages/types/activity.type';
import { TActivityImport, TImportTemplate } from 'activities/pages/types/importTemplate.type';
import intl from 'i18n/intl';
import ImportActivitiesModalResult from './ImportActivitiesModalResult';
import { TActivityImportResultData } from '@timeedit/activity-manager-shared-lib/lib/public/types/ActivityImportPubSub.type';

const language = intl.messages;

interface IProps {
  organizationId: string;
  open?: boolean;
}
export default function ImportActivitiesModal(props: IProps) {
  const user = useSelector(userSelector);
  const { organizationId } = props;
  const [open, setOpen] = useState(props.open || false);
  const [file, setFile] = useState<undefined | File>(undefined);
  const [settings, setSettings] = useState<TImportActivitiesSettings>(initialValue.settings);
  const [tab, setTab] = useState<(typeof TABS)[number]['key']>(TABS[0].key);
  const [importing, setImporting] = useState(false);
  const [importAttemptId, setImportAttemptId] = useState<string | undefined>(undefined);
  const [importErrors, setImportErrors] = useState<string[]>([]);
  const [importResult, setImportResult] = useState<TActivityImportResultData>();

  const [templates, setTemplates] = useState<Record<string, TImportTemplate>>({});
  const [indexedActivityImport, setIndexedActivityImport] = useState<Record<string, TActivityImport>>({});

  const onOpenModal = () => {
    setOpen(true);
  };
  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_IMPORT_ACTIVITIES_MODAL, onOpenModal);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_IMPORT_ACTIVITIES_MODAL, onOpenModal);
    };
  }, []);

  const onFinish = useCallback(() => {
    onReset();
    setOpen(false);
    document.dispatchEvent(new CustomEvent('REFETCH_ACTIVITIES_TABLE'));
  }, []);

  const onReset = () => {
    setTab(TABS[0].key);
    setSettings(initialValue.settings);
    setFile(undefined);
    setImportErrors([]);
    setImportResult(undefined);
    setImporting(false);
  };

  const onImport = useCallback(async () => {
    if (!file) return;
    const { templateId } = settings;
    if (!templateId || !templates[templateId]) {
      notification.error({
        message: language['activities.import.error.template_not_found'] as string,
      });
      return;
    }
    setImportErrors([]);
    setImporting(true);

    const importData = {
      activityImportId: indexedActivityImport[templateId]?._id,
      file,
      template: templates[templateId],
      owner: user?.id,
      name: templates[templateId]?.name,
      description: '',
    };
    const importReponse = await importActivities(organizationId, importData);

    if (importReponse instanceof Error) {
      setImporting(false);
      const errors: TActivityImportError[] = (importReponse as AxiosError<any>)?.response?.data.errors;
      if (errors) {
        setImportErrors(errors.map((err) => `${err.error}`));
      }
      return;
    }
    const { activityImportAttemptId } = importReponse;
    setImportAttemptId(activityImportAttemptId);
    setTab(TABS[2].key);
  }, [settings, file, indexedActivityImport]);

  useEffect(() => {
    if (!open) {
      onReset();
    } else {
      const doGettingTemplates = async () => {
        const results = await fetchActivityImportTemplate(organizationId);
        const activityImportResults = await fetchActivityImports(organizationId);
        setTemplates(keyBy(results, '_id'));
        setIndexedActivityImport(keyBy(activityImportResults, (item) => item.activityCreateTemplate?._id));
      };
      doGettingTemplates();
    }
  }, [open]);

  const contextValue = useMemo(() => {
    return {
      open,
      tab,
      setTab,
      settings,
      setSettings,
      templates,
      file,
      setFile,
      onClose: () => setOpen(false),
      onReset,
      onImport,
      importing,
      importAttemptId,
      importErrors,
      importResult,
      setImportResult,
      onFinish,
      setImporting,
    };
  }, [
    open,
    tab,
    setTab,
    file,
    importing,
    onImport,
    importErrors,
    settings,
    templates,
    importResult,
    importAttemptId,
    onFinish,
  ]);

  const modalMainContent = useMemo(() => {
    switch (tab) {
      case TABS[0].key:
        return <ImportActivitiesModalUpload />;
      case TABS[1].key:
        return <ImportActivitiesModalPreview />;
      case TABS[2].key:
        return <ImportActivitiesModalResult />;
      default:
        return null;
    }
  }, [tab]);

  return (
    <ImportActivitiesModalContext.Provider value={contextValue}>
      <Modal
        open={open}
        title={language['activities.import.title'] as string}
        footer={<ImportActivitiesModalFooter />}
        onCancel={() => setOpen(false)}
        width={700}
      >
        <ImportActivitiesModalNav />
        <div data-testid="IMPORT_ACTIVITIES_MODAL">{modalMainContent}</div>
      </Modal>
    </ImportActivitiesModalContext.Provider>
  );
}
