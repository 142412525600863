import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Text } from '@timeedit/registration-components';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';
import './BatchAllocateContent.scss';
import { useMapping } from '../../services/mapping';

type AllocateStepProps = {
  setCSV: Dispatch<SetStateAction<string>>;
};
export function AllocateStep({ setCSV }: AllocateStepProps) {
  const { courseIds, students, tracks } = useSelector((state: TRootState) => state.allocation.selected);
  const mapping = useMapping();

  useEffect(() => {
    return () => {
      setCSV(
        (csv) =>
          `${csv}\n\nSummary of allocation\n${mapping.typename('course')},${courseIds.length}\n${mapping.typename('track')},${tracks.length}\n${mapping.typename('student')},${students.length}`,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <p className="allocate-step-allocate-text">Do you want to allocate?</p>
      <Text strong>Summary of allocation:</Text>
      <ul className="step_text-list">
        <li>
          {courseIds.length} {mapping.typename('course')}
        </li>
        <li>
          {tracks.length} {mapping.typename('track')}
        </li>
        <li>
          {students.length} {mapping.typename('student')}
        </li>
      </ul>
    </div>
  );
}
