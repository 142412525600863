import React, { useCallback, useMemo } from 'react';
import { Button, List } from 'antd';
import { useMapping } from '../../services/mapping';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { DownloadOutlined } from '@ant-design/icons';
import './BatchAllocateContent.scss';
import {
  exhaustiveMatchingGuard,
  FailsWithTracks,
  getEntries,
  isDefined,
  Mapping,
  TrackListWithReason,
  TrackListWithTrack,
  UnsafeRecord,
} from '@timeedit/registration-shared';
import { conditionalQuotes, convertToCSV, CSVType, downloadCSV } from './utils';
import { presentCategory as createPresentCategory, presentRelation as createPresentRelation } from '../../utils/text';
import { configService } from '../../../services/config.service';
import { teServerDateFormat } from '../../settings/Date';
import dayjs from 'dayjs';

type ReviewResultsStepProps = {
  csv: string;
};
export function ReviewResultsStep({ csv }: ReviewResultsStepProps) {
  const students = useSelector((state: TRootState) => state.allocation.selected.students);
  const mapping = useMapping();

  const csvTypeData = useCsvTypeData();
  const fullyAllocatedStudents = useMemo(() => calculateFullyAllocatedStudents(csvTypeData), [csvTypeData]);

  const handleClick = useCallback(onClick, [fullyAllocatedStudents, students, mapping]);

  return (
    <div className="review-step-container">
      <List
        bordered
        dataSource={[
          `${fullyAllocatedStudents} ${mapping.typename('student').toLowerCase()}(s) were successfully allocated to selected ${mapping.typename('track').toLowerCase()}(s)`,
          `${students.length - fullyAllocatedStudents} ${mapping.typename('student').toLowerCase()}(s) could not be fully allocated to selected ${mapping.typename('track').toLowerCase()}(s)`,
        ]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
      <br />
      <div>
        <Button icon={<DownloadOutlined />} onClick={() => handleClick(csv, csvTypeData)}>
          Download allocation report
        </Button>
      </div>
    </div>
  );
  function onClick(csv: string, data: CSVType[]) {
    const remappedCsvData = remapCsvType(data, mapping);

    const csvTypeData = convertToCSV(remappedCsvData);

    const csvResult = `${csv}\n\n${mapping.typename('student').toLowerCase()} fully allocated to selected ${mapping.typename('track').toLowerCase()},${fullyAllocatedStudents}\n${mapping.typename('student').toLowerCase()} not fully allocated to selected ${mapping.typename('track').toLowerCase()},${students.length - fullyAllocatedStudents}\n\nResults:\n${csvTypeData}`;

    downloadCSV(csvResult, `allocation ${dayjs().format(`${teServerDateFormat} HH-mm-ss`)}.csv`);
  }
}

function remapCsvType(data: CSVType[], mapping: Mapping) {
  return data.map((d) => ({
    [conditionalQuotes(mapping.typename('course'))]: d.course,
    [conditionalQuotes(mapping.fieldname('activityType'))]: d.activityName,
    [conditionalQuotes(mapping.typename('track'))]: d.trackName,
    [conditionalQuotes(mapping.typename('student'))]: d.studentId,
    'Complete on activity': d.completeOnActivity,
    Outcome: d.outcome,
    Reason: d.reason,
    Conflicting: d.conflicting,
    'Link to student': d.linkToStudent,
  }));
}

function calculateFullyAllocatedStudents(csvTypeData: CSVType[]) {
  const completeOnActivityLookup = csvTypeData.reduce(
    (completeOnActivityLookup: UnsafeRecord<string, boolean>, csvType) => {
      const completeOnActivity = completeOnActivityLookup[csvType.studentId];
      // If the student is not complete on activity, we want to keep it that way.
      if (!isDefined(completeOnActivity) || completeOnActivity) {
        return { ...completeOnActivityLookup, [csvType.studentId]: csvType.completeOnActivity === 'Yes' };
      }

      return completeOnActivityLookup;
    },
    {},
  );

  return Object.values(completeOnActivityLookup).filter((complete) => isDefined(complete) && complete).length;
}

function useCsvTypeData(): CSVType[] {
  const mapping = useMapping();
  const students = useSelector((state: TRootState) => state.allocation.selected.students);
  const conflicts = useSelector((state: TRootState) => state.allocation.conflicts);
  const tracks = useSelector((state: TRootState) => state.allocation.trackObjects);
  const courses = useSelector((state: TRootState) => state.allocation.courseObjects);
  const failed = useSelector((state: TRootState) => state.allocation.allocationFailedGroups);
  const success = useSelector((state: TRootState) => state.allocation.allocationSuccessGroups);
  const searchedObjects = useSelector((state: TRootState) => state.allocation.searchedObjects);

  return useMemo(
    () =>
      students.reduce((csvTypeData: CSVType[], studentId) => {
        const linkToStudent = `${configService().REACT_APP_URL}allocation/student-adjustment/registration/${studentId}`;

        const successForStudent = success[studentId];
        const successData: CSVType[] = isDefined(successForStudent)
          ? generateSuccessCSVType({ linkToStudent, studentId, successForStudent })
          : [];

        const failedForStudent = failed[studentId];
        const failedData: CSVType[] = isDefined(failedForStudent)
          ? generateFailedCSVType({ failedForStudent, linkToStudent, studentId, successForStudent })
          : [];

        return [...csvTypeData, ...failedData, ...successData];
      }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [students, failed, success, tracks, courses, conflicts, searchedObjects],
  );

  type GenerateSuccessCSVProps = {
    studentId: number;
    linkToStudent: string;
    successForStudent: TrackListWithTrack[string];
  };

  function generateSuccessCSVType({ successForStudent, studentId, linkToStudent }: GenerateSuccessCSVProps) {
    return (
      successForStudent?.reduce((successData: CSVType[], success) => {
        const coursesMatching = courses.filter((course) => success.courseIds.includes(course.id));
        const courseLabels = coursesMatching.map((course) => mapping.parse('courseLabel', course));

        const currentCSVData: CSVType[] = courseLabels.flatMap((courseLabel) => {
          const activities = success.activities.join(', ');
          return {
            course: courseLabel,
            activityName: activities,
            trackName: mapping.parse(
              'trackName',
              tracks.find((t) => t.id === success.track),
            ),
            studentId,
            completeOnActivity: 'Yes',
            outcome: 'Pass',
            reason: '',
            conflicting: '',
            linkToStudent,
          };
        });
        return [...successData, ...currentCSVData];
      }, []) ?? []
    );
  }

  type GenerateFailedCSVProps = {
    failedForStudent: TrackListWithReason[string];
    successForStudent: TrackListWithTrack[string];
    studentId: number;
    linkToStudent: string;
  };
  function generateFailedCSVType({
    failedForStudent,
    successForStudent,
    linkToStudent,
    studentId,
  }: GenerateFailedCSVProps): CSVType[] {
    const presentCategory = createPresentCategory(mapping);
    const presentRelation = createPresentRelation(mapping);

    return (
      failedForStudent?.reduce((failedData: CSVType[], failed) => {
        const coursesMatching = courses.filter((course) => failed.courseIds.includes(course.id));
        const courseLabels = coursesMatching.map((course) => mapping.parse('courseLabel', course));
        const activities = failed.activities.join(', ');

        // Allocation to the activity can still be success even if this instance was a fail
        const successOnActivity = calculateSuccessOnActivity(failed, successForStudent);

        const failedReasons = failed.reasons;
        if (isDefined(failedReasons)) {
          const { failsWithMessage = {}, failsWithTracks = {} } = failedReasons;

          const currentFailedData: CSVType[] = courseLabels.flatMap((courseLabel) => {
            const failsWithTracksData: CSVType[] = Object.entries(failsWithTracks).flatMap(([key, value]) => {
              return value.map((trackId) => {
                const inputKey = key as keyof FailsWithTracks;
                const incompleteData: CSVType = {
                  course: courseLabel,
                  activityName: activities,
                  trackName: mapping.parse(
                    'trackName',
                    tracks.find((t) => t.id === trackId),
                  ),
                  studentId,
                  completeOnActivity: successOnActivity,
                  outcome: 'Fail',
                  reason: '',
                  conflicting: '',
                  linkToStudent,
                };

                switch (inputKey) {
                  case 'doubleBooked': {
                    const csv: CSVType = { ...incompleteData, outcome: 'Pass', reason: 'Already Allocated' };
                    return csv;
                  }
                  case 'buffer': {
                    const csv: CSVType = { ...incompleteData, reason: 'Buffer' };
                    return csv;
                  }
                  case 'conflict': {
                    // We could get a conflict that is not relevant to our selection and the populated data
                    // We filter those conflicts out
                    const relevantConflicts =
                      conflicts[trackId]
                        ?.map((conflict) =>
                          mapping.parse(
                            'trackName',
                            tracks.find((t) => t.id === conflict),
                          ),
                        )
                        .filter((f) => f !== '')
                        .join(' ') ?? '';
                    const csv: CSVType = { ...incompleteData, reason: 'Conflict', conflicting: relevantConflicts };
                    return csv;
                  }
                  case 'dedicated': {
                    const dedicated = mapping.parse('dedicatedTrack', tracks[trackId]);

                    let conflicting = '';
                    if (dedicated.kind === 'category') {
                      conflicting = presentCategory(dedicated);
                    }
                    if (dedicated.kind === 'relation') {
                      conflicting = presentRelation(dedicated, searchedObjects);
                    }
                    const csv: CSVType = { ...incompleteData, reason: 'Dedication', conflicting };

                    return csv;
                  }
                  case 'size': {
                    const csv: CSVType = { ...incompleteData, reason: 'No seats left' };
                    return csv;
                  }
                  case 'hidden': {
                    const csv: CSVType = { ...incompleteData, reason: 'Hidden' };
                    return csv;
                  }
                  default:
                    return exhaustiveMatchingGuard(inputKey as never);
                }
              });
            });

            const failsWithMessageData: CSVType[] = Object.entries(failsWithMessage).reduce(
              (failsWithMessageData, [reason, reasonWithMessage]) => {
                const messageFail: CSVType[] = Object.entries(reasonWithMessage).map(([trackId, systemError]) => {
                  return {
                    course: courseLabel,
                    activityName: activities,
                    trackName: mapping.parse(
                      'trackName',
                      tracks.find((t) => t.id.toString() === trackId),
                    ),
                    studentId,
                    completeOnActivity: successOnActivity,
                    outcome: 'Fail',
                    reason: `Error (${systemError?.code}) ${reason}: ${systemError?.message}`,
                    conflicting: '',
                    linkToStudent,
                  };
                });

                return [...failsWithMessageData, ...messageFail];
              },
              failsWithTracksData,
            );

            return failsWithMessageData;
          });
          return [...failedData, ...currentFailedData];
        }
        return failedData;
      }, []) ?? []
    );
  }

  function calculateSuccessOnActivity(
    failed: NonNullable<TrackListWithReason[string]>[number],
    successForStudent: TrackListWithTrack[string],
  ) {
    const hasDoubleBooked = failed.reasons?.failsWithTracks?.doubleBooked;

    // If we have a double booked issue, we are already allocated and should set success
    let successOnActivity: 'Yes' | 'No' = isDefined(hasDoubleBooked) ? 'Yes' : 'No';

    if (successOnActivity === 'No' && isDefined(successForStudent)) {
      // Check if we got a success on the same activity.
      const matching = getEntries({
        activities: failed.activities,
        courseIds: failed.courseIds.map((c) => c.toString()),
      }).every(([key, failed]) => {
        const match = successForStudent.find((s) => {
          const success = s[key];
          if (isDefined(success)) {
            const toStringSuccess = success.map((s) => s.toString());
            return failed.every((f: string) => {
              return toStringSuccess.includes(f);
            });
          }
          return false;
        });
        return isDefined(match);
      });

      successOnActivity = matching ? 'Yes' : 'No';
    }
    return successOnActivity;
  }
}
