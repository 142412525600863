import React, { useEffect, useCallback, useRef } from 'react';
import intl from '../../../i18n/intl';
import { TStudentInCourse } from '@timeedit/preferences-and-dm-commons/lib/src';
import { Divider, Typography, notification } from 'antd';
import { configService } from '../../../services/config.service';
import { TFilterValueSet } from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import { isDefined } from '@timeedit/registration-shared';
import { StudentsAdjustmentFilter } from '../../components/Filter/StudentsAdjustmentFilter';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRelatedCourses, fetchStudentFields, fetchStudentPerCourse } from '../slices/fetch.slice';
import { TRootState } from '../../../index';
import { nameOfStateFilterSearchParam, useSearchParamsState } from '../../hooks/UseStateParamState';
import { useMapping } from '../../services/mapping';
import { setColumnKeys, setStudentAdjustmentToInitialState } from '../slices/studentAdjustment.slice';
import './StudentAdjustments.scss';
import { StudentAdjustmentTable } from '../../components/Table/StudentAdjustmentTable';
import { convertToString } from '../BulkAllocationPage/utils';

const language = intl.messages as Record<string, string>;

export interface TStudentsAdjustmentFilters extends TFilterValueSet {
  courseId: string | undefined;
  trackIds: string[] | undefined;
  trackLists: string[] | undefined;
  allocationAmount: { GREATER_THAN: number; LESS_THAN: number };
}

export const randomNumericPlaceHolderForTrackListsLength = 99999;
export const filterValueForIncompleteStudents: Partial<TStudentsAdjustmentFilters> = {
  allocationAmount: {
    LESS_THAN: randomNumericPlaceHolderForTrackListsLength,
  } as TStudentsAdjustmentFilters['allocationAmount'],
};

export type StudentAdjustmentDataSource = {
  inHowManyTrackListIsStudent: number;
  trackListsLength: number;
  percent: number;
  clashes: string;
  [key: string]: number | number[] | string | string[];
} & Pick<TStudentInCourse, 'id' | 'tracks' | 'trackLists'>;

export function StudentAdjustmentPage() {
  const dispatch = useDispatch();
  const mapping = useMapping();
  const relatedCourses = useSelector((state: TRootState) => state.allocation.courseObjects);
  const columnKeys = useSelector((state: TRootState) => state.studentAdjustment.columnKeys);

  const studentFields = useSelector((state: TRootState) => state.allocation.studentFields);
  const tableLoading = useSelector((state: TRootState) => state.studentAdjustment.studentAdjustmentTableLoading);
  const relatedCoursesLoading = useSelector((state: TRootState) => state.allocation.allocationObjectLoading);
  const studentPerCourseTableCurrentlyLoadingCourse = useSelector(
    (state: TRootState) => state.studentAdjustment.studentAdjustmentTableCurrentlyLoadingCourse,
  );
  const studentPerCourseTableCurrentlyLoadedCourse = useSelector(
    (state: TRootState) => state.studentAdjustment.studentAdjustmentTableCurrentlyLoadedCourse,
  );
  const pageFilters = useSelector((state: TRootState) => state.allocation.pageFilter);

  const studentType = mapping.getId('student');
  const [filters, setFilters] = useSearchParamsState<Partial<TStudentsAdjustmentFilters>>({
    searchParamName: nameOfStateFilterSearchParam,
    defaultValue: pageFilters.studentAdjustment,
    pageName: 'studentAdjustment',
  });
  const studentsPerCourse = useSelector(
    (state: TRootState) => state.studentAdjustment.loadedCourses[filters?.courseId ?? ''],
  );

  useEffect(() => {
    if (isDefined(studentType) && Object.keys(studentFields).length <= 0) {
      dispatch(fetchStudentFields(mapping));
    }
  }, [mapping, studentType, studentFields]);

  const mountedRef = useRef<boolean>(); // Used for detecting if a useEffect is called upon mounting or later upon user interaction

  const loadCourse = useCallback(
    async (selectedCourseId: number, doneByUserInteractionAfterInitialLoading?: boolean) => {
      if (
        typeof selectedCourseId === 'undefined' ||
        selectedCourseId === studentPerCourseTableCurrentlyLoadingCourse ||
        selectedCourseId === studentPerCourseTableCurrentlyLoadedCourse
      ) {
        return;
      }

      if (doneByUserInteractionAfterInitialLoading) {
        setFilters({ courseId: convertToString(selectedCourseId) });
      }

      try {
        dispatch(fetchStudentPerCourse(selectedCourseId));
      } catch (e) {
        notification.error({
          duration: 0,
          key: configService().NOTIFICATION_KEY,
          message: language.loadingOneCourseFailed,
          description: language.loadingOneCourseFailedDetails,
        });
        console.error('Error loading course', e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [studentPerCourseTableCurrentlyLoadingCourse, studentPerCourseTableCurrentlyLoadedCourse],
  );

  useEffect(() => {
    if (relatedCourses?.length === 0) {
      dispatch(fetchRelatedCourses());
    }
  }, [dispatch, relatedCourses]);

  useEffect(() => {
    const selectedCourseId: number | undefined = Number(filters?.courseId);
    if (selectedCourseId) {
      loadCourse(selectedCourseId, mountedRef.current);
    }
  }, [filters?.courseId, loadCourse]);

  // Fires once and makes sure we know that later-on (by reading current)
  useEffect(() => {
    dispatch(setStudentAdjustmentToInitialState());
    mountedRef.current = true;
  }, []);

  useEffect(() => {
    if (columnKeys.length === 0) {
      dispatch(setColumnKeys(mapping.includeFields(mapping.getId('student'))));
      return;
    }
    dispatch(setColumnKeys(columnKeys));
  }, [mapping, studentFields]);

  return (
    <div>
      <div>
        <Typography.Title level={3} className="title__text">
          {language.studentAdjustment}
        </Typography.Title>
        <Typography.Text type="secondary">{language.studentAdjustmentSubtitle}</Typography.Text>
      </div>
      <Divider className="content__divider" />

      <div>
        <StudentsAdjustmentFilter
          filterValuesInput={filters}
          trackListsLookup={studentsPerCourse?.trackListsLookup}
          trackLookup={studentsPerCourse?.trackLookup}
          setFilters={setFilters}
          courseOptionsLoading={relatedCoursesLoading}
          courseLoading={tableLoading}
          courseSelected={!!filters?.courseId}
        />
      </div>
      <div className="fullscreentable">
        <StudentAdjustmentTable filters={filters} />
      </div>
    </div>
  );
}
