import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SaveDiscardSections } from '../../components/Drawer/BulkAllocationDrawer';

export interface DrawerState {
  changesToDiscard: Record<SaveDiscardSections, boolean>;
  clicked: ClickedType;
}

type ClickedType = {
  courseId: number;
  track: string;
};

export const clickedInitialState: ClickedType = {
  courseId: -1,
  track: '',
};

const initialState: DrawerState = {
  changesToDiscard: {
    DedicatedTrackSectionDiscard: false,
    GeneralInfoSection: false,
    LinkedSection: false,
    MaxStudentsSection: false,
    Default: false,
  },
  clicked: clickedInitialState,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setChangesToDiscard(state, action: PayloadAction<{ section: SaveDiscardSections; discard: boolean }>) {
      const { section } = action.payload;
      const currentState = state.changesToDiscard[section];

      const newState = action.payload.discard;
      if (currentState !== newState) {
        return {
          ...state,
          changesToDiscard: { ...state.changesToDiscard, [section]: newState },
        };
      }
      return state;
    },
    resetChangesToDiscard(state) {
      const noChangesToDiscard = Object.values(state.changesToDiscard).every((value) => value === false);
      if (noChangesToDiscard) return state;
      return {
        ...state,
        changesToDiscard: {
          DedicatedTrackSectionDiscard: false,
          GeneralInfoSection: false,
          LinkedSection: false,
          MaxStudentsSection: false,
          Default: false,
        },
      };
    },
    setClicked(state, action: PayloadAction<ClickedType>) {
      const isSameState =
        state.clicked.courseId === action.payload.courseId && state.clicked.track === action.payload.track;
      if (isSameState) return state;

      return { ...state, clicked: action.payload };
    },
  },
});

export const { setChangesToDiscard, resetChangesToDiscard, setClicked } = drawerSlice.actions;
export default drawerSlice.reducer;
