import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  BAllocateDataSource,
  CourseDataSource,
  TrackListDataSource,
  TracksDataSource,
  findEnrolledStudents,
  isCourseDataSource,
  isTrackListDataSource,
  isTracksDataSource,
} from './utils';
import { useCreateOnCell } from './useCreateOnCell';
import {
  AllowRegistrationStatus,
  DedicatedTrack,
  isDefined,
  isNumber,
  isString,
  registrationPeriodRunning,
} from '@timeedit/registration-shared';
import { BranchesOutlined, SyncOutlined, WarningOutlined } from '@ant-design/icons';
import {
  Calc,
  PresentRooms,
  convertToString,
  presentReservationRanges,
  presentText,
  printSet,
  printSetBoolean,
} from '../../pages/BulkAllocationPage/utils';
import { Skeleton, Tag, Tooltip, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { useMapping } from '../../services/mapping';
import * as utils from '../../utils/text';
import intl, { getInlineString } from '../../../i18n/intl';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { MultiProgress, Spin } from '@timeedit/registration-components';
import { paths } from '../../../routes/routes';
import { Link } from 'react-router-dom';
import { filterValueForIncompleteStudents } from '../../pages/StudentAdjustmentsPage';
import { jsonToStateFilterUrlParam, nameOfStateFilterSearchParam } from '../../hooks/UseStateParamState';

const language = intl.messages as Record<string, string>;

export function useBulkAllocationTableColumns(): ColumnsType<BAllocateDataSource> {
  const mapping = useMapping();
  const onCell = useCreateOnCell();
  const onCellSpan = useCreateOnCell(2);
  const onCellHide = useCreateOnCell(1, true);
  const studentObjectState = useSelector((state: TRootState) => state.allocation.studentObjectState);
  const trackLists = useSelector((state: TRootState) => state.allocation.trackLists);
  const tracks = useSelector((state: TRootState) => state.allocation.trackObjects);
  const linkedTracks = useSelector((state: TRootState) => state.allocation.linkedTracks);
  const allReservations = useSelector((state: TRootState) => state.allocation.reservations);
  const allRooms = useSelector((state: TRootState) => state.allocation.roomObjects);
  const searchedObjects = useSelector((state: TRootState) => state.allocation.searchedObjects);
  const timezone = useSelector((state: TRootState) => state.allocation.timeZone);
  const presentCategory = utils.presentCategory(mapping);
  const presentRelation = utils.presentRelation(mapping);

  return useMemo(() => {
    return [
      {
        dataIndex: 'BulkAllocationTableColumn2',
        onCell: onCellSpan,
        render(_, record) {
          if (isCourseDataSource(record)) {
            const code = `${record[mapping.getKey(utils.CourseFirstField)]}`;
            const name = `${record[mapping.getKey(utils.CourseSecondField)]}`;
            return (
              <span data-course-id={record.id}>
                <span className="course__text--primary">{code}</span> {name}
              </span>
            );
          }
          if (isTrackListDataSource(record)) {
            if (record.loading) {
              return <Skeleton.Input active />;
            }
            const activityType = record[mapping.getKey('activityType')];
            const activityTypeString = isString(activityType) ? activityType : '';

            return (
              <div data-tracklist-id={record.id}>
                <span className="track__text--activity">{activityTypeString}</span>
                <span className="track__text--count">x{record.children?.length}</span>
              </div>
            );
          }
          if (isTracksDataSource(record)) {
            const singleChildMatchingTrackList = trackLists.find(
              (tL) => tL.allocationObjects.length === 1 && tL.allocationObjects[0].id.toString() === record.key,
            );
            if (isDefined(singleChildMatchingTrackList)) {
              const activityType = record[mapping.getKey('activityType')];
              const activityTypeString = isString(activityType) ? activityType : '';

              return (
                <span data-track-id={record.id} className="track__text--activity--single">
                  {activityTypeString}
                </span>
              );
            }
            return columnTrackRender(record);
          }

          return '-';

          function columnTrackRender(record: TracksDataSource) {
            return (
              <span data-track-id={record.id} className="track__indent">
                <BranchesOutlined className="track__icon" />
                <span className="track__text">{`${record[mapping.getKey('trackNumber')]}`}</span>
              </span>
            );
          }
        },
      },
      {
        dataIndex: 'BulkAllocationTableColumn3',
        onCell,
        render: (_, record) => {
          if (isCourseDataSource(record)) return incompleteAllocations(record);
          if (isTrackListDataSource(record)) return '';
          if (isTracksDataSource(record)) return reservationWeeks(record, timezone);
          return '';
        },
      },

      {
        dataIndex: 'BulkAllocationTableColumn5',
        onCell,
        render: (_, record) => {
          if (isCourseDataSource(record)) return enrolledVsPlanned(record);
          if (isTrackListDataSource(record)) return '';
          if (isTracksDataSource(record)) return columnTrackRender(record);
          return '';

          function columnTrackRender(record: TracksDataSource) {
            return <div>{calcRoomsizes(record)}</div>;
          }
        },
      },
      {
        dataIndex: 'BulkAllocationTableColumn4',
        onCell,
        render: (_, record) => {
          if (isCourseDataSource(record)) return '';
          if (isTrackListDataSource(record)) return '';
          if (isTracksDataSource(record)) return columnTrackRender(record);
          return '';

          function columnTrackRender(record: TracksDataSource) {
            return (
              <div>
                {hideTracks(record)}
                {dedicatedTracks(record)}
              </div>
            );
          }
        },
      },
      {
        dataIndex: 'BulkAllocationTableColumn6',
        onCell,
        render: (_, record) => {
          if (isCourseDataSource(record)) return '';
          if (isTrackListDataSource(record)) return '';
          if (isTracksDataSource(record)) {
            const { id } = record;
            const currentLinkedTracks = linkedTracks[id] ?? [];
            if (currentLinkedTracks.length > 0) {
              const texts = tracks
                .filter((track) => currentLinkedTracks.includes(track.id))
                .map((track) => `${mapping.parse('activityType', track)} ${mapping.parse('trackNumber', track)}`)
                .join(', ');
              return presentTexts('Linked', texts);
            }
          }
          return '';
        },
      },
      {
        dataIndex: 'BulkAllocationTableColumn7',
        onCell,
        render: (_, record) => {
          if (isCourseDataSource(record)) return '';

          if (isTrackListDataSource(record)) {
            if (record.loading) {
              return <Skeleton.Input active />;
            }
            return columnTrackAndTrackListRender(record);
          }

          if (isTracksDataSource(record)) {
            if (record.loading) {
              return <Skeleton.Input active />;
            }

            return columnTrackAndTrackListRender(record);
          }
          return '';

          function columnTrackAndTrackListRender(record: TracksDataSource | TrackListDataSource) {
            const { allocationObjects, courseId } = record;
            const calc = new Calc(mapping, allocationObjects);
            const studentsLoadedForCourse = studentObjectState.coursesWithStudentsLoaded.includes(Number(courseId));

            if (!studentsLoadedForCourse) return null;

            return (
              <MultiProgress
                value={calc.spotsTaken(studentObjectState.studentObjects)}
                end={calc.buffer()}
                max={calc.maxStudents()}
                compact
                explainValue={language.allocated}
                explainEnd={mapping.fieldname('buffer')}
                formatHelp={() => calc.presentBuffer()}
              />
            );
          }
        },
      },
      {
        dataIndex: 'BulkAllocationTableColumn8',
        onCell: onCellHide,
        render: (_, record) => {
          if (isTrackListDataSource(record)) return '';
          if (isTracksDataSource(record)) return columnTrackRender(record);
          return '';

          function columnTrackRender(record: TracksDataSource) {
            const value = record[mapping.getKey('allowRegistration')];
            const allowRegistration = isString(value) ? value : '';

            const icon = autoIcon(record);
            return (
              <div>
                <Tooltip title={language.registrationPeriod}>
                  <div>{statusColumn(allowRegistration, record)}</div> {icon}
                </Tooltip>
              </div>
            );
          }
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentObjectState, trackLists, allReservations, allRooms, mapping, linkedTracks, timezone]);

  function reservationWeeks(record: TracksDataSource, timezone: string) {
    const { allocationObjects } = record;
    if (allReservations.length === 0) return '';
    const { count } = presentReservationRanges(
      allocationObjects.map((t) => t.id),
      allReservations,
      timezone,
    );
    if (count.size === 0) {
      return '-';
    }
    return (
      <Tooltip
        overlayStyle={{ whiteSpace: 'pre-line' }}
        title={`${language.schedule}\n ${count.size} ${language.reservations}`}
      >
        {count.size} <span className="column__text">{language.reservations}</span>
      </Tooltip>
    );
  }

  function dedicatedTracks(record: TracksDataSource) {
    const { allocationObjects } = record;
    if (allReservations.length === 0) return '';
    const dedicatedTracks: DedicatedTrack[] = [];
    allocationObjects.forEach((track) => dedicatedTracks.push(mapping.parse('dedicatedTrack', track)));
    if (dedicatedTracks.length === 0) {
      return '-';
    }
    const texts = dedicatedTracks
      .map((dedicated) => {
        if (dedicated.kind === 'category') {
          return presentCategory(dedicated);
        }
        if (dedicated.kind === 'relation') {
          return presentRelation(dedicated, searchedObjects);
        }
        return '';
      })
      .join(', ');
    return presentTexts(mapping.fieldname('dedicatedTrack'), texts);
  }

  function presentTexts(name: string, texts: string) {
    return (
      <Tooltip placement="topLeft" overlayStyle={{ whiteSpace: 'pre-line' }} title={`${name}\n ${texts}`}>
        {!isEmpty(texts) && (
          <div>
            <div className="column__text">{name}</div> {presentText(texts)}
          </div>
        )}
      </Tooltip>
    );
  }

  function hideTracks(record: TracksDataSource) {
    const { allocationObjects } = record;
    if (allReservations.length === 0) return '';
    const hidden = new Set<boolean>();
    allocationObjects.forEach((track) => hidden.add(mapping.parse('hideTrack', track)));
    if (hidden.size === 0) {
      return '-';
    }
    if (hidden.size === 1 && hidden.has(false)) {
      return '';
    }
    const name = mapping.fieldname('hideTrack');
    return (
      <Tooltip title={name}>
        <Tag>{printSetBoolean(hidden, name)}</Tag>
      </Tooltip>
    );
  }

  function calcRoomsizes(record: TrackListDataSource | TracksDataSource) {
    if (mapping.isMapped('room')) {
      return '';
    }
    const { allocationObjects } = record;
    if (!isDefined(allocationObjects)) return '';
    if (allReservations.length === 0) return '';
    if (allRooms.length === 0) return '';
    const trackIds = allocationObjects.map((t) => t.id);
    const { roomNames, text } = new PresentRooms('room', mapping, allRooms).present(trackIds, allReservations);
    return (
      <span>
        <Tooltip title={mapping.typename('room')}>{printSet(roomNames)}</Tooltip> {printSet(text)}
      </span>
    );
  }

  function autoIcon(record: TrackListDataSource | TracksDataSource) {
    const autoName = mapping.fieldname('autoAllocation');
    const ok = (
      <Tag className="status__tag--info">
        <SyncOutlined /> {autoName}
      </Tag>
    );
    const partial = (
      <Tag className="status__tag--info">
        <SyncOutlined /> {autoName} ${language.partial}
      </Tag>
    );
    const autoIconTracks = (record: TracksDataSource) => {
      const on = record[mapping.getKey('autoAllocation')];
      if (isDefined(on) && on.toString() === '1') {
        return ok;
      }
      return fail;
    };

    const fail = '';
    if (isTracksDataSource(record)) {
      return autoIconTracks(record);
    }

    if (record.children?.every((child) => child[mapping.getKey('autoAllocation')] === '1')) {
      return ok;
    }
    if (record.children?.some((child) => child[mapping.getKey('autoAllocation')] === '1')) {
      return partial;
    }
    return fail;
  }

  function statusColumn(allowRegistration: string, record: BAllocateDataSource) {
    if (allowRegistration === AllowRegistrationStatus.enum.FORCE_CLOSE) return <Tag>{language.closed}</Tag>;
    if (allowRegistration === AllowRegistrationStatus.enum.FORCE_OPEN)
      return <Tag className="status__tag--success">{language.openForRegistration}</Tag>;
    const start = record[mapping.getKey('startDate')];
    const end = record[mapping.getKey('endDate')];

    if (isString(start) && isString(end) && registrationPeriodRunning(start, end)) {
      return (
        <div>
          <Tag className="status__tag--success">{language.openForRegistration}</Tag>
          <div>
            {start} - {end}
          </div>
        </div>
      );
    }
    return (
      <div>
        <Tag>{language.closed}</Tag>
        <div>
          {convertToString(start)} - {convertToString(end)}
        </div>
      </div>
    );
  }

  function enrolledVsPlanned(record: CourseDataSource) {
    const courseId = Number(record.id);
    const studentsLoadedForCourse = studentObjectState.coursesWithStudentsLoaded.includes(courseId);

    let enrolled: number | undefined;

    if (studentsLoadedForCourse) {
      enrolled = findEnrolledStudents({
        courseIds: [Number(record.id)],
        students: studentObjectState.studentObjects,
      }).length;
    }

    const planned = mapping.parse('courseSeats', record[mapping.getKey('courseSeats')]);
    return (
      <Typography.Text>
        {language.enrolledStudents} / {mapping.fieldname('expectedEnrollment')} &nbsp;
        <Typography.Text strong>
          <Tooltip title={language.enrolledStudents}>{enrolled ?? '-'}</Tooltip>/
          <Tooltip title={mapping.fieldname('expectedEnrollment')}>{planned}</Tooltip>
          {isDefined(enrolled) && enrolled > planned && (
            <Tooltip
              placement="top"
              title={getInlineString(
                'overenrolled',
                language.enrolledStudents,
                mapping.fieldname('expectedEnrollment'),
              )}
            >
              {' '}
              <WarningOutlined className="red_icon--warning" />
            </Tooltip>
          )}
        </Typography.Text>
        <Link
          style={{ marginLeft: '10px' }}
          to={`${paths.studentAdjustment}?${nameOfStateFilterSearchParam}=${jsonToStateFilterUrlParam({
            courseId: record.id,
          })}`}
        >
          {language.viewStudents}
        </Link>
      </Typography.Text>
    );
  }

  function incompleteAllocations(record: CourseDataSource) {
    const courseId = Number(record.id);
    const studentsLoadedForCourse = studentObjectState.coursesWithStudentsLoaded.includes(courseId);

    if (!studentsLoadedForCourse) {
      return '';
    }

    const enrolledStudentIds = findEnrolledStudents({
      courseIds: [Number(record.id)],
      students: studentObjectState.studentObjects,
    });

    if (record.children.length === 0 || !isTrackListDataSource(record.children[0])) {
      return '';
    }

    const allocatedStudents = record.children.reduce((allocatedStudents: number[], trackList) => {
      const trackListStudents = trackLists.find((tL) => tL.key === trackList.key)?.students;

      if (!isDefined(trackListStudents)) return allocatedStudents;

      return [...new Set([...allocatedStudents, ...trackListStudents])];
    }, []);

    const completeAllocations = enrolledStudentIds.filter(
      (studentId) =>
        allocatedStudents.includes(studentId) &&
        trackLists
          .filter((tL) => tL.courseId === parseInt(record.id, 10))
          .every((tL) => tL.students.includes(studentId)),
    );

    const incompleteAllocations = enrolledStudentIds.length - completeAllocations.length;

    return (
      <div>
        {incompleteAllocations > 0 && incompleteAllocations !== enrolledStudentIds.length && (
          <div>
            {language.incomplete} &nbsp;
            <Tooltip placement="topLeft" title={language.incompleteAllocations}>
              <Typography.Text type="danger">{incompleteAllocations}</Typography.Text>
            </Tooltip>
            <Link
              style={{ marginLeft: '10px' }}
              to={`${paths.studentAdjustment}?${nameOfStateFilterSearchParam}=${jsonToStateFilterUrlParam({
                courseId: record.id,
                ...filterValueForIncompleteStudents,
              })}`}
            >
              {language.viewStudents}
            </Link>
          </div>
        )}
      </div>
    );
  }
}
