import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Divider,
  ErrorInfo,
  OnAllocate,
  OnDeallocate,
  RegistrationMain,
  Spin,
} from '@timeedit/registration-components';
import {
  createInitialDateIntervals,
  createInitialAllowedDateIntervals,
  getDateIntervals,
  calculateSignalSummary,
  isDefined,
  AddStudentsToTracksOverrides,
  DedicatedCategory,
  LoadBody,
  DedicatedRelation,
} from '@timeedit/registration-shared';
import { useMapping } from '../../services/mapping';
import { useNavigate, useParams } from 'react-router-dom';
import { registrationAllocate, deallocate, fetchRegistration } from '../slices/fetch.slice';
import { initialRegistration } from '../slices/registration.slice';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { Modal, Skeleton, Typography, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ConflictControlStrategy } from '../../services/registration-allocation.service';
import { setAllocationFailedGroups } from '../slices/allocation.slice';
import './RegistrationPage.scss';
import { findErrorKeysWithMessage } from './utils';
import intl from '../../../i18n/intl';
import { ManageStudentHeader } from '../../components/ManageStudent/ManageStudentHeader';
import { configService } from '../../../services/config.service';
import { presentCategory, presentRelation } from '../../utils/text';
import { loadObjectBy } from '../BulkAllocationPage';
import { paths } from '../../../routes/routes';
import { getAccessTokenDecoded } from '../../../services/token.service';
import { ERegion } from '@timeedit/types/lib/enums/region.enum';

const language = intl.messages as Record<string, string>;

type HandleAllocateProps = {
  overrides?: AddStudentsToTracksOverrides;
  conflictControlStrategy?: ConflictControlStrategy;
} & OnAllocate;

export function RegistrationPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const failed = useSelector((state: TRootState) => state.allocation.allocationFailedGroups);
  const studentObjects = useSelector((state: TRootState) => state.allocation.studentObjectState.studentObjects);
  const searchedObjects = useSelector((state: TRootState) => state.allocation.searchedObjects);
  const changeRequests = useSelector((state: TRootState) => state.issueList.changeRequests);
  const previousAllocateRequestBody = useRef<HandleAllocateProps>();

  const errorKeyAndReason = useMemo(() => findErrorKeysWithMessage(failed), [failed]);

  const openOverrideRuleModal = useMemo(() => isDefined(failed) && Object.keys(failed).length > 0, [failed]);
  useEffect(() => {
    dispatch(setAllocationFailedGroups({}));

    return () => {
      dispatch(setAllocationFailedGroups({}));
    };
  }, [dispatch]);

  const studentId = useMemo(() => {
    if (isDefined(params.studentId)) {
      return parseInt(params.studentId, 10);
    }
    return undefined;
  }, [params.studentId]);

  const registration = useSelector((state: TRootState) => {
    if (isDefined(studentId)) return state.registration?.[studentId] ?? initialRegistration;
    return initialRegistration;
  });

  const mapping = useMapping();
  const navigate = useNavigate();

  const initialIntervals = {
    dateInterval: createInitialDateIntervals(),
    allowedDateInterval: createInitialAllowedDateIntervals(),
  };

  const fetch = useCallback(() => {
    if (isDefined(studentId)) {
      dispatch(fetchRegistration(studentId, mapping));
    }
  }, [studentId, dispatch, mapping]);

  useEffect(() => {
    if (isDefined(studentId) && registration.status === 'initial') {
      fetch();
    }
  }, [fetch, registration.status, studentId]);

  const student = useMemo(() => {
    if (isDefined(studentId)) {
      return studentObjects.find((s) => s.id === studentId);
    }
    return undefined;
  }, [studentObjects, studentId]);

  const handlePresentCategory = useCallback(
    (data: DedicatedCategory[]) => {
      return presentCategory(mapping)({ kind: 'category', data });
    },
    [mapping],
  );

  const handlePresentRelation = useCallback(
    (data: DedicatedRelation[]) => {
      return presentRelation(mapping)({ kind: 'relation', data }, searchedObjects);
    },
    [mapping, searchedObjects],
  );

  const handleAllocate = useCallback(
    (body: HandleAllocateProps) => {
      if (isDefined(studentId)) {
        previousAllocateRequestBody.current = body;

        dispatch(
          registrationAllocate({
            ...body,
            conflictControlStrategy: body.conflictControlStrategy ?? 'partial',
            studentId,
            mapping,
            reloadReservations: false,
          }),
        );
      } else {
        notification.error({
          duration: 0,
          key: configService().NOTIFICATION_KEY,
          message: 'Allocation failed',
          description: `Details: \n studentId was not found`,
        });
        console.warn('#handleAllocate studentId was expected to be present but was undefined.');
      }
    },
    [dispatch, mapping, studentId],
  );

  const handleDeallocate = useCallback(
    (body: OnDeallocate) => {
      if (isDefined(studentId)) {
        dispatch(deallocate({ mapping, studentId, trackIds: body.objectIds, reloadReservations: false }));
      } else {
        notification.error({
          duration: 0,
          key: configService().NOTIFICATION_KEY,
          message: 'Deallocation failed',
          description: `Details: \n studentId was not found`,
        });
        console.warn('#handleDeallocate studentId was expected to be present but was undefined.');
      }
    },
    [dispatch, mapping, studentId],
  );

  const onModalOk = useCallback(() => {
    const body = previousAllocateRequestBody.current;
    if (!isDefined(body)) return;

    if (errorKeyAndReason.key === 'conflict') {
      body.conflictControlStrategy = 'none';
    } else if (errorKeyAndReason.type === 'failsWithTracks') {
      body.overrides = { ...body?.overrides, [errorKeyAndReason.key]: true };
    }

    handleAllocate(body);
  }, [errorKeyAndReason, handleAllocate, previousAllocateRequestBody]);

  if (['initial', 'loading'].includes(registration.status)) {
    return <Spin contained />;
  }

  if (registration.status === 'error') {
    const region = getAccessTokenDecoded()?.region;

    return (
      <ErrorInfo
        region={region ?? ERegion.EU_EES}
        translations={{ retry: 'Retry' }}
        error={registration.error}
        recover={fetch}
      />
    );
  }

  const { allowedDateInterval, dateInterval } = getDateIntervals(registration.registration, initialIntervals);

  const signal = {
    summary: calculateSignalSummary({ registration: registration.registration }),
  };

  const loadActions = {
    loadTypes: () => Promise.resolve(mapping.store.types ?? []),
    loadFields: () => Promise.resolve(mapping.store.fields ?? []),
    loadObjects: (body: LoadBody) => loadObjectBy(body),
  };

  return (
    <div className="registration-page">
      {isDefined(student) ? (
        <>
          <ManageStudentHeader
            handleGoBack={() => {
              navigate(paths.studentAdjustment);
            }}
            progress={{
              allocated: signal.summary.OK,
              total: signal.summary.OK + signal.summary.ISSUE + signal.summary.PENDING,
            }}
            student={student}
          />
          <Divider className="manage-student__divider" />
        </>
      ) : (
        <Skeleton active />
      )}
      <RegistrationMain
        mode="teacher"
        loadActions={loadActions}
        navigate={navigate}
        params={params}
        studentId={studentId}
        mappingData={mapping.store.mappingData}
        registration={registration.registration}
        allowedDateInterval={allowedDateInterval}
        dateInterval={dateInterval}
        signal={signal}
        allocationControlActions={{
          onAllocate: handleAllocate,
          onDeallocate: handleDeallocate,
          isLoading: registration.status === 'fetching',
        }}
        dedicatedTracksActions={{
          presentCategory: handlePresentCategory,
          presentRelation: handlePresentRelation,
        }}
        changeRequests={changeRequests}
      />
      <Modal
        title={
          errorKeyAndReason.type === 'failsWithMessage' ? language.registerFailPopup : `${language.registerPopup}?`
        }
        mask={false}
        zIndex={10000}
        open={openOverrideRuleModal}
        onCancel={() => {
          dispatch(setAllocationFailedGroups({}));
        }}
        onOk={onModalOk}
        okText={language.register}
        okButtonProps={{
          disabled: errorKeyAndReason.key === 'size',
          style:
            registration.registration.tracks[previousAllocateRequestBody.current?.newObjectId ?? 0]
              ?.allocationStatus === 'ALLOCATED_TO_THIS' || errorKeyAndReason.type === 'failsWithMessage'
              ? { display: 'none' }
              : undefined,
        }}
      >
        <div>
          <ExclamationCircleOutlined className="allocate-fail__exclamation-icon" />
          {<Typography.Text>{errorKeyAndReason.reason}</Typography.Text>}
        </div>
      </Modal>
    </div>
  );
}
