import { Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CreateNewImportTemplateModalContext, {
  ICreateNewImportTemplateModalContext,
  TSelectedObjects,
  TTemplateSettings,
  initialValue,
  TSelectedStaticObjects,
} from './CreateNewImportTemplateModal.context';

import { TABS } from './CreateNewImportTemplateModal.constants';
import CreateNewImportTemplateModalGeneralSettings from './CreateNewImportTemplateModalGeneralSettings';
import CreateNewImportTemplateModalObjects from './CreateNewImportTemplateModalObjects';
import CreateNewImportTemplateModalFields from './CreateNewImportTemplateModalFields';
import CreateNewImportTemplateModalPreview from './CreateNewImportTemplateModalPreview';
import CreateNewImportTemplateModalFooter from './CreateNewImportTemplateModalFooter';
import CreateNewImportTemplateModalNav from './CreateNewImportTemplateModalNav';

import './CreateNewImportTemplateModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchReservationFields,
  reservationModesSelector,
  reservationObjectTypesSelector,
} from 'slices/integration.slice';
import { isEmpty, pick } from 'lodash';
import { userSelector } from 'slices/auth.slice';
import { createActivityImportTemplate, downloadActivityImportTemplate } from 'activities/services/activities.service';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import { TImportTemplate, TTemplateReservationField } from 'activities/pages/types/importTemplate.type';
import { reservationFieldsForTemplateSelector } from 'activities/pages/slices/activity.slice';
import CreateNewImportTemplateModalQuickSummary from './CreateNewImportTemplateModalQuickSummary';
import intl from 'i18n/intl';
import TEObjectManager from 'activities/services/TEObjects.service';
import { TType } from '@timeedit/types/lib/types';

const language = intl.messages;

interface Props {
  open?: boolean;
}
export default function CreateNewImportTemplateModal(props: Props) {
  const dispatch = useDispatch();
  const [modal, modalContextHolder] = Modal.useModal();

  const [open, setOpen] = useState(props.open);
  const [tab, setTab] = useState<(typeof TABS)[number]['key']>(TABS[0].key);

  const [settings, setSettings] = useState<TTemplateSettings>(initialValue.settings);
  const [staticObjects, setStaticObjects] = useState<TSelectedStaticObjects>({});
  // Dynamic objects
  const [objects, setObjects] = useState<TSelectedObjects>({});
  const [fields, setFields] = useState<TTemplateReservationField[]>(initialValue.fields);
  const [informationFields, setInformationFields] = useState(initialValue.informationFields);
  const [creating, setCreating] = useState(false);

  const reservationModes = useSelector(reservationModesSelector());
  const reservationFields = useSelector(reservationFieldsForTemplateSelector(settings.reservationMode));
  const user = useSelector(userSelector);
  const reservationObjectTypes = useSelector(reservationObjectTypesSelector(settings.reservationMode));

  const allObjectTypes = useMemo((): ICreateNewImportTemplateModalContext['allObjectTypes'] => {
    return pick(TEObjectManager.objectTypes, reservationObjectTypes);
  }, [settings.reservationMode, reservationObjectTypes]);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onReset = () => {
    setTab(TABS[0].key);
    setStaticObjects({});
    setObjects({});
    setInformationFields(initialValue.informationFields);
    setFields(initialValue.fields);
    setSettings(initialValue.settings);
  };

  useEffect(() => {
    if (!open) {
      onReset();
    } else if (settings.reservationMode) {
      dispatch(fetchReservationFields(settings.reservationMode));
    }
  }, [open, settings.reservationMode]);

  useEffect(() => {
    if (open && isEmpty(objects) && !isEmpty(allObjectTypes)) {
      const initialObjects: TSelectedObjects = {};
      Object.entries(allObjectTypes).forEach(([extId, objectType]) => {
        initialObjects[extId] = {
          selected: true,
          active: true,
          fields: [],
          label: objectType.name ?? objectType.extId,
          extId,
        };
      });

      setObjects(initialObjects);
    }
  }, [allObjectTypes, objects, open]);

  useEffect(() => {
    if (reservationFields?.length) {
      setFields(reservationFields);
    }
  }, [reservationFields?.length, settings.reservationMode]);

  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_CREATE_NEW_IMPORT_TEMPLATE_MODAL, onOpenModal);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_CREATE_NEW_IMPORT_TEMPLATE_MODAL, onOpenModal);
    };
  }, []);

  const activeTabIndex = useMemo(() => {
    return TABS.findIndex(({ key }) => key === tab);
  }, [tab]);

  const onNext = () => {
    setTab(TABS[activeTabIndex + 1]?.key || tab);
  };
  const onPrev = () => {
    setTab(TABS[activeTabIndex - 1]?.key || tab);
  };
  const onFinish = async () => {
    if (!user?.organizationId) return;

    const obj: TImportTemplate = {
      organizationId: user?.organizationId,
      name: settings.name,
      _typeTag: 'InexactTime',
      fields,
      primaryObject: {
        extId: settings.primaryObjectType,
        label: staticObjects.primaryObject?.label || settings.primaryObjectType,
        excludeInScheduling: false,
      },
      activityType: {
        extId: settings.activityObjectType,
        label: staticObjects.activityType?.label || settings.activityObjectType,
        excludeInScheduling: false,
      },
      reservationMode: settings.reservationMode,
      objects: [
        ...(staticObjects.additionalObjectOfPrimaryObjectType
          ? [staticObjects.additionalObjectOfPrimaryObjectType]
          : []),
        ...(staticObjects.additionalObjectOfActivityTypeType ? [staticObjects.additionalObjectOfActivityTypeType] : []),
        ...Object.values(objects),
      ]
        .filter((object) => object.selected && !object.fields?.length)
        .map((object) => {
          return {
            label: object.label,
            extId: object.extId,
            excludeInScheduling: false,
          };
        }),
      objectFilters: Object.values(objects)
        .filter((object) => object.selected && object.fields?.length)
        .map((object) => ({
          label: object.label,
          excludeInScheduling: false,
          typeExtId: object.extId,
          fieldExtId: object.fields,
        })),
      informationFields: informationFields.map((field) => ({
        ...field,
        label: field.label.trim(),
      })),
      timezone: {
        label: 'Timezone',
        exclude: true,
      },
      duration: {
        label: 'Duration',
      },
    };
    setCreating(true);

    const createTemplateResponse = await createActivityImportTemplate(obj);
    const { activityCreateTemplate } = createTemplateResponse;
    if (activityCreateTemplate) {
      await downloadActivityImportTemplate(
        activityCreateTemplate._id,
        activityCreateTemplate.name,
        settings.enableTracks,
      );
    }

    setCreating(false);
    setOpen(false);
  };

  const getSummary = useCallback(() => {
    const reservationModeDisplay = reservationModes.find((mode) => mode.extId === settings.reservationMode)?.name;
    return {
      reservationMode: reservationModeDisplay,
      primaryObject: staticObjects.primaryObject?.label,
      activityType: staticObjects.activityType?.label,
    };
  }, [
    reservationModes,
    staticObjects.primaryObject?.label,
    staticObjects.activityType?.label,
    settings.reservationMode,
  ]);

  const contextValue = useMemo(() => {
    return {
      allObjectTypes,
      tab,
      setTab,
      settings,
      setSettings,
      staticObjects,
      setStaticObjects,
      objects,
      setObjects,
      onClose: () => {
        modal.confirm({
          title: language['activities.create_template.confirm_leaving_with_unsaved_changes.title'] as string,
          content: language['activities.create_template.confirm_leaving_with_unsaved_changes.message'] as string,
          onOk: () => setOpen(false),
        });
      },
      onNext,
      onPrev,
      activeTabIndex,
      onFinish,
      fields,
      setFields,
      informationFields,
      setInformationFields,
      creating,
      getSummary,
    };
  }, [tab, setTab, activeTabIndex, onNext, onPrev, onFinish]);

  const modalMainContent = useMemo(() => {
    switch (tab) {
      case TABS[0].key:
        return <CreateNewImportTemplateModalGeneralSettings />;
      case TABS[1].key:
        return <CreateNewImportTemplateModalObjects />;
      case TABS[2].key:
        return <CreateNewImportTemplateModalFields />;
      case TABS[3].key:
        return <CreateNewImportTemplateModalPreview />;
      default:
        return null;
    }
  }, [tab]);

  return (
    <CreateNewImportTemplateModalContext.Provider value={contextValue}>
      <Modal
        maskClosable={false}
        open={open}
        title={language['activities.create_template.title'] as string}
        footer={<CreateNewImportTemplateModalFooter />}
        onCancel={() => contextValue.onClose()}
        width={1000}
        className="create-new-import-template-modal"
      >
        <CreateNewImportTemplateModalNav />
        <CreateNewImportTemplateModalQuickSummary />
        <div data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL">{modalMainContent}</div>
        {modalContextHolder}
      </Modal>
    </CreateNewImportTemplateModalContext.Provider>
  );
}
