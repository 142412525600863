import intl from '../../../i18n/intl';
import {
  EKindOfFilter,
  TBaseFilterOption,
  TFilterOptions,
} from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import { Mapping } from '../../services/mapping';
import { TField, TTEObject, isDefined } from '@timeedit/registration-shared';
import { EFieldType } from '@timeedit/types/lib/enums';
import { TTEObjectAdaptedToActualValues } from '@timeedit/preferences-and-dm-commons/lib/src/types';

const language = intl.messages as Record<string, string>;

export function presentCourse(object: TTEObject | TTEObjectAdaptedToActualValues, mapping: Mapping) {
  return `${mapping.parse('courseLabel', object)} ${mapping.parse('courseTitle', object)}`;
}

export const checkBoxOptions = [
  {
    value: '1',
    label: language.yes,
  },
  {
    value: '0',
    label: language.no,
  },
];

export const filterOptionLowerCaseInclude = (input: string, option?: { label: string; value: string }) => {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
};

export const getCourseCategoryValues = (
  fields: TField[],
  mapping: Mapping,
  settings: {
    fieldsToExclude?: number[];
    fieldsForNormalFilter?: number[] /* By default, everything goes to otherFilterOptionsToSet */;
  },
  callback: (params: { otherFilterOptionsToSet: TFilterOptions; normalFilterOptionsToSet: TFilterOptions }) => void,
) => {
  function options(field: TField) {
    if (isDefined(field.categories)) {
      return field.categories.map((category: string) => ({
        value: category,
        label: category,
      }));
    }
    if (field.fieldType === EFieldType.CHECKBOX) {
      return checkBoxOptions;
    }
    return [];
  }

  const mapped = mapping.searchableFields(mapping.getId('course'));
  const fieldsToShow = fields.filter((f) => mapped.includes(f.id));

  const other: TFilterOptions = {};
  const normal: TFilterOptions = {};
  for (const field of fieldsToShow) {
    if (field.fieldType !== EFieldType.CHECKBOX && field.fieldType !== EFieldType.CATEGORY) {
      continue;
    }
    if (settings.fieldsToExclude?.includes(field.id)) {
      continue;
    }
    const filterValue: TBaseFilterOption = {
      key: field.id.toString(),
      label: field.name,
      kindOfFilter: EKindOfFilter.DROPDOWN,
      allowMultiple: true,
      showSearch: true,
      options: options(field),
      debounce: true,
      allowClear: true,
      autoClearSearchValue: false,
      type: 'string',
      className: 'bulkdropdown',
    };

    if (settings.fieldsForNormalFilter?.includes(field.id)) {
      normal[field.id] = filterValue;
      continue;
    }

    other[field.id] = filterValue;
  }
  callback({ otherFilterOptionsToSet: other, normalFilterOptionsToSet: normal });
};
