import React from 'react';
import { createRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { getLdClientSideId } from 'utils/launchDarklyHelpers';

import reportWebVitals from './reportWebVitals';

// COMPONENTS
import App from './App';

// REDUX
import rootReducer from './slices';

// STYLES
import './index.css';
import { TUninitializedContext } from '@timeedit/types/lib/types/launchDarkly.type';

// CONFIGURE STORE
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
  },
});
export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const clientSideID = getLdClientSideId(process.env.REACT_APP_TE_APP_ENV);
const context: TUninitializedContext = {
  kind: 'uninitialized',
  key: 'uninitialized',
};
const LDProvider = await asyncWithLDProvider({
  clientSideID,
  // Skip Initializing context until later when we know which user is
  // logged in
  context,
  options: {
    application: { id: 'Data Manager front end' },
    streaming: true,
  },
});

root.render(
  <LDProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </LDProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
