import React, { MutableRefObject, useState } from 'react';
import { Button, Space, notification } from 'antd';
import { DiscardFunctions, SaveDiscardSections, SaveFunctions } from './BulkAllocationDrawer';
import intl from '../../../i18n/intl';
import { configService } from '../../../services/config.service';
import { isDefined } from '@timeedit/registration-shared';
import { reloadTrackAndReservations } from '../../pages/slices/fetch.slice';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { useMapping } from '../../services/mapping';
import { SaveDrawerModal } from './SaveDrawerModal';

const language = intl.messages as Record<string, string>;

type DrawerExtraButtonsProps = {
  saveFunctionsRef: MutableRefObject<SaveFunctions>;
  discardFunctionsRef: MutableRefObject<DiscardFunctions>;
};
export function DrawerExtraButtons({ saveFunctionsRef, discardFunctionsRef }: DrawerExtraButtonsProps) {
  const dispatch = useDispatch();
  const mapping = useMapping();
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);

  const [openSaveModal, setOpenSaveModal] = useState(false);
  const sensitiveSectionsChanged = () => {
    return saveFunctionsRef.current.find(({ id }) => id === 'MaxStudentsSection')?.id;
  };
  const [sensitiveSection, setSensitiveSection] = useState<SaveDiscardSections>('Default');

  const [loading, setLoading] = useState(false);
  return (
    <>
      <Space>
        <Button onClick={handleDiscard}>{language.discard_changes}</Button>
        <Button loading={loading} type="primary" onClick={() => handleSaveClick()}>
          {language.save}
        </Button>
      </Space>
      <SaveDrawerModal
        section={sensitiveSection}
        open={openSaveModal}
        onOk={() => {
          setOpenSaveModal(false);
          save();
        }}
        onCancel={() => setOpenSaveModal(false)}
      />
    </>
  );

  function handleDiscard() {
    discardFunctionsRef.current.forEach((discardFunction) => discardFunction.fn());
  }
  async function handleSaveClick() {
    const section = sensitiveSectionsChanged();
    if (isDefined(section)) {
      setOpenSaveModal(true);
      setSensitiveSection(section);
    } else {
      save();
    }
  }

  async function save() {
    setLoading(true);

    // Needs to be done in sequence because different save methods might update same things.
    // So we need to have the updated data for each function.
    const errors = [];
    for (const saveFunction of saveFunctionsRef.current) {
      try {
        if (isDefined(saveFunction.fn)) {
          // eslint-disable-next-line no-await-in-loop
          await saveFunction.fn();
        }
      } catch (error) {
        errors.push(error);
      }
    }

    setLoading(false);

    if (errors.length > 0) {
      console.error('Error saving', errors.join(', '));

      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: 'Saving Failed',
        description: ` Details:  ${errors.join(', ')}`.slice(0, 600),
      });
    } else {
      dispatch(
        reloadTrackAndReservations({ mapping, trackIds: trackObjects.map((t) => t.id), reloadReservations: false }),
      );
    }
  }
}
