import { DedicatedCategory, DedicatedRelation } from '@timeedit/registration-shared';
import React from 'react';
import { DedicationListProps } from './DedicationList';
import { Button, InputNumber, List, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useMapping } from '../../../services/mapping';
import { updateDedicationSeats } from './DedicationUtils';

type DedicationListItemProps = {
  displayItem: string;
  item: DedicatedCategory | DedicatedRelation;
  removeItem: (item: DedicatedCategory | DedicatedRelation) => void;
} & Pick<DedicationListProps, 'setDedicatedTrackString'>;

export function DedicationListItem({
  displayItem,
  item,
  setDedicatedTrackString,
  removeItem,
}: DedicationListItemProps) {
  const mapping = useMapping();
  return (
    <List.Item className="row-item">
      <div className="row-item__display-text">{displayItem}</div>
      <div className="row-item__seats-container">
        <InputNumber
          onChange={onChange}
          className="row-item__seats-number"
          controls={false}
          size="small"
          max={999}
          min={0}
          value={item.seats}
        />
        <Typography.Text className="row-item__seats-percentage">%</Typography.Text>
      </div>
      <Button icon={<DeleteOutlined />} type="text" onClick={() => removeItem(item)} className="row-item__delete-btn" />
    </List.Item>
  );
  function onChange(seatsNumber: number | null) {
    setDedicatedTrackString((prevDedicatedTrackString) => {
      const parsedDedicatedTrack = mapping.parse('dedicatedTrack', prevDedicatedTrackString);
      return updateDedicationSeats({ parsedDedicatedTrack, item, seatsNumber });
    });
  }
}
