import { useDispatch, useSelector } from 'react-redux';
import {
  activitiesPaginationSelector,
  activitiesTriggerSelector,
  activityFiltersSelector,
  fetchActivities,
} from '../slices/activity.slice';
import { useEffect } from 'react';

import wsClient from 'services/socket.service';
import { ActivityEvents } from '../types/activity.type';
import { useAppFeatureFlags } from '../../hooks/useAppFeatureFlags';

export const useActivitiesWatcher = () => {
  const dispatch = useDispatch();
  const pagination = useSelector(activitiesPaginationSelector);
  const trigger = useSelector(activitiesTriggerSelector);
  const filtersValue = useSelector(activityFiltersSelector);
  const { showDmStatusFilter } = useAppFeatureFlags();

  useEffect(() => {
    dispatch(fetchActivities(showDmStatusFilter));
  }, [pagination.page, pagination.perPage, filtersValue.status, dispatch, showDmStatusFilter]);

  useEffect(() => {
    if (trigger) {
      dispatch(fetchActivities(showDmStatusFilter));
    }
  }, [trigger, dispatch, showDmStatusFilter]);

  const onMessageReceived = (event: MessageEvent<any>) => {
    if (!event?.data.startsWith('{')) {
      return;
    }

    try {
      const { eventName } = JSON.parse(event.data) as any; // This assertion is save till we introduce more types of socket messages. Then, we need to adapt!
      if (eventName === 'DRAFT_ACTIVITIES_CHANGED') {
        document.dispatchEvent(new CustomEvent(ActivityEvents.REFETCH_ACTIVITIES_TABLE));
      }
    } catch (err) {
      console.log('Error when listenning socket io event: ', err);
    }
  };

  useEffect(() => {
    wsClient.instance?.addEventListener('message', onMessageReceived);

    return () => {
      wsClient.instance?.removeEventListener('message', onMessageReceived);
    };
  }, []);
};
