import { DedicatedCategory, DedicatedRelation, DedicatedTrack, isDefined } from '@timeedit/registration-shared';
import { useMemo } from 'react';
import { useMapping } from '../../services/mapping';

export function useDedicatedSectionInfo(dedicatedTrackString: string | undefined) {
  const mapping = useMapping();

  const parsedDedicatedTrack: DedicatedTrack = useMemo(
    () => mapping.parse('dedicatedTrack', dedicatedTrackString),
    [dedicatedTrackString, mapping],
  );
  const dedicatedPercentage = useMemo(() => {
    const dedicatedData: (DedicatedCategory | DedicatedRelation)[] | undefined = parsedDedicatedTrack.data;

    if (isDefined(dedicatedData)) {
      return dedicatedData.reduce<number>((seatsTaken: number, { seats }) => seatsTaken + seats, 0);
    }
    return 0;
  }, [parsedDedicatedTrack.data]);

  return { parsedDedicatedTrack, dedicatedPercentage };
}
