import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetChangeRequestReturn, Issues } from '@timeedit/preferences-and-dm-commons/lib/src';
import { TTEObject, ChangeRequest } from '@timeedit/registration-shared';

type ChangeRequestLoading = {
  objectsLoading: boolean;
  activeRequestsLoading: boolean;
};
type IssuesStatus = 'initial' | 'loading' | 'ready';
export interface IssueListState {
  issues: Issues;
  changeRequests: ChangeRequest[];
  issuesStatus: IssuesStatus;
  changeRequestLoading: ChangeRequestLoading;
  changeRequestObjects: TTEObject[];
  issuesCount: number;
}

const initialState: IssueListState = {
  issues: { noAllocation: { courseIds: [], trackIds: [] }, overEnrollment: { courseIds: [], trackIds: [] } },
  changeRequests: [],
  issuesStatus: 'initial',
  changeRequestLoading: { activeRequestsLoading: false, objectsLoading: false },
  changeRequestObjects: [],
  issuesCount: 0,
};

const issueListSlice = createSlice({
  name: 'issueList',
  initialState,
  reducers: {
    setIssues: (state, action: PayloadAction<Issues>) => {
      return {
        ...state,
        issues: action.payload,
      };
    },
    setIssuesStatus: (state, action: PayloadAction<IssuesStatus>) => {
      return {
        ...state,
        issuesStatus: action.payload,
      };
    },
    setActiveChangeRequests: (state, action: PayloadAction<GetChangeRequestReturn>) => {
      return {
        ...state,
        changeRequests: action.payload.changeRequests,
      };
    },
    updateChangeRequestsLoading: (state, action: PayloadAction<Partial<ChangeRequestLoading>>) => {
      return {
        ...state,
        changeRequestLoading: { ...state.changeRequestLoading, ...action.payload },
      };
    },
    setChangeRequestObjects: (state, action: PayloadAction<TTEObject[]>) => {
      return {
        ...state,
        changeRequestObjects: action.payload,
      };
    },
    setIssuesCount: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        issuesCount: action.payload,
      };
    },
  },
});

export const {
  setIssues,
  setIssuesStatus,
  setActiveChangeRequests,
  updateChangeRequestsLoading,
  setChangeRequestObjects,
  setIssuesCount,
} = issueListSlice.actions;
export default issueListSlice.reducer;
