import React from 'react';
import { DedicatedTrackSectionProps } from './DedicatedTrackSection';
import { useMapping } from '../../../services/mapping';
import { useSelector } from 'react-redux';
import { TRootState } from '../../../..';
import {
  DedicatedCategory,
  DedicatedRelation,
  DedicatedTrack,
  isDedicatedCategory,
  isDedicatedRelation,
  isDefined,
} from '@timeedit/registration-shared';
import { List } from 'antd';
import { DedicationListItem } from './DedicationListItem';
import { dedicatedTrackRemove } from './DedicationUtils';

export type DedicationListProps = {
  parsedDedicatedTrack: DedicatedTrack;
} & Pick<DedicatedTrackSectionProps, 'setDedicatedTrackString'>;

export function DedicationList({ parsedDedicatedTrack, setDedicatedTrackString }: DedicationListProps) {
  const searchedObjects = useSelector((state: TRootState) => state.allocation.searchedObjects);
  const mapping = useMapping();

  return (
    <List<DedicatedCategory | DedicatedRelation>
      className="dedicated-track-list"
      dataSource={parsedDedicatedTrack.data}
      renderItem={renderItem}
    />
  );
  function renderItem(item: DedicatedCategory | DedicatedRelation) {
    let displayItem: string = `no label found for item: ${item.id}`;

    if (isDedicatedRelation(item)) {
      const itemObject = searchedObjects.find((obj) => obj.id === item.id);
      if (isDefined(itemObject)) {
        const programName = mapping.string('programName', itemObject);
        displayItem = programName;
      }
    }
    if (isDedicatedCategory(item)) {
      displayItem = item.value;
    }

    return (
      <DedicationListItem
        removeItem={removeItem}
        displayItem={displayItem}
        item={item}
        setDedicatedTrackString={setDedicatedTrackString}
      />
    );
  }
  function removeItem(itemToRemove: DedicatedCategory | DedicatedRelation) {
    setDedicatedTrackString((prevDedicatedTrackString) => {
      return dedicatedTrackRemove({ itemToRemove, parsedDedicatedTrack, prevDedicatedTrackString });
    });
  }
}
