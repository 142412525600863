/* eslint-disable no-param-reassign */
// TODO: Temporary store solution, we probably want to talk with pathway-service directly

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  StudentInfo,
  TField,
  TrackListWithReason,
  TrackListWithTrack,
  TTEObject,
  TType,
  UnsafeRecord,
} from '@timeedit/registration-shared';
import { allocationApi } from '../../services/registration-allocation.service';
import { AllocationGroup } from '../BulkAllocationPage';
import { TTEReservation } from '../BulkAllocationPage/loadReservations';
import { CourseDataSource } from '../../components/Table/utils';
import { ViewLink } from '../BulkAllocationPage/ViewerLink';
import { mergeOnId, mergeOnResId, mergeOnString } from '../BulkAllocationPage/utils';
import { paths } from '../../../routes/routes';

type SelectedType = {
  studentInfo: StudentInfo;
  courseIds: number[];
  students: number[];
  tracks: string[];
};

interface StudentObjectState {
  studentObjects: TTEObject[];
  coursesWithStudentsLoaded: number[];
}

export interface AllocationState {
  trackObjects: TTEObject[];
  courseObjects: TTEObject[];
  studentObjectState: StudentObjectState;
  roomObjects: TTEObject[];
  reservations: TTEReservation[];
  allocationObjectLoading: boolean;
  conflicts: UnsafeRecord<string, number[]>;
  allocationFailedGroups: TrackListWithReason;
  allocationSuccessGroups: TrackListWithTrack;
  selected: SelectedType;
  trackLists: AllocationGroup[];
  types: TType[];
  fields: TField[];
  studentFields: TField[];
  viewerLink: ViewLink;
  overviewCourseDataSource: CourseDataSource[];
  searchedObjects: TTEObject[];
  expandedCourses: string[]; // List of courses where the tracks has be loaded even for courses with no tracks.
  linkedTracks: UnsafeRecord<string, number[]>;
  timeZone: string;
  baseCourseObjects: TTEObject[];
  autoAllocationRunning: boolean;
  pageFilter: Record<keyof typeof paths, {}>;
}

const selectedInitialState: SelectedType = {
  courseIds: [],
  studentInfo: {},
  students: [],
  tracks: [],
};

const initialState: AllocationState = {
  trackObjects: [],
  courseObjects: [],
  studentObjectState: {
    studentObjects: [],
    coursesWithStudentsLoaded: [],
  },
  roomObjects: [],
  reservations: [],
  allocationObjectLoading: false,
  conflicts: {},
  allocationFailedGroups: {},
  allocationSuccessGroups: {},
  trackLists: [],
  selected: selectedInitialState,
  types: [],
  fields: [],
  studentFields: [],
  viewerLink: {},
  overviewCourseDataSource: [],
  searchedObjects: [],
  expandedCourses: [],
  linkedTracks: {},
  timeZone: '',
  baseCourseObjects: [],
  autoAllocationRunning: false,
  pageFilter: {
    bulkAllocation: {},
    registrationPeriods: {},
    studentAdjustment: {},
  },
};

export const runAutoAllocation = createAsyncThunk('allocation/autoAllocation', async () => {
  await allocationApi.autoAllocate();
});

const allocationSlice = createSlice({
  name: 'allocation',
  initialState,
  reducers: {
    setPageFilters: (state, action: PayloadAction<{ key: keyof typeof paths; value?: {} }>) => {
      return { ...state, pageFilter: { ...state.pageFilter, [action.payload.key]: action.payload.value } };
    },
    setTrackObjects: (state, action: PayloadAction<TTEObject[]>) => {
      return {
        ...state,
        trackObjects: action.payload,
      };
    },
    updateExpandedCourses: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        expandedCourses: mergeOnString(state.expandedCourses, action.payload),
      };
    },
    updateTrackObjects: (state, action: PayloadAction<TTEObject[]>) => {
      return {
        ...state,
        trackObjects: mergeOnId(action.payload, state.trackObjects),
      };
    },
    setCourseObjects: (state, action: PayloadAction<TTEObject[]>) => {
      return {
        ...state,
        courseObjects: action.payload,
      };
    },
    updateStudentObjects: (state, action: PayloadAction<StudentObjectState>) => {
      const all = mergeOnId(action.payload.studentObjects, state.studentObjectState.studentObjects);
      const coursesSet = new Set(state.studentObjectState.coursesWithStudentsLoaded);
      for (const courseId of action.payload.coursesWithStudentsLoaded) {
        coursesSet.add(courseId);
      }
      const hasSameCourses = coursesSet.size === state.studentObjectState.coursesWithStudentsLoaded.length;
      const hasSameStudentObjects = all.length === state.studentObjectState.studentObjects.length;

      if (hasSameCourses && hasSameStudentObjects) {
        return state;
      }
      return {
        ...state,
        studentObjectState: {
          coursesWithStudentsLoaded: Array.from(coursesSet),
          studentObjects: all,
        },
      };
    },
    updateRoomObjects: (state, action: PayloadAction<TTEObject[]>) => {
      return {
        ...state,
        roomObjects: [...state.roomObjects, ...action.payload],
      };
    },
    updateReservations: (state, action: PayloadAction<TTEReservation[]>) => {
      return {
        ...state,
        reservations: mergeOnResId(action.payload, state.reservations),
      };
    },
    setAllocationObjectLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, allocationObjectLoading: action.payload };
    },
    setAllocationFailedGroups: (state, action: PayloadAction<TrackListWithReason>) => {
      return { ...state, allocationFailedGroups: action.payload };
    },
    setAllocationSuccessGroups: (state, action: PayloadAction<TrackListWithTrack>) => {
      return { ...state, allocationSuccessGroups: action.payload };
    },
    setTrackLists: (state, action: PayloadAction<AllocationGroup[]>) => {
      return { ...state, trackLists: action.payload };
    },
    setConflicts: (state, action: PayloadAction<UnsafeRecord<string, number[]>>) => {
      return { ...state, conflicts: { ...state.conflicts, ...action.payload } };
    },
    setTypes(state, action: PayloadAction<TType[]>) {
      return { ...state, types: action.payload };
    },
    setFields(state, action: PayloadAction<TField[]>) {
      return { ...state, fields: action.payload };
    },
    setStudentFields(state, action: PayloadAction<TField[]>) {
      return { ...state, studentFields: action.payload };
    },
    setViewerLink(state, action: PayloadAction<ViewLink>) {
      return { ...state, viewerLink: action.payload };
    },
    setTimeZone(state, action: PayloadAction<string>) {
      return { ...state, timeZone: action.payload };
    },

    setSelected(state, action: PayloadAction<SelectedType>) {
      return { ...state, selected: action.payload };
    },
    setOverviewCourseDataSource(state, action: PayloadAction<CourseDataSource[]>) {
      return { ...state, overviewCourseDataSource: action.payload };
    },
    updateSearchedObjects(state, action: PayloadAction<TTEObject[]>) {
      return { ...state, searchedObjects: mergeOnId(action.payload, state.searchedObjects) };
    },
    updateLinkedTracks(state, action: PayloadAction<UnsafeRecord<string, number[]>>) {
      return { ...state, linkedTracks: { ...state.linkedTracks, ...action.payload } };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(runAutoAllocation.pending, (state) => {
      // eslint-disable-next-line no-param-reassign
      state.autoAllocationRunning = true;
    });
    builder.addCase(runAutoAllocation.fulfilled, (state) => {
      // eslint-disable-next-line no-param-reassign
      state.autoAllocationRunning = false;
    });
    builder.addCase(runAutoAllocation.rejected, (state) => {
      // eslint-disable-next-line no-param-reassign
      state.autoAllocationRunning = false;
    });
  },
});

export const {
  updateTrackObjects,
  setCourseObjects,
  updateStudentObjects,
  updateRoomObjects,
  updateReservations,
  setAllocationObjectLoading,
  setAllocationFailedGroups,
  setAllocationSuccessGroups,
  setSelected,
  setTypes,
  setFields,
  setStudentFields,
  setViewerLink,
  setTrackLists,
  updateLinkedTracks,
  setOverviewCourseDataSource,
  updateSearchedObjects,
  updateExpandedCourses,
  setTimeZone,
  setConflicts,
  setPageFilters,
} = allocationSlice.actions;
export default allocationSlice.reducer;
