import React, { useEffect, useState } from 'react';
import { UnorderedListOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, Spin } from 'antd';
import { IssueListContent } from './IssueListContent';
import { TRootState } from '../../..';
import { useSelector } from 'react-redux';
import intl from '../../../i18n/intl';
import './IssueList.scss';

const language = intl.messages as Record<string, string>;

export function IssueListButton() {
  const issuesCount = useSelector((state: TRootState) => state.issueList.issuesCount);
  const issuesStatus = useSelector((state: TRootState) => state.issueList.issuesStatus);
  const { activeRequestsLoading } = useSelector((state: TRootState) => state.issueList.changeRequestLoading);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (issuesStatus === 'loading' || activeRequestsLoading) {
      setOpen(false);
    }
  }, [issuesStatus, activeRequestsLoading]);
  return (
    <div className="issue-list__button">
      <Badge className="issue-list__badge" size="small" count={issuesCount} />
      <Dropdown trigger={['click']} dropdownRender={() => <IssueListContent />} onOpenChange={onOpenChange} open={open}>
        <Button
          icon={issuesStatus === 'loading' || activeRequestsLoading ? <Spin size="small" /> : <UnorderedListOutlined />}
        >
          {language.issueList}
        </Button>
      </Dropdown>
    </div>
  );

  function onOpenChange(open: boolean) {
    if (issuesStatus !== 'loading' && !activeRequestsLoading) {
      setOpen(open);
    }
  }
}
