import React from 'react';
import { Text } from '@timeedit/registration-components';
import { useSelector } from 'react-redux';
import { TRootState } from '../../..';
import intl from '../../../i18n/intl';
import './BatchAllocateContent.scss';
import { useMapping } from '../../services/mapping';

const language = intl.messages as Record<string, string>;

export function StudentsStep() {
  const { courseIds, students, tracks } = useSelector((state: TRootState) => state.allocation.selected);
  const mapping = useMapping();

  return (
    <div>
      <Text strong>{language.summaryOfAllocation}:</Text>
      <ul className="step_text-list ">
        <li>
          {courseIds.length} {mapping.typename('course')}
        </li>
        <li>
          {tracks.length} {mapping.typename('track')}
        </li>
        <li>
          {students.length} {mapping.typename('student')}
        </li>
      </ul>
    </div>
  );
}
