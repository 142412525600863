import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, notification } from 'antd';
import { useMapping } from '../../services/mapping';
import { TRootState } from '../../../index';
import intl from '../../../i18n/intl';
import { allocationApi } from '../../services/registration-allocation.service';
import { reloadTrackAndReservations } from '../../pages/slices/fetch.slice';
import { configService } from '../../../services/config.service';
import { countSelectedTracks } from '../Table/utils';
import { useAbortController } from '../../hooks/UseAbortController';

const language = intl.messages as Record<string, string>;

type DeallocationBatchModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function BatchDeallocateModal({ open, setOpen }: DeallocationBatchModalProps) {
  const selectedTrackIds = useSelector((state: TRootState) => state.allocation.selected.tracks);
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const nbrOfSelectedTracks = countSelectedTracks({ selectedTracks: selectedTrackIds, trackObjects });
  const { batchDeallocate, deAllocateLoading } = useBatchDeallocate();
  const mapping = useMapping();

  return (
    <div>
      <Modal
        title={`Deallocate ${mapping.typename('student').toLowerCase()} - ${nbrOfSelectedTracks} ${mapping.typename('track').toLowerCase()}(s) ${language.selected}`}
        open={open}
        closable
        onCancel={onCancel}
        destroyOnClose
        maskClosable
        okText={language.deallocate_all_students}
        confirmLoading={deAllocateLoading}
        onOk={() => batchDeallocate(selectedTrackIds)}
        cancelText={language.close}
      />
    </div>
  );

  function onCancel() {
    setOpen(false);
  }
}

function useBatchDeallocate() {
  const { signal } = useAbortController();
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const [deAllocateLoading, setDeallocateLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const mapping = useMapping();

  const batchDeallocate = useCallback(runDeallocation, [trackObjects, dispatch, mapping, signal]);

  async function runDeallocation(selectedTrackIds: string[]) {
    setDeallocateLoading(true);

    try {
      const response = await allocationApi.deallocateAll({
        data: { tracks: selectedTrackIds.map((id) => parseInt(id, 10)) },
        signal: signal(),
      });

      if (response.status === 200) {
        await dispatch(
          reloadTrackAndReservations({
            mapping,
            trackIds: trackObjects.map((t) => t.id),
            reloadReservations: false,
          }),
        );
        setDeallocateLoading(false);
        return notification.success({ message: language.deallocate_ok });
      }
      throw new Error(language.could_not_deallocate_students);
    } catch (error) {
      setDeallocateLoading(false);
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: language.deallocate_failed,
        description: `Details: ${error}`.slice(0, 600),
      });
    }
    return Promise.resolve();
  }

  return useMemo(() => ({ deAllocateLoading, batchDeallocate }), [deAllocateLoading, batchDeallocate]);
}
