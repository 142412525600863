import React, { useState } from 'react';
import { Button, notification } from 'antd';
import { TEDrawerSection } from '@timeedit/ui-components';
import { configService } from '../../../services/config.service';
import { TTEObject, isDefined } from '@timeedit/registration-shared';
import intl from '../../../i18n/intl';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { allocationApi } from '../../services/registration-allocation.service';
import { useMapping } from '../../services/mapping';
import { reloadTrackAndReservations } from '../../pages/slices/fetch.slice';
import { setFieldValue } from 'registration-allocation/utils/fields';

const language = intl.messages as Record<string, string>;

export function AutoAllocationSection() {
  const mapping = useMapping();
  const dispatch = useDispatch();
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const track = useSelector((state: TRootState) => state.drawer.clicked.track);

  const [loading, setLoading] = useState<boolean>(false);

  const autoAllocateFieldId = mapping.getId('autoAllocation');

  const changeAutoAllocate = async (val: boolean) => {
    const clickedTrack = trackObjects.find((obj) => track === obj.id.toString());
    if (!isDefined(clickedTrack)) {
      return;
    }

    setLoading(true);
    try {
      const toPatch = patch(clickedTrack, val);
      if (isDefined(toPatch)) {
        await toPatch();
      }
    } catch (e) {
      notification.error({
        duration: 0,
        key: configService().NOTIFICATION_KEY,
        message: 'Change automatic allocation failed.',
        description: ` Details:  ${e}`.slice(0, 600),
      });
      console.error(e);
    }
    setLoading(false);
    dispatch(
      reloadTrackAndReservations({ mapping, trackIds: trackObjects.map((t) => t.id), reloadReservations: false }),
    );
  };

  const clearAllCaches = async () => {
    await allocationApi.clearCache();
  };

  return (
    <TEDrawerSection label={mapping.fieldname('autoAllocation')}>
      <Button
        className="drawer__item--margin"
        size="small"
        type={'primary'}
        loading={loading}
        onClick={(e) => changeAutoAllocate(true)}
      >
        {language.enable} {mapping.fieldname('autoAllocation')}
      </Button>
      <Button
        size="small"
        className="drawer__item--margin"
        type={'default'}
        loading={loading}
        onClick={(e) => changeAutoAllocate(false)}
      >
        {language.disable} {mapping.fieldname('autoAllocation')}
      </Button>
      <br />
      <br />
      <br />
      <Button className="drawer__item--margin" size="small" type={'default'} onClick={(e) => clearAllCaches()}>
        {'Clear cache'}
      </Button>
    </TEDrawerSection>
  );

  function patch(track: TTEObject, val: boolean) {
    const fields = setFieldValue(val ? '1' : '0', autoAllocateFieldId);
    return () => allocationApi.updateObjects({ data: [{ id: track.id, fields }] });
  }
}
