/* eslint-disable no-redeclare */
import { IMapping, isDefined, TimeZoneObject } from '@timeedit/registration-shared';
import { TTEUser } from '@timeedit/types/lib/types';
import { TRootState } from 'index';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTimeZone } from 'registration-allocation/pages/slices/allocation.slice';
import { fetchFields, fetchTypes, fetchViewerLink } from 'registration-allocation/pages/slices/fetch.slice';
import { getVisibility } from 'utils/authentication';
import { IState } from '../../../types/state.interface';
import { allocationApi } from '../registration-allocation.service';
import { createMappingInstance, Mapping } from './mapping.service';

let mapping: Mapping | null = null;

/**
 * Function to get the mapping instance after initialization
 * outside of React components.
 * @returns Mapping instance
 */
export function getMapping() {
  if (!mapping) {
    throw new Error('[getMapping]: Mapping is not initialized.');
  }
  return mapping;
}

type FetchMappingResult =
  | { status: 'loading' }
  | { status: 'success'; mapping: Mapping }
  | { status: 'error'; error: unknown }
  | { status: 'noop' };

type UseMappingInit = { init: true; authUser: TTEUser };
type UseMapping = { init: false; authUser: undefined };

/**
 * Mapping hook to get the mapping instance inside React components.
 * Use with `init: true` to initialize the mapping instance in a
 * central component, and block rendering until initialization is
 * done. After that, use without argument to conveniently get the
 * mapping instance directly.
 * @param init - Whether to initialize the mapping instance
 * @returns Mapping instance or fetch result
 */
export function useMapping(props: UseMappingInit): FetchMappingResult;
export function useMapping(props?: UseMapping): Mapping;
export function useMapping(
  { init, authUser }: UseMapping | UseMappingInit = { init: false, authUser: undefined },
): unknown {
  const dispatch = useDispatch();
  const types = useSelector((state: TRootState) => state.allocation.types);
  const fields = useSelector((state: TRootState) => state.allocation.fields);
  const [mappingData, setMappingData] = useState<IMapping>();

  const organizationId = useSelector((state: IState) => state.auth.user?.organizationId);
  const [fetchMappingResult, setFetchMappingResult] = useState<FetchMappingResult>(
    mapping ? { status: 'success', mapping } : { status: 'loading' },
  );

  useEffect(() => {
    let abort = false;
    if (mapping || !init) {
      return () => {
        abort = true;
      };
    }

    if (!getVisibility([], { scopes: ['TE_ALLOCATE::user', 'TE_ALLOCATE::admin'] }, authUser)) {
      setFetchMappingResult({ status: 'noop' });

      return () => {
        abort = true;
      };
    }

    setFetchMappingResult({ status: 'loading' });

    async function fetch() {
      if (mapping) return;
      const timeZonesResponse = await allocationApi.getTimeZones();
      const mappingDataResponse = await allocationApi.getMapping();
      if (!abort) {
        const timezoneObjects = timeZonesResponse.data as TimeZoneObject[];
        const currentTimezone = timezoneObjects.find((timeZone) => timeZone.isDefault);
        setMappingData(mappingDataResponse);
        dispatch(setTimeZone(currentTimezone?.identifiers[0] ?? ''));
        dispatch(fetchViewerLink());
        dispatch(fetchTypes());
        dispatch(fetchFields());
      }
    }

    fetch().catch((error) => {
      setFetchMappingResult({ status: 'error', error });
    });

    return () => {
      abort = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, organizationId, init]);

  useEffect(() => {
    if (fields.length > 0 && types.length > 0 && isDefined(mappingData) && !mapping) {
      mapping = createMappingInstance({ mappingData, fields, types });
      setFetchMappingResult({ status: 'success', mapping });
    }
  }, [fields, types, mappingData]);

  return init ? fetchMappingResult : mapping;
}
