import { Card, Select, Tooltip, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import intl from '../../../../i18n/intl';
import { useMapping } from '../../../services/mapping';
import { useSelector } from 'react-redux';
import { TRootState } from '../../../..';
import { DedicatedTrack, exhaustiveMatchingGuard, isDefined } from '@timeedit/registration-shared';
import { DiscardFunctions } from '../BulkAllocationDrawer';
import './DedicatedTrackSection.scss';
import { WarningOutlined } from '@ant-design/icons';
import { CategorySection } from './DedicatedCategorySection';
import { RelationSection } from './DedicatedRelationSection';
import { DedicationList } from './DedicationList';

const language = intl.messages as Record<string, string>;

export type DedicationKindSectionProps = {
  initialDedication: DedicatedTrack;
} & Omit<DedicatedTrackSectionProps, 'parsedDedicatedTrack' | 'addDiscardFunction'>;

export type DedicatedTrackSectionProps = {
  setDedicatedTrackString: React.Dispatch<React.SetStateAction<string | undefined>>;
  addDiscardFunction: (discardFunction: DiscardFunctions[number]) => void;
  parsedDedicatedTrack: DedicatedTrack;
  dedicatedPercentage: number;
};
export function DedicatedTrackSection({
  setDedicatedTrackString,
  addDiscardFunction,
  parsedDedicatedTrack,
  dedicatedPercentage,
}: DedicatedTrackSectionProps) {
  const mapping = useMapping();
  const track = useSelector((state: TRootState) => state.drawer.clicked.track);
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);

  const [selectedKind, setSelectedKind] = useState<DedicatedTrack['kind']>('none');

  const initialDedication = useMemo(() => {
    const trackObj = trackObjects.find((trackObj) => trackObj.id.toString() === track);
    return mapping.parse('dedicatedTrack', trackObj);
  }, [track, trackObjects, mapping]);

  useEffect(() => {
    const setInitialValues = createSetInitialValues(initialDedication);
    setInitialValues();

    addDiscardFunction({
      fn: setInitialValues,
      id: 'DedicatedTrackSectionDiscard',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDedication]);

  useEffect(() => {
    if (selectedKind === 'none') {
      setDedicatedTrackString(JSON.stringify(mapping.parse('dedicatedTrack', undefined)));
    }
  }, [setDedicatedTrackString, selectedKind, mapping]);

  return (
    <div className="dedicated-track-card">
      <Card type="inner" title={<CardTitle selectedKind={selectedKind} setSelectedKind={setSelectedKind} />}>
        {isDefined(parsedDedicatedTrack.data) ? (
          <DedicationList
            parsedDedicatedTrack={parsedDedicatedTrack}
            setDedicatedTrackString={setDedicatedTrackString}
          />
        ) : (
          ''
        )}
        {showSectionByKind({ initialDedication, kind: selectedKind, setDedicatedTrackString, dedicatedPercentage })}
        {selectedKind !== 'none' && (
          <Typography.Text type="secondary">
            {language.sumOfDedicatedCapacity}: {dedicatedPercentage}%
          </Typography.Text>
        )}
        {dedicatedPercentage > 100 ? (
          <Tooltip title={language.dedicationCapacityTooLarge}>
            <WarningOutlined className="dedication-capacity-warning" />
          </Tooltip>
        ) : (
          ''
        )}
      </Card>
    </div>
  );

  function createSetInitialValues(initialDedication: DedicatedTrack) {
    return () => {
      setSelectedKind(initialDedication.kind);
      setDedicatedTrackString(JSON.stringify(initialDedication));
    };
  }
}

type ShowSectionByKindProps = {
  kind: DedicatedTrack['kind'];
  initialDedication: DedicatedTrack;
  setDedicatedTrackString: React.Dispatch<React.SetStateAction<string | undefined>>;
  dedicatedPercentage: number;
};
function showSectionByKind({
  initialDedication,
  kind,
  setDedicatedTrackString,
  dedicatedPercentage,
}: ShowSectionByKindProps) {
  switch (kind) {
    case 'category':
      return (
        <CategorySection
          initialDedication={initialDedication}
          setDedicatedTrackString={setDedicatedTrackString}
          dedicatedPercentage={dedicatedPercentage}
        />
      );
    case 'relation':
      return (
        <RelationSection
          initialDedication={initialDedication}
          setDedicatedTrackString={setDedicatedTrackString}
          dedicatedPercentage={dedicatedPercentage}
        />
      );
    case 'none':
      return '';
    default:
      return exhaustiveMatchingGuard(kind);
  }
}

type CardTitleProps = {
  selectedKind: DedicatedTrack['kind'];
  setSelectedKind: React.Dispatch<React.SetStateAction<DedicatedTrack['kind']>>;
};
function CardTitle({ selectedKind, setSelectedKind }: CardTitleProps) {
  const mapping = useMapping();

  return (
    <div className="ant-card-head__title-container">
      <Typography.Text strong={false} className="card-header-text">
        {language.dedicateTo}:
      </Typography.Text>
      <Select<DedicatedTrack['kind']>
        value={selectedKind}
        onChange={setSelectedKind}
        options={[
          {
            label: '-',
            value: 'none',
          },
          {
            label: language.category,
            value: 'category',
          },
          {
            label: `${mapping.typename('program')}`,
            value: `relation`,
          },
        ]}
        popupMatchSelectWidth={false}
        className="te-w-full"
      />
    </div>
  );
}
