import { isDefined } from '@timeedit/registration-shared';
import { StudentAdjustmentDataSource } from '../../pages/StudentAdjustmentsPage';

export function createStatusData(dataSource: StudentAdjustmentDataSource[]) {
  const data = dataSource.reduce(
    (statusData, dataSource) => {
      const diff = dataSource.trackListsLength - dataSource.inHowManyTrackListIsStudent;

      if (diff < 0) return statusData;
      if (diff === 0) {
        return {
          fullyAllocated: statusData.fullyAllocated + 1,
          notFullyAllocated: statusData.notFullyAllocated,
        };
      }
      return {
        fullyAllocated: statusData.fullyAllocated,
        notFullyAllocated: statusData.notFullyAllocated + 1,
      };
    },
    {
      fullyAllocated: 0,
      notFullyAllocated: 0,
    },
  );
  const percentage = Math.round((data.fullyAllocated / (data.fullyAllocated + data.notFullyAllocated)) * 100);
  return {
    fullyAllocated: data.fullyAllocated,
    notFullyAllocated: data.notFullyAllocated,
    percentage: Number.isNaN(percentage) ? 0 : percentage,
  };
}

type OnSwitchChangeProps = {
  columnKeys: number[];
  indexToInsert?: number;
  value: number;
};
export function createOnSwitchChangeValues({ columnKeys, value, indexToInsert }: OnSwitchChangeProps) {
  if (isDefined(indexToInsert)) {
    const keys = [...columnKeys];
    keys.splice(indexToInsert, 0, value);
    return keys;
  }
  return columnKeys.filter((k) => k !== value);
}
