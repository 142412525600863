import React from 'react';
import { Modal, ModalProps } from 'antd';
import intl from '../../../i18n/intl';
import { createSaveDiscardSectionValues, SaveDiscardSections } from './BulkAllocationDrawer';
import { useMapping } from '../../services/mapping';

const language = intl.messages as Record<string, string>;

type SaveDrawerModalProps = {
  section: SaveDiscardSections;
} & ModalProps;
export function SaveDrawerModal({ section, open, onOk, onCancel }: SaveDrawerModalProps) {
  const mapping = useMapping();
  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel}>
      {createSaveDiscardSectionValues(mapping)[section] ?? 'Information'} {language.save_changed}
    </Modal>
  );
}
