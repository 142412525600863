import React, { ReactNode } from 'react';

interface GeneralInfoRowProps {
  title: string;
  value: string | ReactNode;
}

export function GeneralInfoRow({ title, value }: GeneralInfoRowProps) {
  return (
    <>
      <dt className="general-info-row">{title}</dt>
      <dd className="general-info-row">{value}</dd>
    </>
  );
}

type GeneralInfoRowsWrapperProps = {
  children: ReactNode;
};

export function GeneralInfoRowsWrapper({ children }: GeneralInfoRowsWrapperProps) {
  return <dl className="general-info-row___wrapper">{children}</dl>;
}
