import React from 'react';
import { Modal, ModalProps } from 'antd';
import intl from '../../../i18n/intl';

const language = intl.messages as Record<string, string>;

type DiscardDrawerModalProps = {} & ModalProps;
export function DiscardDrawerModal({ open, onOk, onCancel }: DiscardDrawerModalProps) {
  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel}>
      {language.unsaved_discard}
    </Modal>
  );
}
