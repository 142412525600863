import {
  DedicatedCategory,
  DedicatedRelation,
  DedicatedTrack,
  exhaustiveMatchingGuard,
  isDedicatedCategory,
  isDedicatedRelation,
  isDefined,
  isNumber,
} from '@timeedit/registration-shared';
import { uniqBy } from 'lodash';

type CalcOnChangeProps = {
  newCategory: { id: number | string; value: number | string };
  previousDedicatedTrack: DedicatedTrack;
  dedicatedPercentage: number;
};
export function addToDedicatedCategory({
  newCategory,
  previousDedicatedTrack,
  dedicatedPercentage,
}: CalcOnChangeProps) {
  const { id, value } = newCategory;
  if (previousDedicatedTrack.kind !== 'category') {
    const initialData: DedicatedCategory[] = [
      { id: parseInt(id.toString(), 10), value: value.toString(), seats: calcRemainingPercentage(dedicatedPercentage) },
    ];
    return JSON.stringify({ kind: 'category', data: initialData });
  }
  const newId = parseInt(id.toString(), 10);
  const newValue = value.toString();

  const found = previousDedicatedTrack.data.find((category) => category.id === newId && category.value === newValue);

  if (!found) {
    previousDedicatedTrack.data.push({
      id: newId,
      value: newValue,
      seats: calcRemainingPercentage(dedicatedPercentage),
    });
  }

  return JSON.stringify(previousDedicatedTrack);
}

type AddToDedicatedRelationProps = {
  newRelationIds: number[];
  previousDedicatedTrack: DedicatedTrack;
  dedicatedPercentage: number;
};
export function addToDedicatedRelation({
  newRelationIds,
  previousDedicatedTrack,
  dedicatedPercentage,
}: AddToDedicatedRelationProps) {
  if (previousDedicatedTrack.kind !== 'relation') {
    const initialData: DedicatedRelation[] = newRelationIds.map((id, index) => ({
      id,
      seats: index === 0 ? calcRemainingPercentage(dedicatedPercentage) : 0,
    }));
    return JSON.stringify({ kind: 'relation', data: initialData });
  }
  const newDedicatedTrack: DedicatedTrack = previousDedicatedTrack;

  const moreData = newRelationIds.map((id, index) => ({
    id,
    seats: index === 0 ? calcRemainingPercentage(dedicatedPercentage) : 0,
  }));
  newDedicatedTrack.data = uniqBy([...newDedicatedTrack.data, ...moreData], 'id');

  return JSON.stringify(newDedicatedTrack);
}

type DedicatedTrackRemoveProps = {
  prevDedicatedTrackString: string | undefined;
  parsedDedicatedTrack: DedicatedTrack;
  itemToRemove: DedicatedCategory | DedicatedRelation;
};

export function dedicatedTrackRemove({
  itemToRemove,
  parsedDedicatedTrack,
  prevDedicatedTrackString,
}: DedicatedTrackRemoveProps) {
  if (!isDefined(prevDedicatedTrackString)) return prevDedicatedTrackString;

  const { kind } = parsedDedicatedTrack;
  switch (kind) {
    case 'category': {
      if (isDedicatedCategory(itemToRemove)) {
        const newData = parsedDedicatedTrack.data.filter((item) => item.value !== itemToRemove.value);
        return JSON.stringify({ kind: 'category', data: newData });
      }
      return prevDedicatedTrackString;
    }
    case 'relation': {
      if (isDedicatedRelation(itemToRemove)) {
        const newData = parsedDedicatedTrack.data.filter((item) => item.id !== itemToRemove.id);
        return JSON.stringify({ kind: 'relation', data: newData });
      }
      return prevDedicatedTrackString;
    }
    case 'none': {
      return prevDedicatedTrackString;
    }
    default: {
      return exhaustiveMatchingGuard(kind);
    }
  }
}

type UpdateDedicationSeatsProps = {
  parsedDedicatedTrack: DedicatedTrack;
  seatsNumber: number | null;
  item: DedicatedCategory | DedicatedRelation;
};
export function updateDedicationSeats({ parsedDedicatedTrack, seatsNumber, item }: UpdateDedicationSeatsProps) {
  const found = parsedDedicatedTrack.data?.find((dedicatedData) =>
    isDedicatedCategory(dedicatedData) && isDedicatedCategory(item)
      ? dedicatedData.value === item.value
      : dedicatedData.id === item.id,
  );

  if (isDefined(found)) {
    if (isNumber(seatsNumber)) {
      found.seats = seatsNumber;
    } else {
      found.seats = 0;
    }
  }
  return JSON.stringify(parsedDedicatedTrack);
}

function calcRemainingPercentage(dedicatedPercentage: number) {
  return Math.min(100, Math.max(0, 100 - dedicatedPercentage));
}
