import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { Cascader, Input, InputNumber, Modal, Result, Select, notification } from 'antd';
import { BatchEditType } from '../Table/BulkAllocationTable';
import { useMapping } from '../../services/mapping';
import { TRootState } from '../../../index';
import intl, { getInlineString } from '../../../i18n/intl';
import { allocationApi } from '../../services/registration-allocation.service';
import { exhaustiveMatchingGuard, isDefined, isString, UnsafeRecord } from '@timeedit/registration-shared';
import {
  DedicatedCategory,
  DedicatedTrack,
} from '@timeedit/preferences-and-dm-commons/lib/src/utils/typechecks/common';
import { isMapped, parseBufferKind, validBufferSize } from '../../pages/BulkAllocationPage/utils';
import ObjectSearcher from '../Drawer/ObjectSeacher';
import { configService } from '../../../services/config.service';
import { renderNumber } from '../../../study-combinations/utils/stringUtils';
import {
  fetchAndUpdateIssueList,
  fetchStudentFields,
  reloadTrackAndReservations,
} from '../../pages/slices/fetch.slice';
import { isEmpty, isNumber, isUndefined, uniq } from 'lodash';
import { numberArrayParser } from '@timeedit/preferences-and-dm-commons/lib/src/utils/registration-allocation/mapping/parsers';
import { setFieldValue } from '../../utils/fields';
import { BatchAllocateModal } from './BatchAllocateModal';
import { BatchDeallocateModal } from './BatchDeallocateModal';
import { countSelectedTracks } from '../Table/utils';
import { createCategoryOptions } from './utils';

const language = intl.messages as Record<string, string>;

type AllocationBatchModalProps = {
  open: boolean;
  onCancel: () => void;
  batchEditType: BatchEditType;
  setOpen: (open: boolean) => void;
};
const MAX_TRACKS = 100;

// TODO: Refactor this so that every type in BatchEditType has its own component...
export function BatchEditModal({ open, onCancel, batchEditType, setOpen }: AllocationBatchModalProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const studentFields = useSelector((state: TRootState) => state.allocation.studentFields);
  const viewerLink = useSelector((state: TRootState) => state.allocation.viewerLink);
  const selectedTrackIds = useSelector((state: TRootState) => state.allocation.selected.tracks);
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const [bufferKind, setBufferKind] = useState<string>('');
  const [buffer, setBuffer] = useState<number>(0);
  const [selected, setSelected] = useState<DedicatedTrack['kind']>('none');
  const [studentCategoryValue, setStudentCategoryValue] = useState<[number, string][]>([]);
  const options = useMemo(() => createCategoryOptions(studentFields), [studentFields]);
  const mapping = useMapping();
  const studentType = mapping.getId('student');
  const bufferFieldId = mapping.getId('buffer');
  const [dedicatedTrackString, setDedicatedTrackString] = useState<string>('-');
  const dedicatedFieldId = mapping.getId('dedicatedTrack');
  const [changeRequestTextValue, setChangeRequestTextValue] = useState<string>('');

  const [requestObjects, setRequestObjects] = useState<number[]>([]);

  function firstCommentFieldId(): number {
    return numberArrayParser(viewerLink.commentField)[0];
  }

  const studentRelatedIds = useMemo(() => {
    const trackObjs = trackObjects.filter((trackObj) => selectedTrackIds.includes(trackObj.id.toString()));
    const rs: number[] = [];
    for (const trackObj of trackObjs) {
      const dedicatedTrack = mapping.parse('dedicatedTrack', trackObj);
      if (!isDefined(trackObjects) || dedicatedTrack.kind !== 'relation') {
        continue;
      }
      rs.push(...dedicatedTrack.data.map((data) => data.id));
    }
    return [...new Set(rs)];
  }, [selectedTrackIds, trackObjects, mapping]);

  useEffect(() => {
    if (isDefined(studentType) && Object.keys(studentFields).length <= 0) {
      dispatch(fetchStudentFields(mapping));
    }
  }, [mapping, studentFields, studentType]);

  useEffect(() => {
    setDedicatedTrackString('-');
  }, [selected]);

  useEffect(() => {
    setStudentCategoryValue(() => {
      const trackObjs = trackObjects.filter((trackObj) => selectedTrackIds.includes(trackObj.id.toString()));
      const dedicatedTracks: DedicatedTrack[] = [];
      for (const trackObj of trackObjs) {
        const dedicatedTrack = mapping.parse('dedicatedTrack', trackObj);
        if (!isDefined(trackObj) || dedicatedTrack.kind !== 'category') {
          continue;
        }
        dedicatedTracks.push(dedicatedTrack);
      }
      const categories: [number, string][] = [];
      for (const dedicatedTrack of dedicatedTracks) {
        const { data } = dedicatedTrack;
        if (data) {
          for (const category of data) {
            categories.push([(category as DedicatedCategory).id, (category as DedicatedCategory).value]);
          }
        }
      }
      return categories;
    });
  }, [selectedTrackIds, trackObjects, mapping]);

  useEffect(() => {
    const dedicated: DedicatedTrack = studentCategoryValue.reduce<DedicatedTrack>(
      (dedicatedTrack, categoryValue) => {
        if (dedicatedTrack.kind !== 'category') return dedicatedTrack;
        const [id, value] = categoryValue;
        return { ...dedicatedTrack, data: [...dedicatedTrack.data, { id, value }] };
      },
      { kind: 'category', data: [] },
    );
    setDedicatedTrackString(JSON.stringify(dedicated));
  }, [studentCategoryValue]);

  useEffect(() => {
    const selectedTracks = trackObjects.filter(({ id }) => selectedTrackIds.includes(id.toString()));
    switch (batchEditType) {
      case 'buffer': {
        const buffers = selectedTracks.map((track) => parseBufferKind(track, mapping));
        let same = true;
        for (const buffer of buffers) {
          if (buffer.buffer !== buffers[0].buffer || buffer.bufferKind !== buffers[0].bufferKind) {
            same = false;
            break;
          }
        }
        if (same) {
          setBuffer(buffers[0]?.buffer);
          setBufferKind(buffers[0]?.bufferKind || '');
        } else {
          setBuffer(0);
          setBufferKind('');
        }
        break;
      }
      case 'dedicatedTracks': {
        const dedicatedTracks = selectedTracks
          .filter((track) => !isUndefined(track))
          .map((track) => {
            if (track) {
              const { fields } = track;
              const dedicatedField = fields.find((field) => field.fieldId === dedicatedFieldId);
              if (isDefined(dedicatedField)) {
                return dedicatedField.values[0];
              }
            }
            return '-';
          });
        let same = true;
        for (const dedicatedTrack of dedicatedTracks) {
          if (dedicatedTrack !== dedicatedTracks[0]) {
            same = false;
            break;
          }
        }
        if (same) {
          setDedicatedTrackString(dedicatedTracks[0] || '-');
        } else {
          setDedicatedTrackString('-');
        }
        break;
      }
      default:
        break;
    }
  }, []);

  const onBufferChange = (value: number | null) => {
    if (!isNumber(value)) {
      setBuffer(0);
      return;
    }
    setBuffer(value);
  };

  const validTrackLength = selectedTrackIds.length > 0 && selectedTrackIds.length <= MAX_TRACKS;

  function renderModalBodyType() {
    if (!validTrackLength) {
      const message = getInlineString(
        'limit_track_exceeded',
        renderNumber(MAX_TRACKS, '-'),
        renderNumber(selectedTrackIds.length, '-'),
      );
      return <Result title={message} />;
    }
    switch (batchEditType) {
      case 'buffer':
        return (
          <div className={'batch-grid'}>
            <div>
              <span>{mapping.fieldname('buffer')}:</span>
            </div>
            <div>
              <InputNumber
                className="te-mr-2 buffer__input"
                value={buffer}
                onChange={onBufferChange}
                min={0}
                max={9000}
              />
              <Select
                className="drawer__item--margin"
                defaultValue=""
                onChange={setBufferKind}
                value={bufferKind}
                options={[
                  { value: '', label: 'seats' },
                  { value: '%', label: '%' },
                ]}
                popupMatchSelectWidth={false}
              />
            </div>
          </div>
        );
      case 'dedicatedTracks': {
        return (
          <div>
            <div className={'batch-grid'}>
              <div>
                <span>{mapping.fieldname('dedicatedTrack')}:</span>
              </div>
              <div>
                <div>
                  <Select<DedicatedTrack['kind']>
                    defaultValue={'none'}
                    value={selected}
                    onChange={setSelected}
                    options={[
                      {
                        label: '-',
                        value: 'none',
                      },
                      {
                        label: language.category,
                        value: 'category',
                      },
                      {
                        label: `${mapping.typename(mapping.getId('program'))}`,
                        value: `relation`,
                      },
                    ]}
                    popupMatchSelectWidth={false}
                  />
                </div>
                {selected === 'category' && (
                  <div className={'te-mt-2'}>
                    <Cascader
                      multiple
                      className="drawer__item--margin"
                      style={{ width: '100%' }}
                      showCheckedStrategy={'SHOW_CHILD'}
                      options={options}
                      onChange={(newValue) => {
                        setStudentCategoryValue(() => {
                          return newValue.reduce<[number, string][]>((newCategoryValue, value) => {
                            const [fieldId, category] = value;
                            if (isString(category) && isNumber(fieldId)) {
                              return [...newCategoryValue, [fieldId, category]];
                            }
                            return newCategoryValue;
                          }, []);
                        });
                      }}
                      value={studentCategoryValue}
                      placeholder={language.category}
                      expandTrigger="hover"
                    />
                  </div>
                )}
                {selected === 'relation' && (
                  <ObjectSearcher
                    className="drawer__item--wide"
                    mapping={mapping}
                    ids={studentRelatedIds}
                    type="program"
                    fields={['programName']}
                    placeholder={`${mapping.typename(mapping.getId('program'))}`}
                    onChange={onChange}
                    dispatch={dispatch}
                  />
                )}
              </div>
            </div>
          </div>
        );
      }
      case 'changeRequest': {
        return (
          <table className="twoColumns">
            <tbody>
              <tr>
                <td> {mapping.typename('changeRequest')}</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td>
                  <ObjectSearcher
                    className="drawer__item--wide"
                    mapping={mapping}
                    ids={requestObjects}
                    onlyVirtual
                    type="changeRequest"
                    fields={['changeRequestName']}
                    placeholder={`${mapping.typename(mapping.getId('changeRequest'))}`}
                    onChange={setRequestObjects}
                    dispatch={dispatch}
                  />
                </td>
              </tr>
              <tr>
                <td className="twoColumnTop">{mapping.fieldname(firstCommentFieldId())}</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td>
                  <Input.TextArea
                    value={changeRequestTextValue}
                    onChange={(e) => setChangeRequestTextValue(e.target.value)}
                    autoSize={{ minRows: 4, maxRows: 10 }}
                    placeholder={mapping.fieldname(firstCommentFieldId())}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        );
      }
      default:
        return <p>{language.invalid_type}</p>;
    }
  }

  function onChange(ids: number[]) {
    const dedicated: DedicatedTrack = { kind: 'relation', data: ids };
    setDedicatedTrackString(JSON.stringify(dedicated));
  }
  function renderModalHeaderType() {
    // TODO: See over the language in this function, hardcoded strings
    const nbrOfSelectedTracks = countSelectedTracks({ selectedTracks: selectedTrackIds, trackObjects });

    switch (batchEditType) {
      case 'buffer':
        return `Edit ${mapping.fieldname('buffer')}  for ${nbrOfSelectedTracks} ${mapping.typename('track')}`;
      case 'dedicatedTracks':
        return `Edit ${mapping.fieldname('dedicatedTrack')} for ${nbrOfSelectedTracks} ${mapping.typename('track')}`;
      case 'changeRequest':
        return `${language.add} ${mapping.typename('changeRequest')} for ${nbrOfSelectedTracks} ${mapping.typename(
          'track',
        )}`;
      default:
        return '';
    }
  }

  async function onOk() {
    const selectedTracks = trackObjects.filter(({ id }) => selectedTrackIds.includes(id.toString()));
    switch (batchEditType) {
      case 'buffer': {
        const valid = uniq(
          selectedTracks.map((track) => (track ? validBufferSize(buffer, bufferKind, track, mapping) : '')),
        ).join('');
        if (!isEmpty(valid)) {
          notification.error({
            duration: 0,
            key: configService().NOTIFICATION_KEY,
            message: valid,
            description: '',
          });
          return;
        }
        const updatedTrack = selectedTracks.map((track) => {
          const bufferValue = buffer + bufferKind;
          const fields = setFieldValue(bufferValue, bufferFieldId);
          return { id: track.id, fields };
        });
        setLoading(true);
        try {
          await allocationApi.updateObjects({ data: updatedTrack });
          await dispatch(
            reloadTrackAndReservations({ mapping, trackIds: trackObjects.map((t) => t.id), reloadReservations: false }),
          );
          setOpen(false);
        } catch (e) {
          notification.error({
            duration: 0,
            key: configService().NOTIFICATION_KEY,
            message: 'Change buffer failed.',
            description: ` Details:  ${e}`.slice(0, 600),
          });
          console.error(e);
        } finally {
          setLoading(false);
        }
        break;
      }
      case 'dedicatedTracks': {
        const updatedTrack = selectedTracks.map((track) => {
          const fields = setFieldValue(dedicatedTrackString, dedicatedFieldId);
          return { id: track.id, fields };
        });
        setLoading(true);
        try {
          await allocationApi.updateObjects({ data: updatedTrack });
          await dispatch(
            reloadTrackAndReservations({ mapping, trackIds: trackObjects.map((t) => t.id), reloadReservations: false }),
          );
          setOpen(false);
        } catch (e) {
          notification.error({
            duration: 0,
            key: configService().NOTIFICATION_KEY,
            message: 'Change dedicated failed.',
            description: ` Details:  ${e}`.slice(0, 600),
          });
        } finally {
          setLoading(false);
        }
        break;
      }
      case 'changeRequest': {
        const failedTracks: number[] = [];
        const trackWithChangeRequest = selectedTracks.reduce<UnsafeRecord<number, number>>(
          (trackWithChangeRequest, track) => {
            const firstId = requestObjects.find(() => true);
            if (isDefined(firstId)) {
              return { ...trackWithChangeRequest, [track.id]: firstId };
            }
            failedTracks.push(track.id);
            return trackWithChangeRequest;
          },
          {},
        );
        try {
          if (isEmpty(trackWithChangeRequest)) {
            throw new Error('No change request found');
          }
          setLoading(true);
          const commentFieldId = firstCommentFieldId();
          const shouldAddComment = isMapped(commentFieldId) && !isEmpty(changeRequestTextValue);
          const makeChangeRequestResult = await allocationApi.makeChangeRequests({
            data: {
              trackWithChangeRequest,
              reservationComment: shouldAddComment
                ? { comment: changeRequestTextValue, fieldId: commentFieldId.toString() }
                : undefined,
            },
          });
          if (makeChangeRequestResult.status === 200) {
            const failed = [
              ...failedTracks,
              ...Object.keys(makeChangeRequestResult.data).flatMap(
                (key) => `${key}: ${makeChangeRequestResult.data[key]}`,
              ),
            ];
            if (failed.length > 0) {
              notification.info({
                duration: 0,
                key: configService().NOTIFICATION_KEY,
                message: language.makeChangeRequestFailed,
                description: `${failed.join(', ')}`.slice(0, 600),
              });
            } else {
              setOpen(false);
            }
            await dispatch(
              reloadTrackAndReservations({
                mapping,
                trackIds: trackObjects.map((t) => t.id),
                reloadReservations: true,
              }),
            );
            dispatch(fetchAndUpdateIssueList());
          }
        } catch (error) {
          notification.error({
            duration: 0,
            key: configService().NOTIFICATION_KEY,
            message: language.changeRequestFailed,
            description: `Details: ${error}`.slice(0, 600),
          });
        } finally {
          setLoading(false);
        }
        break;
      }
      default:
        setOpen(false);
        break;
    }
  }

  function validateSubmit() {
    switch (batchEditType) {
      case 'buffer':
        return buffer === undefined || buffer === null;
      case 'dedicatedTracks':
        return dedicatedTrackString === '-';
      case 'changeRequest':
        return requestObjects.length === 0;
      default:
        return false;
    }
  }

  return <div>{renderModal(batchEditType)}</div>;

  function renderModal(batchEditType: BatchEditType) {
    switch (batchEditType) {
      case 'batchAllocate':
        return <BatchAllocateModal open={open} setOpen={setOpen} />;

      case 'batchDeallocate':
        return <BatchDeallocateModal open={open} setOpen={setOpen} />;

      case 'buffer':
      case 'changeRequest':
      case 'dedicatedTracks':
        return (
          <Modal
            confirmLoading={loading}
            title={renderModalHeaderType()}
            open={open}
            closable={false}
            cancelText={language.cancel}
            okText={language.save}
            onCancel={() => onCancel()}
            okButtonProps={{ disabled: validateSubmit() }}
            onOk={() => onOk()}
            destroyOnClose
          >
            {renderModalBodyType()}
          </Modal>
        );
      default:
        return exhaustiveMatchingGuard(batchEditType);
    }
  }
}
