import React, { useEffect, useRef, useState } from 'react';
import { setOverviewCourseDataSource } from '../../pages/slices/allocation.slice';
import { TRootState } from '../../..';
import { useDispatch, useSelector } from 'react-redux';
import { useMapping } from '../../services/mapping';
import { TableProps } from 'antd/es/table';
import { Table } from '@timeedit/ui-components';
import { Button, Dropdown, MenuProps, Spin } from 'antd';
import intl from '../../../i18n/intl';
import {
  BAllocateDataSource,
  countSelectedTracks,
  isCourseDataSource,
  isTrackListDataSource,
  isTracksDataSource,
} from './utils';
import { useBulkAllocationTableColumns } from './useCreateColumns';
import { useCreateDataSource } from './useCreateDataSource';
import { useCreateRowSelection } from './useCreateRowSelection';
import { colorPrimary, colorPrimaryBg } from '@timeedit/ui-components/lib/src/assets/variables';
import { BulkAllocationFilter } from '../Filter/BulkAllocationFilter';
import { TFilterValueSet } from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import { useCreateExpandable } from './useCreateExpandable';
import { BatchEditModal } from '../AllocationModal/EditModal';
import { nameOfStateFilterSearchParam, useSearchParamsState } from '../../hooks/UseStateParamState';
import { useDeepEffect } from '../../hooks/UseDeepEffect';
import { DiscardDrawerModal } from '../Drawer/DiscardDrawerModal';
import { useVT } from 'virtualizedtableforantd4';
import AutoAllocateButton from '../AutoAllocateButton/AutoAllocateButton';
import { getVisibility } from 'utils/authentication';
import { setClicked } from 'registration-allocation/pages/slices/drawer.slice';

const language = intl.messages as Record<string, string>;

export type BatchEditType = 'buffer' | 'dedicatedTracks' | 'batchAllocate' | 'changeRequest' | 'batchDeallocate';

type BulkAllocationTableProps = {
  filtersChanged: (newFilterValue?: TFilterValueSet) => void;
  setOpenDrawer: (open: boolean) => void;
};
export function BulkAllocationTable({ filtersChanged, setOpenDrawer }: BulkAllocationTableProps) {
  const dispatch = useDispatch();
  const clickedTrack = useSelector((state: TRootState) => state.drawer.clicked.track);
  const trackObjects = useSelector((state: TRootState) => state.allocation.trackObjects);
  const changesToDiscard = useSelector((state: TRootState) => state.drawer.changesToDiscard);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [batchEditType, setBatchEditType] = useState<BatchEditType>('buffer');
  const selectedTracks = useSelector((state: TRootState) => state.allocation.selected.tracks);
  const trackLists = useSelector((state: TRootState) => state.allocation.trackLists);
  const changeRequestLoading = useSelector((state: TRootState) => state.issueList.changeRequestLoading);
  const mapping = useMapping();
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const proceedId = useRef<string>('');
  const tableLoading = useSelector((state: TRootState) => state.allocation.allocationObjectLoading);
  const authUser = useSelector((state: any) => state.auth.user);
  const pageFilters = useSelector((state: TRootState) => state.allocation.pageFilter);

  const [filters, setFilters] = useSearchParamsState<TFilterValueSet>({
    searchParamName: nameOfStateFilterSearchParam,
    defaultValue: pageFilters.bulkAllocation,
    pageName: 'bulkAllocation',
  });
  // TODO: Make the table height dynamic, -340 is based on the content above the table
  const initialTableHeight = Math.max(300, window.innerHeight - 340);
  const [vt] = useVT(() => ({ scroll: { y: initialTableHeight } }), []);

  const isAllocateAdmin = getVisibility([], { scopes: ['TE_ALLOCATE::admin'] }, authUser);
  function dropItems(): MenuProps['items'] {
    const menu = [];
    if (mapping.isMapped('buffer')) {
      menu.push({
        key: 'buffer-item',
        label: (
          <p onClick={() => handleMenuClick('buffer')}>
            {language.edit} {mapping.fieldname('buffer')}
          </p>
        ),
      });
    }
    // TODO: Uncomment when dedicatedTracks in bulk is implemented
    // if (mapping.isMapped('dedicatedTrack')) {
    //   menu.push({
    //     key: 'dedicatedTracks-item',
    //     label: (
    //       <p onClick={() => handleMenuClick('dedicatedTracks')}>
    //         {language.edit} {mapping.fieldname('dedicatedTrack')}
    //       </p>
    //     ),
    //   });
    // }
    if (mapping.isMapped('changeRequest')) {
      menu.push({
        key: 'changeRequest-item',
        label: changeRequestLoading.objectsLoading ? (
          <Spin />
        ) : (
          <p onClick={() => handleMenuClick('changeRequest')}>
            {language.add} {mapping.typename('changeRequest')}
          </p>
        ),
      });
    }
    menu.push({
      key: 'batchDeallocate-item',
      label: <p onClick={() => handleMenuClick('batchDeallocate')}>Deallocate students</p>,
    });
    menu.push({
      key: 'batchAllocate-item',
      label: <p onClick={() => handleMenuClick('batchAllocate')}>Allocate students</p>,
    });
    return menu;
  }

  const items = dropItems();

  const handleMenuClick = (type: BatchEditType) => {
    setOpenEditModal(true);
    setBatchEditType(type);
  };

  const allDataDataSource = useCreateDataSource();
  const { expandable, collapseAll } = useCreateExpandable();

  useDeepEffect(() => {
    filtersChanged(filters);
  }, [filters]);

  useEffect(() => {
    dispatch(setOverviewCourseDataSource(allDataDataSource));
  }, [dispatch, allDataDataSource]);

  const nbrOfSelectedTracks = countSelectedTracks({ selectedTracks, trackObjects });

  const bulkAllocationTableColumns = useBulkAllocationTableColumns();
  const { rowSelection, deSelectAll } = useCreateRowSelection();
  return (
    <div className="bulkwrap fullscreentable">
      <div className="fixed-bottom">
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
          <Dropdown disabled={nbrOfSelectedTracks === 0} menu={{ items }}>
            <Button disabled={nbrOfSelectedTracks === 0} size="small">
              {language.edit}
            </Button>
          </Dropdown>
          <p>
            {nbrOfSelectedTracks} {language.selected}
          </p>
          {isAllocateAdmin && <AutoAllocateButton size="small" />}
        </div>
      </div>
      <BulkAllocationFilter setFilters={setFilters} searchParamFilters={filters} />

      <Table
        scroll={{ y: initialTableHeight }}
        components={vt}
        loading={tableLoading}
        columns={bulkAllocationTableColumns}
        pagination={false}
        data={allDataDataSource}
        onRow={CreateOnRow()}
        expandable={expandable}
        showHeader={false}
        tableLayout="auto"
        rowSelection={rowSelection}
        headerExtra={[
          <Button className="bulkwrap header-extra__element" size="small" key="deSelectAllButton" onClick={deSelectAll}>
            {language.deselectAll}
          </Button>,
          <Button size="small" key="collapseButton" onClick={collapseAll}>
            {language.collapse_all}
          </Button>,
        ]}
      />

      {openEditModal && (
        <BatchEditModal
          open={openEditModal}
          batchEditType={batchEditType}
          setOpen={(isOpen) => setOpenEditModal(isOpen)}
          onCancel={() => setOpenEditModal(false)}
        />
      )}
      <DiscardDrawerModal open={openDiscardModal} onOk={onDiscardOk} onCancel={() => setOpenDiscardModal(false)} />
    </div>
  );

  function onDiscardOk() {
    clickProceed(proceedId.current);
    setOpenDiscardModal(false);
  }

  function CreateOnRow(): TableProps<BAllocateDataSource>['onRow'] {
    return (record) => {
      return {
        style: {
          backgroundColor: getBackgroundColor(),
          display: isTrackListDataSource(record) && record.children?.length === 1 ? 'none' : '',
          color: getTextColor(),
        },
        className: rowClassName(),
        onClick: () => {
          if (isTracksDataSource(record)) {
            handleClick(record.id);
          }
        },
      };

      function rowClassName(): string {
        if (isCourseDataSource(record)) return 'row__course';
        if (isTrackListDataSource(record)) return 'row_tracklist';
        return '';
      }

      function getBackgroundColor() {
        if (isTracksDataSource(record)) {
          return clickedTrack === record.id ? colorPrimaryBg : '';
        }
        return '';
      }
      function getTextColor() {
        if (isTracksDataSource(record)) {
          return clickedTrack === record.id ? colorPrimary : '';
        }
        return '';
      }

      function handleClick(id: string) {
        const anythingToDiscard = Object.values(changesToDiscard).some((value) => value);
        if (anythingToDiscard) {
          setOpenDiscardModal(true);
          proceedId.current = id;
        } else {
          clickProceed(id);
        }
      }
    };
  }

  function clickProceed(id: string) {
    if (clickedTrack === id) {
      changeClickedTrack('');
      return;
    }
    changeClickedTrack(id);
  }

  function changeClickedTrack(clickedTrack: string) {
    setOpenDrawer(clickedTrack.length > 0);
    const selectedCourseIds = trackLists.reduce((selectedCourseIds: number[], group) => {
      const selectedAllocationObjects = group.allocationObjects.filter((obj) => clickedTrack === obj.id.toString());
      if (selectedAllocationObjects.length === 0 || selectedCourseIds.includes(group.courseId)) {
        return selectedCourseIds;
      }
      return [...selectedCourseIds, group.courseId];
    }, []);

    dispatch(
      setClicked({
        courseId: selectedCourseIds[0],
        track: clickedTrack,
      }),
    );
  }
}
