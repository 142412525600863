import { SearchOutlined } from '@ant-design/icons';
import { Button, Table } from '@timeedit/ui-components';
import React, { useCallback, useMemo, useState } from 'react';
import {
  StudentAdjustmentDataSource,
  TStudentsAdjustmentFilters,
  randomNumericPlaceHolderForTrackListsLength,
} from '../../pages/StudentAdjustmentsPage';
import { filterByNumber } from '../../../study-combinations/pages/PathwayService.utils';
import { TStudentInCourse, TStudentsAndTracksOfCourse, isDefined } from '@timeedit/preferences-and-dm-commons/lib/src';
import { useStudentAdjustmentTableColumns } from './useCreateStudentAdjustmentColumns';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../..';
import { useMapping } from '../../services/mapping';
import { Modal, Switch, Tag } from 'antd';
import intl from '../../../i18n/intl';
import { setColumnKeys } from '../../pages/slices/studentAdjustment.slice';
import { useNavigate } from 'react-router-dom';
import { chunk } from 'lodash';
import { createOnSwitchChangeValues, createStatusData } from './StudentAdjustmentTableUtils';
import { fetchStudentConflicts } from '../../pages/slices/fetch.slice';

const language = intl.messages as Record<string, string>;

// Order matters, as it is used for the order of the columns.
export const requiredKeys = ['program', 'clashes', 'allocation', 'manage'] as const;

type StudentAdjustmentTableProps = {
  filters?: Partial<TStudentsAdjustmentFilters>;
};
export function StudentAdjustmentTable({ filters }: StudentAdjustmentTableProps) {
  const [trackListsLength, setTrackListsLength] = useState<number>();
  const mapping = useMapping();
  const columnKeys = useSelector((state: TRootState) => state.studentAdjustment.columnKeys);
  const tableLoading = useSelector((state: TRootState) => state.studentAdjustment.studentAdjustmentTableLoading);
  const studentConflicts = useSelector((state: TRootState) => state.studentAdjustment.conflicts);
  const [openChangeColumns, setOpenChangeColumns] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const studentsPerCourse = useSelector(
    (state: TRootState) => state.studentAdjustment.loadedCourses[filters?.courseId ?? ''],
  );

  useMemo(async () => {
    const ids = studentsPerCourse?.students.map((s) => s.id);
    if (!isDefined(ids)) {
      return;
    }
    const chunks = chunk(ids, 10);
    for (const studentIds of chunks) {
      dispatch(fetchStudentConflicts(studentIds));
    }
  }, [studentsPerCourse, dispatch]);

  const enhanceStudent = useCallback(
    (student: TStudentInCourse, studentsPerCourse: TStudentsAndTracksOfCourse): StudentAdjustmentDataSource => {
      const inHowManyTrackListIsStudent = student.trackLists.length;
      const trackListsLength = Object.keys(studentsPerCourse.trackListsLookup).length;
      setTrackListsLength(trackListsLength);
      const percent = (inHowManyTrackListIsStudent / trackListsLength) * 100;
      const fieldKeys = columnKeys.reduce(
        (fieldKeys, key) => ({ ...fieldKeys, [key]: mapping.string(key, student) }),
        {},
      );
      const programIds = student?.relations;
      const program =
        studentsPerCourse.programs
          .filter((p) => programIds?.includes(p.id))
          .map((p) => mapping.parse('programName', p)) ?? [];

      const clashes = (studentConflicts[student.id] ?? '-').toString();

      return {
        tracks: student.tracks,
        trackLists: student.trackLists,
        inHowManyTrackListIsStudent,
        trackListsLength,
        percent,
        program,
        clashes,
        key: student.id,
        id: student.id,
        ...fieldKeys,
      };
    },
    [mapping, studentConflicts, columnKeys],
  );

  const filteredDataSource: StudentAdjustmentDataSource[] = useMemo(() => {
    let students: StudentAdjustmentDataSource[] =
      studentsPerCourse?.students.map((s) => enhanceStudent(s, studentsPerCourse)) ?? [];

    if (filters?.trackIds && filters.trackIds?.length > 0) {
      students = students.filter((student) => student.tracks.some((id) => filters.trackIds?.includes(id.toString())));
    }

    if (filters?.trackLists && filters.trackLists?.length > 0) {
      students = students.filter((student) => student.trackLists.some((sTl) => filters.trackLists?.includes(sTl)));
    }

    const filtersAllocationAmountLocalCopy = { ...filters?.allocationAmount };

    if (students[0] && filtersAllocationAmountLocalCopy.LESS_THAN === randomNumericPlaceHolderForTrackListsLength) {
      const trackListsLengthLocal = trackListsLength ?? students[0].trackListsLength;
      filtersAllocationAmountLocalCopy.LESS_THAN = trackListsLengthLocal;

      /*

      TODO:

      Goal: The actual filter values in the GUI should represent what we actually filter on. However, that doesn't work right now.
      - First problem here is that the filters do not react to changes here, so it will not be reflected in the UI. Task: https://timeedit.atlassian.net/browse/CON-847
      - Second problem is that setting filters here obviously causes an infinite loop.

      const newFiltersValue = {
        ...filters,
        allocationAmount: {
          ...filters.allocationAmount,
          LESS_THAN: trackListsLengthLocal,
        } as TStudentsPerCourseFilters['allocationAmount'],
      };
      
      setFilters(newFiltersValue);
      setFilterValuesInput(newFiltersValue);
      */
    }
    students = students.filter((student) =>
      filterByNumber(filtersAllocationAmountLocalCopy, student.inHowManyTrackListIsStudent),
    );

    return students;
  }, [studentsPerCourse, filters, enhanceStudent, trackListsLength]);

  const statusData = useMemo(() => {
    return createStatusData(filteredDataSource);
  }, [filteredDataSource]);

  const columns = useStudentAdjustmentTableColumns({
    keys: [...columnKeys, ...requiredKeys],
    mapping,
    hasData: filteredDataSource.length > 0,
    navigate,
  });

  return (
    <>
      <Table
        loading={tableLoading}
        emptyComponent={() => {
          return <SearchOutlined style={{ fontSize: '8rem' }} />;
        }}
        statusHeader={
          <div style={{ marginLeft: 'auto' }}>
            <span>
              <Tag className="status__tag status__tag--success">{language.fullyAllocated}</Tag>
              {statusData.fullyAllocated} ({Number.isNaN(statusData.percentage) ? 0 : statusData.percentage}%)
            </span>{' '}
            <span>
              <Tag className="status__tag status__tag--error">{language.notFullyAllocated}</Tag>
              {statusData.notFullyAllocated} ({Number.isNaN(statusData.percentage) ? 0 : 100 - statusData.percentage}
              %)
            </span>
          </div>
        }
        data={filteredDataSource}
        columns={columns}
        size="small"
        tableLayout={filteredDataSource.length > 0 ? 'auto' : 'fixed'}
        scroll={{ x: 'max-content', y: 'calc(60vh)' }}
        footer={
          <div style={{ marginLeft: 'auto' }}>
            <Button
              size="small"
              onClick={() => {
                setOpenChangeColumns(true);
              }}
            >
              {language.edit} {language.columns}
            </Button>
          </div>
        }
      />
      <Modal
        open={openChangeColumns}
        onCancel={() => {
          setOpenChangeColumns(false);
        }}
        onOk={() => {
          setOpenChangeColumns(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <KeysToFilterKeySwitches />
      </Modal>
    </>
  );
}

function KeysToFilterKeySwitches() {
  const columnKeys = useSelector((state: TRootState) => state.studentAdjustment.columnKeys);
  const studentFields = useSelector((state: TRootState) => state.allocation.studentFields);
  const mapping = useMapping();
  const dispatch = useDispatch();

  const includeFields = mapping.includeFields(mapping.getId('student'));

  function sortKey(id: number): number {
    const index = includeFields.indexOf(id);
    if (index < 0) {
      return 100000;
    }
    return index;
  }
  const sorted = [...studentFields].sort((a, b) => sortKey(a.id) - sortKey(b.id));

  return (
    <table className="twoColumns">
      <tbody>
        {sorted.map((field, index) => {
          return (
            <tr key={field.id}>
              <td>{mapping.fieldname(field.id)}</td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td>
                <Switch
                  onChange={(val) => {
                    const newKeys = createOnSwitchChangeValues({
                      columnKeys,
                      value: field.id,
                      indexToInsert: val ? index : undefined,
                    });
                    dispatch(setColumnKeys(newKeys));
                  }}
                  defaultChecked={columnKeys.includes(field.id)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
